import React, { useEffect, useState } from "react";
import styles from "../scss/components/adminHeader.module.scss";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AdminHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  useEffect(() => {
    const handleLanguageChange = (lng) => {
      setCurrentLanguage(lng);
    };
    i18next.on("languageChanged", handleLanguageChange);
    return () => {
      i18next.off("languageChanged", handleLanguageChange);
    };
  }, []);

  const handleExit = () => {
    localStorage.removeItem("user-data");
    navigate(`/`);
  };

  return (
    <div className={styles.adminHeader}>
      <div className="container">
        <div className={styles.adminHeader__wrapper}>
          <div className={styles.lang}>
            <p
              className={currentLanguage === "ro" ? styles.langActive : ""}
              onClick={() => {
                i18next.changeLanguage("ro");
              }}
            >
              RO
            </p>
            <p
              className={currentLanguage === "ru" ? styles.langActive : ""}
              onClick={() => {
                i18next.changeLanguage("ru");
              }}
            >
              RU
            </p>
          </div>
          <Link to="/admin">{t("admin.header.list")}</Link>
          <button onClick={handleExit}>{t("admin.header.exit")}</button>
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
