import React from "react";
import styles from "../scss/components/basketEmpty.module.scss";
import image from "../assets/basketEmpty.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BasketEmpty = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.basketEmpty}>
      <div className="container">
        <div className={styles.image}>
          <img src={image} alt="" />
        </div>
        <div className={styles.contant}>
          <p className={styles.title}>{t("basketEmpty.title")}</p>
          <div className={styles.description}>
            <p>{t("basketEmpty.description")}</p>
          </div>
          <Link to="/catalog/chapter">
            <div className={styles.btn}>
              <p>{t("basketEmpty.btn")}</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 320 512"
              >
                <path
                  d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                  fill="#ffffff"
                />
              </svg>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BasketEmpty;
