import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "../scss/components/blogPostCarousel.module.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import BlogPostCarouselCard from "./BlogPostCarouselCard";

const axiosInstance = axios.create({
  baseURL: "https://api.prodcomfit.md/api/",
});

const sortByDate = (a, b) =>
  new Date(b.attributes?.date) - new Date(a.attributes?.date);

const BlogPostCarousel = () => {
  const { t } = useTranslation();
  const currentLanguage = i18next.language;
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef();
  const [blogData, setBlogData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/blogs?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=title&fields[1]=roTitle&fields[2]=date&fields[3]=subtitle&fields[4]=roSubtitle&fields[5]=date&populate[imagePostCard][fields][0]=name&populate[imagePostCard][fields][1]=url`
        )
        .then((res) => {
          const products = res.data.data;
          if (products.length > 0) {
            setBlogData((prevData) => [...prevData, ...products]);
            fetchData(page + 1); // Рекурсивный вызов с увеличением номера страницы
          } else {
            setIsLoading(false); // Завершаем загрузку, когда больше нет данных
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
          setIsLoading(false);
        });
    };

    fetchData();
  }, []);

  const sortedBlogData = useMemo(
    () => blogData.slice(0, 8).sort(sortByDate),
    [blogData]
  );

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    nextArrow: null,
    prevArrow: null,
    draggable: true, //  перетаскивания мышью
    swipe: true, //  свайпа пальцем на сенсорных устройствах
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
    afterChange: (current) => {
      setCurrentSlide(current);
    },
  };

  return (
    <div
      className={`${styles.blogPostCarousel} ${
        sortedBlogData.length === 0 ? styles.hidden : ""
      }`}
    >
      <div className="container">
        <div className={styles.blogPostCarousel__wrapper}>
          <p className={styles.title}>{t("carousel.title.post")}</p>
          {sortedBlogData.length > 0 ? (
            <Slider
              ref={sliderRef}
              className={styles.carousel}
              {...sliderSettings}
            >
              {sortedBlogData.map(({ id, attributes }) => {
                const {
                  imagePostCard,
                  date,
                  title,
                  roTitle,
                  subtitle,
                  roSubtitle,
                } = attributes;
                const name = currentLanguage === "ru" ? title : roTitle;
                const description =
                  currentLanguage === "ru" ? subtitle : roSubtitle;
                return (
                  <div key={id} className={styles.item}>
                    <BlogPostCarouselCard
                      id={id}
                      imagePostCard={imagePostCard?.data[0]?.attributes?.url}
                      date={date}
                      title={name}
                      subtitle={description}
                    />
                  </div>
                );
              })}
            </Slider>
          ) : (
            ""
          )}
        </div>
        <div className={styles.footer}>
          <Link to="/blog" className={styles.blogLink}>
            <p>{t("carousel.title.blog")}</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
            >
              <path
                d="M1 11L6 6L1 1"
                stroke="#8FC23D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default React.memo(BlogPostCarousel);
