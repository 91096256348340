import React from "react";
import styles from "../scss/components/aboutUsDescriptions.module.scss";
import image from "../assets/logo.png";
import { useTranslation } from "react-i18next";

const AboutUsDescriptions = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.aboutUsDescriptions}>
      <div className="container">
        <div className={styles.aboutUsDescriptions__wrapper}>
          <div className={styles.top}>
            <div className={styles.top__contant}>
              <p className={styles.title}>{t("aboutUs.title")}</p>
              <div className={styles.imgTable}>
                <img src={image} alt="image" />
              </div>
              <div className={styles.top__descriptions}>
                <p>{t("aboutUs.descriptions.header.first")}</p>
                <p>{t("aboutUs.descriptions.header.second")}</p>
              </div>
            </div>
            <div className={styles.img}>
              <img src={image} alt="image" />
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.bottom__descriptions}>
              <p>{t("aboutUs.descriptions.footer.first")}</p>
              <p>{t("aboutUs.descriptions.footer.second")}</p>
              <p>{t("aboutUs.descriptions.footer.third")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsDescriptions;
