import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import styles from "../scss/components/mainBannerCarousel.module.scss";
import { Link } from "react-router-dom";
import i18next from "i18next";

const fetchBannerData = async () => {
  try {
    const response = await axios.get(
      "https://api.prodcomfit.md/api/main-banners?pagination[page]=1&pagination[pageSize]=1000&fields[0]=color&fields[1]=url&populate[deskImageRu][fields][0]=name&populate[deskImageRu][fields][1]=url&populate[deskImageRo][fields][0]=name&populate[deskImageRo][fields][1]=url&populate[tableImageRu][fields][0]=name&populate[tableImageRu][fields][1]=url&populate[tableImageRo][fields][0]=name&populate[tableImageRo][fields][1]=url&populate[mobImageRu][fields][0]=name&populate[mobImageRu][fields][1]=url&populate[mobImageRo][fields][0]=name&populate[mobImageRo][fields][1]=url"
    );
    return response.data.data;
  } catch (error) {
    console.error("Failed to fetch data:", error);
    return [];
  }
};

const CarouselItem = React.memo(({ item, width }) => {
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);

  useEffect(() => {
    const handleLanguageChange = () => {
      setCurrentLanguage(i18next.language);
    };
    i18next.on("languageChanged", handleLanguageChange);
    return () => {
      i18next.off("languageChanged", handleLanguageChange);
    };
  }, []);

  const {
    url,
    deskImageRu,
    deskImageRo,
    tableImageRu,
    tableImageRo,
    mobImageRu,
    mobImageRo,
  } = item.attributes || {};
  const ruDeskImageUrl = deskImageRu?.data?.attributes?.url || "";
  const roDeskImageUrl = deskImageRo?.data?.attributes?.url || "";
  const ruTableImageUrl = tableImageRu?.data?.attributes?.url || "";
  const roTableImageUrl = tableImageRo?.data?.attributes?.url || "";
  const ruMobImageUrl = mobImageRu?.data?.attributes?.url || "";
  const roMobImageUrl = mobImageRo?.data?.attributes?.url || "";

  const getFullImageUrl = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 767) {
      return currentLanguage === "ro"
        ? `https://api.prodcomfit.md${roMobImageUrl}`
        : `https://api.prodcomfit.md${ruMobImageUrl}`;
    } else if (screenWidth <= 1024) {
      return currentLanguage === "ro"
        ? `https://api.prodcomfit.md${roTableImageUrl}`
        : `https://api.prodcomfit.md${ruTableImageUrl}`;
    } else {
      return currentLanguage === "ro"
        ? `https://api.prodcomfit.md${roDeskImageUrl}`
        : `https://api.prodcomfit.md${ruDeskImageUrl}`;
    }
  };

  return (
    <div
      className={`${styles.embla__slide} ${width > 1920 ? styles.height : ""}`}
    >
      <Link to={`${url || ""}`}>
        <div className={styles.image}>
          <img
            className={styles.imageRatio}
            src={getFullImageUrl()}
            alt="image"
          />
        </div>
      </Link>
    </div>
  );
});

const MainBannerCarousel = () => {
  const [bannerData, setBannerData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentColor, setCurrentColor] = useState("");
  const [loading, setLoading] = useState(true);
  const cachedBannerData = useMemo(() => bannerData, [bannerData]);
  useEffect(() => {
    fetchBannerData()
      .then((data) => {
        setBannerData(data);
        if (data.length > 0) {
          const initialColor = data[0].attributes.color || "#fff"; // Если цвет null, устанавливаем белый цвет
          setCurrentColor(initialColor); // Установка цвета для первого слайда
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const autoplayOptions = Autoplay({ delay: 15000 });
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: false,
    },
    [autoplayOptions]
  );

  useEffect(() => {
    if (!emblaApi || bannerData.length === 0) return;

    const onSelect = () => {
      setCurrentIndex(emblaApi.selectedScrollSnap());
      const currentBanner = bannerData[emblaApi.selectedScrollSnap()];

      if (
        currentBanner &&
        currentBanner.attributes &&
        currentBanner.attributes.color
      ) {
        const newColor = currentBanner.attributes.color || "#fff"; // Если цвет null, устанавливаем белый цвет
        setCurrentColor(newColor);
      }
    };

    emblaApi.on("select", onSelect);

    return () => emblaApi.off("select", onSelect);
  }, [emblaApi, bannerData]);

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={`${styles.mainBannerCarousel}${
        width > 1920 ? styles.height : ""
      } `}
      style={{ backgroundColor: currentColor }}
    >
      <div className={styles.container}>
        <div className={styles.embla} ref={emblaRef}>
          <div className={styles.embla__container}>
            {cachedBannerData.map((item) => (
              <CarouselItem key={item.id} width={width} item={item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBannerCarousel;
