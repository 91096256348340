import React, { useEffect, useRef, useState } from "react";
import styles from "../scss/components/discountCarousel.module.scss";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import DiscountCarouselCard from "./DiscountCarouselCard";

const DiscountCarousel = () => {
  const { t, i18n } = useTranslation();
  const sliderRef = useRef();
  const token = process.env.REACT_APP_TOKEN;
  const [isDragging, setIsDragging] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: null,
    prevArrow: null,
    draggable: true,
    swipe: true,

    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
    ],
    afterChange: (current) => {
      setCurrentSlide(current);
    },
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(true),
  };
  useEffect(() => {
    let timer;
    if (isDragging) {
      // Запускаем таймер при установке isDragging в true
      timer = setTimeout(() => {
        setIsDragging(false);
      }, 100);
    }
    return () => clearTimeout(timer);
  }, [isDragging]);
  const [discountData, setDiscountData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/products?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=title&fields[1]=price&fields[2]=priceDiscount&fields[3]=new&fields[4]=article&fields[5]=quantity&fields[6]=roTitle&fields[7]=roDesriptions&fields[8]=description&populate[imageMain][fields][0]=name&populate[imageMain][fields][1]=url`
        )
        .then((res) => {
          const products = res.data.data;
          if (products.length > 0) {
            setDiscountData((prevData) => [...prevData, ...products]);
            fetchData(page + 1);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
          setIsLoading(false);
        });
    };

    fetchData();
  }, []);
  const filteredDiscountData = discountData
    .filter(
      ({ attributes }) =>
        (attributes.priceDiscount > 0) & (attributes.quantity > 0)
    )
    .sort((a, b) => b.attributes.priceDiscount - a.attributes.priceDiscount)
    .slice(0, 15);

  const [commentData, setCommentData] = useState([]);
  useEffect(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/product-comments?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=article&fields[1]=estimation`
        )
        .then((res) => {
          const products = res.data.data;
          if (products.length > 0) {
            setCommentData((prevData) => [...prevData, ...products]);
            fetchData(page + 1);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
          setIsLoading(false);
        });
    };

    fetchData();
  }, []);

  const [hitData, setHitData] = useState({});
  useEffect(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/order-products?pagination[page]=${page}&pagination[pageSize]=1000&fields[0]=article&fields[1]=quantity`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const orderProductData = response.data.data;
          const salesById = orderProductData.reduce((acc, product) => {
            const { article, quantity } = product.attributes;
            acc[article] = (acc[article] || 0) + quantity;
            return acc;
          }, {});
          const topSellingProducts = Object.keys(salesById)
            .sort((a, b) => salesById[b] - salesById[a])
            .slice(0, 14);
          setHitData(
            topSellingProducts.reduce((acc, id) => {
              acc[id] = true;
              return acc;
            }, {})
          );
          const nextPageExists =
            response.data.meta.pagination.page *
              response.data.meta.pagination.pageSize <
            response.data.meta.pagination.totalCount;
          if (nextPageExists) {
            fetchData(page + 1);
          } else {
          }
        })
        .catch((error) => {
          /*  console.error("Error fetching order products data:", error); */
        });
    };

    fetchData();
  }, []);

  return (
    <div
      className={`${styles.discountCarousel} ${
        filteredDiscountData.length === 0 ? styles.hidden : ""
      }`}
    >
      <div className="container">
        <div className={styles.discountCarousel__wrapper}>
          <p className={styles.title}>{t("carousel.title.discount")}</p>
          <div className={styles.embla}>
            <div className={styles.embla__container}>
              {filteredDiscountData.length > 0 ? (
                <Slider
                  ref={sliderRef}
                  className={styles.carousel}
                  {...sliderSettings}
                >
                  {filteredDiscountData.map((item) => {
                    const { id, attributes } = item;
                    const {
                      title,
                      roTitle,
                      article,
                      price,
                      imageMain,
                      priceDiscount,
                      new: widgetNew,
                      quantity,
                    } = attributes;

                    // Фильтрация и сортировка комментариев для данного продукта
                    const sortedComments = commentData
                      .filter(
                        (comment) =>
                          comment.attributes.article === item.attributes.article
                      )
                      .sort((a, b) => {
                        const dateA =
                          a.attributes.date || a.attributes.createdAt;
                        const dateB =
                          b.attributes.date || b.attributes.createdAt;
                        return new Date(dateB) - new Date(dateA);
                      });
                    // Вычисляем среднюю оценку и количество отзывов
                    const averageRating =
                      sortedComments.reduce(
                        (total, comment) =>
                          total + comment.attributes.estimation,
                        0
                      ) / (sortedComments.length || 1); // Деление на ноль обрабатываем
                    const reviewCount = sortedComments.length;

                    const isHit = hitData[item.attributes.article] === true;

                    return (
                      <div key={id} className={styles.item}>
                        <DiscountCarouselCard
                          isDragging={isDragging}
                          id={id}
                          translateTitle={
                            i18n.language === "ro" ? roTitle : title
                          }
                          roTitle={roTitle}
                          title={title}
                          article={article}
                          price={price}
                          image={imageMain?.data?.attributes.url}
                          priceDiscount={priceDiscount}
                          widgetNew={widgetNew}
                          quantity={quantity}
                          averageRating={averageRating}
                          reviewCount={reviewCount}
                          isHit={isHit}
                        />
                      </div>
                    );
                  })}
                </Slider>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountCarousel;
