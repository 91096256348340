import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import styles from "../scss/components/mainBannerChapter.module.scss";
import i18next from "i18next";


const fetchChapterData = async () => {
  try {
    const response = await axios.get("https://api.prodcomfit.md/api/product-shapters?pagination[page]=1&pagination[pageSize]=1000&fields[0]=name&fields[1]=roName&populate[imageBanner][fields][0]=name&populate[imageBanner][fields][1]=url");
    return response.data.data;
  } catch (error) {
    console.error("Ошибка при запросе данных:", error);
    return [];
  }
};

const ChapterItem = React.memo(({ item }) => {
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);

  useEffect(() => {
    const handleLanguageChange = () => {
      setCurrentLanguage(i18next.language);
    };

    i18next.on("languageChanged", handleLanguageChange);

    // Очистка подписки
    return () => {
      i18next.off("languageChanged", handleLanguageChange);
    };
  }, []);
  const { id, attributes } = item;
  const { imageBanner, name, roName } = attributes || {};
  const imageUrl = imageBanner?.data?.attributes?.url;

  return (
    <Link className={styles.item} key={id} to={`/catalog/chapter/${id}`}>
      <div className={styles.image}>
        <img
          src={imageUrl ? `https://api.prodcomfit.md${imageUrl}` : ""}
          alt={name}
        />
      </div>

      <div className={styles.descriptoins}>
        <p className={styles.title}>
          {currentLanguage === "ro"
            ? roName
            : currentLanguage === "ru"
            ? name
            : ""}
        </p>

        <button>
          <span>перейти</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
          >
            <path
              d="M1 11L6 6L1 1"
              stroke="#8FC23D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </Link>
  );
});

const MainBannerChapter = () => {
  const [chapterData, setChapterData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchChapterData()
      .then((data) => {
        setChapterData(data);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className={styles.mainBannerChapter}>
      <div className="container">
        <div className={styles.items}>
          {chapterData.slice(0, 2).map((item) => (
            <ChapterItem key={item.id} item={item}  />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MainBannerChapter;
