import React, { useState, useEffect } from "react";
import styles from "../../scss/components/pages/contacts.module.scss";

import HeaderOtherPages from "../HeaderOtherPages";
import ContactInformations from "../ContactInformations";
import Footer from "../Footer";
import ContactsBreadcrumb from "../breadcrumbs/ContactsBreadcrumb";
import PhoneWidget from "../mui/PhoneWidget";
import ScrollToTopWidget from "../mui/ScrollToTopWidget";

const Contacts = () => {
  const [widgetActive, setWidgetActive] = useState(true);

  return (
    <div className={styles.contacts}>
      <HeaderOtherPages setWidgetActive={setWidgetActive} />
      <div className={styles.contant}>
        <ContactsBreadcrumb />
        <ContactInformations />
        {widgetActive ? (
          <div className={styles.widget}>
            <ScrollToTopWidget />
            <PhoneWidget />
          </div>
        ) : (
          ""
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Contacts;
