import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import styles from "../scss/components/hitCarousel.module.scss";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import HitCarouselCard from "./HitCarouselCard";

const HitCarousel = () => {
  const { t, i18n } = useTranslation();
  const token = process.env.REACT_APP_TOKEN;
  const [productData, setProductData] = useState([]);
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [commentData, setCommentData] = useState([]);
  useEffect(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/product-comments?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=article&fields[1]=estimation`
        )
        .then((res) => {
          const products = res.data.data;
          if (products.length > 0) {
            setCommentData((prevData) => [...prevData, ...products]);
            fetchData(page + 1);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
          setIsLoading(false);
        });
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/products?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=title&fields[1]=price&fields[2]=priceDiscount&fields[3]=new&fields[4]=article&fields[5]=quantity&fields[6]=roTitle&fields[7]=roDesriptions&fields[8]=description&populate[imageMain][fields][0]=name&populate[imageMain][fields][1]=url`
        )
        .then((res) => {
          const products = res.data.data;
          if (products.length > 0) {
            setProductData((prevData) => [...prevData, ...products]);
            fetchData(page + 1);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
          setIsLoading(false);
        });
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (productData.length > 0) {
      // Логика обработки данных о заказах теперь включена в этот useEffect
      const fetchOrderProductData = async () => {
        try {
          const orderResponse = await axios.get(
            "https://api.prodcomfit.md/api/order-products?pagination[page]=1&pagination[pageSize]=1000&fields[0]=article&fields[1]=quantity",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const orderProductData = orderResponse.data.data;
          calculateTopSellingProducts(orderProductData);
        } catch (error) {
          /*  console.error("Error fetching order data:", error); */
        }
      };

      fetchOrderProductData();
    }
  }, [productData]);
  const calculateTopSellingProducts = useCallback(
    (orderProductData) => {
      const salesById = orderProductData.reduce((acc, product) => {
        const { article, quantity } = product.attributes;
        acc[article] = acc[article] || { article, quantity: 0 };
        acc[article].quantity += quantity;
        return acc;
      }, {});

      const sortedSales = Object.values(salesById).sort(
        (a, b) => b.quantity - a.quantity
      );

      const calculatedTopSellingProducts = sortedSales
        .map((sale) => {
          const product = productData.find(
            (p) => p.attributes.article === sale.article
          );
          if (!product) return undefined;
          return { ...sale, product };
        })
        .filter(Boolean)
        .slice(0, 14);

      setTopSellingProducts(calculatedTopSellingProducts);
    },
    [productData]
  );
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: null,
    prevArrow: null,
    draggable: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
    ],
    afterChange: (current) => {
      setCurrentSlide(current);
    },
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(true),
  };
  useEffect(() => {
    let timer;
    if (isDragging) {
      // Запускаем таймер при установке isDragging в true
      timer = setTimeout(() => {
        setIsDragging(false);
      }, 100);
    }
    return () => clearTimeout(timer);
  }, [isDragging]);
  useEffect(() => {
    const avgRating = 0;
    const revCount = 0;
    setAverageRating(avgRating);
    setReviewCount(revCount);
  }, [commentData]);

  return (
    <div
      className={`${styles.hitCarousel} ${
        topSellingProducts.length === 0 ? styles.hidden : ""
      }`}
    >
      <div className="container">
        <div className={styles.hitCarousel__wrapper}>
          <p className={styles.title}>{t("carousel.title.hit")}</p>
          <div className={styles.embla}>
            <div className={styles.embla__container}>
              {topSellingProducts.length > 0 ? (
                <Slider
                  ref={sliderRef}
                  className={styles.carousel}
                  {...sliderSettings}
                >
                  {topSellingProducts.map((sale) => {
                    if (!sale || !sale.product || !sale.product.attributes) {
                      return null;
                    }
                    const { id, attributes } = sale.product;
                    const productArticle = attributes.article;
                    const sortedComments = commentData
                      .filter(
                        (comment) =>
                          comment.attributes.article === productArticle
                      )
                      .sort((a, b) => {
                        const dateA =
                          a.attributes.date || a.attributes.createdAt;
                        const dateB =
                          b.attributes.date || b.attributes.createdAt;
                        return new Date(dateB) - new Date(dateA);
                      });

                    const averageRating =
                      sortedComments.reduce(
                        (total, comment) =>
                          total + comment.attributes.estimation,
                        0
                      ) / (sortedComments.length || 1);
                    const reviewCount = sortedComments.length;

                    return (
                      <div key={id} className={styles.item}>
                        <HitCarouselCard
                          isDragging={isDragging}
                          id={id}
                          title={attributes.title}
                          roTitle={attributes.roTitle}
                          translateTitle={
                            i18n.language === "ro"
                              ? attributes.roTitle
                              : attributes.title
                          }
                          article={attributes.article}
                          price={attributes.price}
                          image={
                            attributes.imageMain?.data?.attributes.url ?? []
                          }
                          priceDiscount={attributes.priceDiscount}
                          widgetNew={attributes.new}
                          quantity={attributes.quantity}
                          averageRating={averageRating}
                          reviewCount={reviewCount}
                        />
                      </div>
                    );
                  })}
                </Slider>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HitCarousel;
