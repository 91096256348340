import React from "react";
import { Link } from "react-router-dom";
import styles from "../../scss/components/breadcrumbs/catalogCategoryBreadcrumb.module.scss";
import { useTranslation } from "react-i18next";

const CatalogCategoryBreadcrumb = ({ chapterId, chapterTitle, name }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.catalogCategoryBreadcrumb}>
      <div className="container">
        <div className={styles.catalogCategoryBreadcrumb__wrapper}>
          <Link to="/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 576 512"
              style={{ fill: "#81818b" }}
            >
              <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c.2 35.5-28.5 64.3-64 64.3H128.1c-35.3 0-64-28.7-64-64V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24zM352 224a64 64 0 1 0 -128 0 64 64 0 1 0 128 0zm-96 96c-44.2 0-80 35.8-80 80c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16c0-44.2-35.8-80-80-80H256z" />
            </svg>
          </Link>
          <span>/</span>
          <Link to="/catalog/chapter">{t("catalog.title")}</Link>
          <span>/</span>
          <Link to={`/catalog/chapter/${chapterId}`}>{chapterTitle}</Link>
          <span>/</span>
          <p>{name}</p>
        </div>
      </div>
    </div>
  );
};

export default CatalogCategoryBreadcrumb;
