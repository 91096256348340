import React, { useEffect, useState } from "react";
import styles from "../scss/components/favoritesItem.module.scss";
import { useTranslation } from "react-i18next";
import axios from "axios";

import FavoritesCard from "./FavoritesCard";

const FavoritesItem = ({ products }) => {
  const { t } = useTranslation();
  const [commentData, setCommentData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const token = process.env.REACT_APP_TOKEN;

  useEffect(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/product-comments?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=article&fields[1]=estimation`
        )
        .then((res) => {
          const products = res.data.data;
          if (products.length > 0) {
            setCommentData((prevData) => [...prevData, ...products]);
            fetchData(page + 1);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
          setIsLoading(false);
        });
    };

    fetchData();
  }, []);
  const [hitData, setHitData] = useState({});
  useEffect(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/order-products?pagination[page]=${page}&pagination[pageSize]=1000&fields[0]=article&fields[1]=quantity`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const orderProductData = response.data.data;
          const salesById = orderProductData.reduce((acc, product) => {
            const { article, quantity } = product.attributes;
            acc[article] = (acc[article] || 0) + quantity;
            return acc;
          }, {});
          const topSellingProducts = Object.keys(salesById)
            .sort((a, b) => salesById[b] - salesById[a])
            .slice(0, 14);
          setHitData(
            topSellingProducts.reduce((acc, id) => {
              acc[id] = true;
              return acc;
            }, {})
          );
          const nextPageExists =
            response.data.meta.pagination.page *
              response.data.meta.pagination.pageSize <
            response.data.meta.pagination.totalCount;
          if (nextPageExists) {
            fetchData(page + 1);
          } else {
          }
        })
        .catch((error) => {
          /*  console.error("Error fetching order products data:", error); */
        });
    };

    fetchData();
  }, []);

  return (
    <div className={styles.favoritesItem}>
      <div className="container">
        <div className={styles.container}>
          <p className={styles.title}>{t("pagesAloneName.favorites")}</p>

          <div className={styles.items}>
            {products.map((product) => {
              // Фильтрация и сортировка комментариев для данного продукта
              const sortedComments = commentData
                .filter(
                  (comment) => comment.attributes.article === product.article
                )
                .sort((a, b) => {
                  const dateA = a.attributes.date || a.attributes.createdAt;
                  const dateB = b.attributes.date || b.attributes.createdAt;
                  return new Date(dateB) - new Date(dateA);
                });
              // Вычисляем среднюю оценку и количество отзывов
              const averageRating =
                sortedComments.reduce(
                  (total, comment) => total + comment.attributes.estimation,
                  0
                ) / (sortedComments.length || 1); // Деление на ноль обрабатываем
              const reviewCount = sortedComments.length;

              const isHit = hitData[product.article] === true;
              return (
                <FavoritesCard
                  key={product.id}
                  article={product.article}
                  id={product.id}
                  title={product.title}
                  roTitle={product.roTitle}
                  image={product.image}
                  price={product.price}
                  priceDiscount={product.priceDiscount}
                  widgetNew={product.widgetNew}
                  quantity={product.quantity}
                  averageRating={averageRating}
                  reviewCount={reviewCount}
                  isHit={isHit}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoritesItem;
