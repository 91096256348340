import React from "react";
import Pagination from "@mui/material/Pagination";
import "@mui/material/styles/";
import "@emotion/react";

import styles from "../../scss/components/mui/Pagination.module.scss"

const PaginationRounded = ({ pageCount, currentPage, onPageChange }) => {
  return (
    <div className={styles.paginationRounded}>
      <Pagination
        count={pageCount}
        page={currentPage}
        onChange={onPageChange}
        shape="rounded"
      />
    </div>
  );
};

export default PaginationRounded;
