import React from "react";
import { Link } from "react-router-dom";
import styles from "../scss/components/footer.module.scss";
import { useTranslation } from "react-i18next";
import image from "../assets/bg.jpg";

const Footer = () => {
  const { t } = useTranslation();
  const year = new Date();
  return (
    <footer className={styles.footer}>
      <div className={styles.main}>
        <div className={styles.container}>
          <div className={styles.main__wrapper}>
            <div className={styles.contacts}>
              <p className={styles.title}>{t("footer.title.contacts")}</p>
              <div className={styles.contacts__items}>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                  >
                    <path
                      d="M6.63416 9.36811C5.10422 7.83817 4.75979 6.31143 4.68129 5.69786C4.65886 5.52964 4.71813 5.35983 4.83829 5.23807L6.07345 4.00291C6.25448 3.82188 6.28652 3.53671 6.15195 3.32044L4.18145 0.260557C4.03086 0.0186499 3.72007 -0.069462 3.46374 0.0587007L0.306136 1.54539C0.101076 1.64471 -0.022281 1.86419 0.00335155 2.09328C0.169963 3.66648 0.854031 7.53699 4.65886 11.3418C8.46369 15.1466 12.331 15.8323 13.9074 15.9973C14.1365 16.0198 14.3544 15.8996 14.4553 15.6945L15.9452 12.5305C16.0733 12.2774 15.9852 11.9666 15.7449 11.8176L12.685 9.84872C12.4656 9.71095 12.1836 9.74619 12.0026 9.92722L10.7674 11.1624C10.6489 11.2841 10.4758 11.3402 10.3076 11.3194C9.69405 11.2409 8.16411 10.8981 6.63416 9.36811Z"
                      fill="#8fc23d"
                    />
                  </svg>
                  <a href="tel:+37360275589">+373 60 275 589</a>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="14"
                    viewBox="0 0 21 14"
                    fill="none"
                  >
                    <path
                      d="M14.0029 7.10284L20.8675 11.4444V2.58008L14.0029 7.10284Z"
                      fill="#8fc23d"
                    />
                    <path
                      d="M0.503906 2.58008V11.4444L7.36849 7.10284L0.503906 2.58008Z"
                      fill="#8fc23d"
                    />
                    <path
                      d="M19.5947 0H1.77652C1.14117 0 0.63819 0.474473 0.54248 1.08335L10.6856 7.76465L20.8287 1.08131C20.733 0.472436 20.23 0 19.5947 0Z"
                      fill="#8fc23d"
                    />
                    <path
                      d="M12.8362 7.87256L11.036 9.05976C10.9281 9.12899 10.808 9.16361 10.6858 9.16361C10.5636 9.16361 10.4434 9.12899 10.3355 9.05976L8.53537 7.87256L0.544678 12.9248C0.642423 13.5296 1.14337 14 1.77668 14H19.5949C20.2282 14 20.7291 13.5296 20.8269 12.9248L12.8362 7.87256Z"
                      fill="#8fc23d"
                    />
                  </svg>
                  <a href="mailto:prodcom.fit.md@gmail.com">
                    prodcom.fit.md@gmail.com
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.menu}>
              <p className={styles.title}>{t("footer.title.menu")}</p>
              <div className={styles.menu__items}>
                <nav>
                  <ul>
                    <li>
                      <Link to="/catalog/chapter">
                        {t("footer.footerLinks.cartalog")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/payment&delivery">
                        {t("footer.footerLinks.payment&delivery")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/aboutUs">
                        {t("footer.footerLinks.aboutUs")}
                      </Link>
                    </li>
                  </ul>
                </nav>
                <nav>
                  <ul>
                    <li>
                      <Link to="/blog">{t("footer.footerLinks.blog")}</Link>
                    </li>

                    <li>
                      <Link to="/contacts">
                        {t("footer.footerLinks.contacts")}
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className={styles.social}>
              <p className={styles.title}>{t("footer.title.social")}</p>
              <div className={styles.social__items}>
                <a href="https://www.instagram.com/prodcom.fit.md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0,0,256,256"
                    width="50px"
                    height="50px"
                  >
                    <g
                      fill="#8fc23d"
                      fillRule="nonzero"
                      stroke="none"
                      strokeWidth="1"
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      strokeMiterlimit="10"
                      strokeDasharray=""
                      strokeDashoffset="0"
                      fontFamily="none"
                      fontWeight="none"
                      fontSize="none"
                      textAnchor="none"
                    >
                      <g transform="scale(5.12,5.12)">
                        <path d="M16,3c-7.17,0 -13,5.83 -13,13v18c0,7.17 5.83,13 13,13h18c7.17,0 13,-5.83 13,-13v-18c0,-7.17 -5.83,-13 -13,-13zM37,11c1.1,0 2,0.9 2,2c0,1.1 -0.9,2 -2,2c-1.1,0 -2,-0.9 -2,-2c0,-1.1 0.9,-2 2,-2zM25,14c6.07,0 11,4.93 11,11c0,6.07 -4.93,11 -11,11c-6.07,0 -11,-4.93 -11,-11c0,-6.07 4.93,-11 11,-11zM25,16c-4.96,0 -9,4.04 -9,9c0,4.96 4.04,9 9,9c4.96,0 9,-4.04 9,-9c0,-4.96 -4.04,-9 -9,-9z"></path>
                      </g>
                    </g>
                  </svg>
                </a>
                <a href="https://www.facebook.com/prodcomfit">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0,0,256,256"
                    width="50px"
                    height="50px"
                  >
                    <g
                      fill="#8fc23d"
                      fillRule="nonzero"
                      stroke="none"
                      strokeWidth="1"
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      strokeMiterlimit="10"
                      strokeDasharray=""
                      strokeDashoffset="0"
                      fontFamily="none"
                      fontWeight="none"
                      fontSize="none"
                      textAnchor="none"
                    >
                      <g transform="scale(5.12,5.12)">
                        <path d="M41,4h-32c-2.76,0 -5,2.24 -5,5v32c0,2.76 2.24,5 5,5h32c2.76,0 5,-2.24 5,-5v-32c0,-2.76 -2.24,-5 -5,-5zM37,19h-2c-2.14,0 -3,0.5 -3,2v3h5l-1,5h-4v15h-5v-15h-4v-5h4v-3c0,-4 2,-7 6,-7c2.9,0 4,1 4,1z"></path>
                      </g>
                    </g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
       
      </div>

      <div className={styles.copyright}>
        <div className="container">
          <div className={styles.copyright__wrapper}>
            <p>
              {year.getFullYear()} {t("footer.copyright")}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
