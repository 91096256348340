import React, { useState, useEffect } from "react";
import styles from "../../scss/components/mui/phoneWidget.module.scss";

function PhoneWidget() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const footer = document.querySelector(".footer");
      if (footer) {
        const footerTop = footer.getBoundingClientRect().top;
        if (footerTop <= window.innerHeight - 20) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const phoneNumber = "+37360275589"; // Замените на ваш номер телефона

  const handlePhoneCall = () => {
    const telLink = `tel:${phoneNumber}`;
    window.location.href = telLink;
  };

  return (
    <div
      className={`${styles["phone-widget"]} ${
        isVisible ? styles["visible"] : ""
      }`}
      onClick={handlePhoneCall}
    >
      <lord-icon
        src="https://cdn.lordicon.com/tftaqjwp.json"
        trigger="loop"
        colors="primary:#fff"
        state="hover-phone-ring"
      ></lord-icon>
    </div>
  );
}

export default PhoneWidget;
