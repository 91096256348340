import { createSlice } from "@reduxjs/toolkit";

const loadFromSessionStorage = () => {
  const serializedData = sessionStorage.getItem("viewedProducts");
  return JSON.parse(serializedData) || [];
};

if (!sessionStorage.getItem("viewedProducts")) {
  sessionStorage.setItem("viewedProducts", JSON.stringify([]));
}

const viewedProductsSlice = createSlice({
  name: "viewedProducts",
  initialState: loadFromSessionStorage(),
  reducers: {
    addViewedProduct: (state, action) => {
      const product = action.payload;
      if (!state.some((p) => p.id === product.id)) {
        state.push(product);
        sessionStorage.setItem("viewedProducts", JSON.stringify(state));
      }
    },
    clearViewedProducts: (state) => {
      state.splice(0);
      sessionStorage.removeItem("viewedProducts");
    },
  },
});
export const selectWatched = (state) => state.viewedProducts;

export const { addViewedProduct, clearViewedProducts } =
  viewedProductsSlice.actions;

export default viewedProductsSlice.reducer;
