import React, { useEffect, useState } from "react";
import styles from "../scss/components/basketCard.module.scss";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectBasket, updateBasket } from "../store/features/basketSlice";
import { useTranslation } from "react-i18next";
import noImage from "./../assets/noImage.png";

function calculateDiscountedPrice(originalPrice, discountPercentage) {
  const discountedPrice = (originalPrice * (100 - discountPercentage)) / 100;
  return Math.round(discountedPrice * 100) / 100;
}

const BasketCard = ({
  id,
  title,
  roTitle,
  price,
  priceDiscount,
  image,
  quantity,
  selectquantity,
  updateTotalBasket,
  setUpdateProduct,
  onRemoveFromBasket,
}) => {
  const { t, i18n } = useTranslation(); // используем хук useTranslation

  const dispatch = useDispatch();
  const basket = useSelector(selectBasket);
  const [basketCount, setBasketCount] = useState(selectquantity);
  const updateCount = (newCount) => {
    if (newCount >= 1 && newCount <= quantity) {
      setBasketCount(newCount);
      // Сохраняем обновленное количество товаров в localStorage
      updateBasketItemQuantityInLocalStorage(id, newCount);
    }
  };
  const increment = () => {
    updateCount(basketCount + 1);
  };
  const decrement = () => {
    updateCount(basketCount - 1);
  };

  // Обновление количества товара в localStorage
  const updateBasketItemQuantityInLocalStorage = (itemId, newQuantity) => {
    setBasketCount(newQuantity); // Устанавливаем новое количество моментально
    // Асинхронно обновляем localStorage
    setTimeout(() => {
      const basketData = JSON.parse(localStorage.getItem("basket")) || [];
      const updatedBasketData = basketData.map((item) => {
        if (item.id === itemId) {
          return { ...item, selectquantity: newQuantity };
        }
        return item;
      });
      localStorage.setItem("basket", JSON.stringify(updatedBasketData));
      setUpdateProduct(updatedBasketData);
      dispatch(updateBasket(updatedBasketData));
    }, 0); // Устанавливаем нулевую задержку, чтобы выполнить в следующем микрозадании
  };

  const discountedPrice = calculateDiscountedPrice(price, priceDiscount);
  const totalPrice = basketCount * discountedPrice;

  useEffect(() => {
    updateTotalBasket(totalPrice, id);
  }, [totalPrice, id]);

  const url = process.env.REACT_APP_API_FOR_IMAGE;
  return (
    <div key={id} className={styles.basketCard}>
      <div className={styles.item}>
        <div className={styles.header}>
          <Link to={`/catalog/product/${id}`}>
            <div className={styles.img}>
              <img
                src={
                  image && !Array.isArray(image)
                    ? `https://api.prodcomfit.md${image}`
                    : noImage
                }
                alt={title}
              />
            </div>
          </Link>
          <div className={styles.title}>
            <Link to={`/catalog/product/${id}`}>
              <p>{i18n.language === "ro" ? roTitle : title}</p>
            </Link>
          </div>
        </div>
        <div className={styles.block}>
          <span className={styles.nameSpan}>{t("word.quantity")}</span>
          <div className={styles.count}>
            <div onClick={decrement} className={styles.count__btn}>
              <svg
                fill="#8fc23d"
                height="200px"
                width="200px"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 42 42"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M37.059,16H26H16H4.941C2.224,16,0,18.282,0,21s2.224,5,4.941,5H16h10h11.059C39.776,26,42,23.718,42,21 S39.776,16,37.059,16z"></path>{" "}
                </g>
              </svg>
            </div>
            <div className={styles.count__number}>
              <input
                type="number"
                value={basketCount}
                onChange={(e) => {
                  const newCount = parseInt(e.target.value, 10) || 1; // Устанавливаем минимальное значение 1

                  if (newCount >= 1 && newCount <= (quantity || 0)) {
                    setBasketCount(newCount);
                    updateBasketItemQuantityInLocalStorage(id, newCount);
                  } else if (newCount < 1) {
                    setBasketCount(1);
                    updateBasketItemQuantityInLocalStorage(id, 1);
                  } else {
                    setBasketCount(quantity || 0);
                    updateBasketItemQuantityInLocalStorage(id, quantity || 0);
                  }
                }}
                onKeyDown={(e) => {
                  // Предотвращаем удаление символов, если текущее значение 1
                  if (e.key === "Backspace" && basketCount === 1) {
                    e.preventDefault();
                  }
                }}
                min="1"
                max={quantity || ""}
              />
            </div>
            <div onClick={increment} className={styles.count__btn}>
              <svg
                viewBox="0 0 8 8"
                id="meteor-icon-kit__regular-plus-xxs"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 3V0.83333C3 0.3731 3.4477 0 4 0C4.5523 0 5 0.3731 5 0.83333V3H7.1667C7.6269 3 8 3.4477 8 4C8 4.5523 7.6269 5 7.1667 5H5V7.1667C5 7.6269 4.5523 8 4 8C3.4477 8 3 7.6269 3 7.1667V5H0.83333C0.3731 5 0 4.5523 0 4C0 3.4477 0.3731 3 0.83333 3H3z"
                    fill="#8fc23d"
                  ></path>
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div className={styles.block}>
          <span className={styles.nameSpan}>{t("word.price")}</span>
          <div className={styles.price}>
            {priceDiscount === null || priceDiscount === 0 ? (
              <>
                <p>
                  {price.toFixed(2)}
                  <span>{t("word.lei")}</span>
                </p>
              </>
            ) : (
              <>
                <p>
                  {calculateDiscountedPrice(price, priceDiscount).toFixed(2)}
                  <span>{t("word.lei")}</span>
                </p>
                <p className={styles.crossed}>
                  {price}
                  <span>{t("word.lei")}</span>
                </p>
              </>
            )}
          </div>
        </div>
        <div className={styles.block}>
          <span className={styles.nameSpan}>{t("word.total")}</span>
          <div className={styles.totalPrice}>
            <p>
              {(totalPrice.toFixed(2) * 100).toFixed(0) / 100}
              <span>{t("word.lei")}</span>
            </p>
          </div>
        </div>
      </div>
      <div onClick={onRemoveFromBasket} className={styles.delete}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path
            d="M4.08301 4.08331L9.91639 9.91669M4.08301 9.91669L9.91639 4.08331"
            stroke="white"
            strokeWidth="1.16668"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default BasketCard;
