import React, { useEffect, useState } from "react";
import styles from "../scss/components/contactInformations.module.scss";
import { useTranslation } from "react-i18next";
import axios from "axios";

import Spinner from "./Spinner";

const ContactInformations = () => {
  const { t, i18n } = useTranslation();
  const [contactData, setContactData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get("https://api.prodcomfit.md/api/contacts?pagination[page]=1&pagination[pageSize]=1000&populate=*").then((res) => {
      if (res.data && res.data.data) {
        setContactData(res.data.data);
        setLoading(false); // Устанавливаем loading в false после успешной загрузки данных
      }
    });
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={styles.contactInformations}>
      <div className="container">
        <div className={styles.contactInformations__wrapper}>
          <p className={styles.title}>{t("contactInformations.title")}</p>
          {contactData.map((item) => {
            const number = item.attributes.number;

            const map =
              i18n.language === "ru"
                ? item.attributes.map
                : item.attributes.roMap;

            const location =
              i18n.language === "ru"
                ? item.attributes.location
                : item.attributes.roLocation;

            return (
              <div key={item.id} className={styles.twoPart}>
                <div className={styles.first}>
                  <div className={styles.items}>
                    {location.map((location, index) => (
                      <div key={index} className={styles.item}>
                        <div className={styles.location}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="34"
                            viewBox="0 0 23 34"
                            fill="none"
                          >
                            <path
                              d="M6.46836 11.4533C6.46836 8.70584 8.70414 6.47007 11.4516 6.47007C14.1995 6.47007 16.4352 8.70585 16.4352 11.4533C16.4352 14.2012 14.1995 16.4369 11.4516 16.4369C8.70414 16.4369 6.46836 14.2012 6.46836 11.4533Z"
                              fill="#8FC23D"
                              stroke="white"
                              strokeWidth="0.3"
                            />
                            <path
                              d="M1.93906 11.4531C1.93906 6.20739 6.20691 1.93955 11.4526 1.93955C16.6983 1.93955 20.9662 6.20739 20.9662 11.4531C20.9662 12.4758 20.5752 13.916 19.9251 15.5937C19.2767 17.2668 18.3773 19.1597 17.3746 21.0815C15.4203 24.827 13.0786 28.6727 11.4526 31.1979C9.82666 28.6731 7.48495 24.8276 5.53068 21.0822C4.52794 19.1604 3.62853 17.2675 2.98018 15.5943C2.33001 13.9164 1.93906 12.4761 1.93906 11.4531ZM4.37984 11.4527C4.37984 15.353 7.55232 18.5259 11.4526 18.5259C15.3525 18.5259 18.5258 15.3534 18.5258 11.4531C18.5258 7.55281 15.3529 4.38033 11.4526 4.38033C7.55234 4.38033 4.37984 7.55243 4.37984 11.4527Z"
                              fill="#8FC23D"
                              stroke="white"
                              strokeWidth="0.3"
                            />
                            <path
                              d="M12.0725 33.5186L12.0725 33.5187C11.9345 33.7258 11.7019 33.85 11.4531 33.85C11.2039 33.85 10.9716 33.7258 10.8336 33.519L10.7089 33.6022L10.8336 33.5189C10.6157 33.1925 7.94106 29.1673 5.32138 24.4712C4.01144 22.123 2.71679 19.6097 1.75003 17.3088C0.78092 15.0023 0.15 12.9289 0.15 11.4531C0.15 5.22062 5.22062 0.15 11.4531 0.15C17.6856 0.15 22.7562 5.22062 22.7562 11.4527C22.7562 12.9286 22.1252 15.0021 21.1561 17.3086C20.1893 19.6095 18.8946 22.1228 17.5847 24.471C14.9649 29.167 12.2903 33.1921 12.0725 33.5186ZM11.3271 31.556L11.4531 31.7508L11.5791 31.556C13.2113 29.0317 15.6298 25.0744 17.6409 21.2201C18.6463 19.2931 19.5513 17.3894 20.2052 15.702C20.8573 14.0192 21.2666 12.5349 21.2666 11.4531C21.2666 6.04169 16.8645 1.63953 11.4531 1.63953C6.04169 1.63953 1.63953 6.04169 1.63953 11.4531C1.63953 12.5353 2.04881 14.0198 2.70092 15.7027C3.35483 17.3903 4.25974 19.294 5.26518 21.221C7.27618 25.0751 9.69469 29.0321 11.3271 31.556Z"
                              fill="#8FC23D"
                              stroke="white"
                              strokeWidth="0.3"
                            />
                            <path
                              d="M4.6793 11.4528C4.6793 7.71821 7.71747 4.68003 11.4521 4.68003C15.1863 4.68003 18.2252 7.71785 18.2252 11.4524C18.2252 15.187 15.1867 18.2256 11.4521 18.2256C7.71747 18.2256 4.6793 15.1874 4.6793 11.4528ZM6.16883 11.4528C6.16883 14.3663 8.53891 16.7364 11.4521 16.7364C14.3656 16.7364 16.7357 14.3663 16.7357 11.4528C16.7357 8.53965 14.3656 6.16956 11.4521 6.16956C8.53892 6.16956 6.16883 8.53965 6.16883 11.4528Z"
                              fill="#8FC23D"
                              stroke="white"
                              strokeWidth="0.3"
                            />
                          </svg>
                          <p>{location.address}</p>
                        </div>

                        <div className={styles.hours}>
                          <div className={styles.workingHours}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="26"
                              viewBox="0 0 26 26"
                              fill="none"
                            >
                              <path
                                d="M12.1879 0C12.7299 0 13.2719 0 13.8139 0C14.2474 0.0555905 14.6866 0.0945038 15.1174 0.169551C18.2248 0.711558 20.8347 2.15969 22.8721 4.56676C25.4486 7.61312 26.4409 11.1459 25.8211 15.0845C25.2819 18.5005 23.5781 21.2745 20.8236 23.3619C17.9886 25.5105 14.77 26.336 11.2456 25.8774C8.65242 25.5411 6.37049 24.4793 4.41376 22.7421C2.15407 20.7353 0.742113 18.242 0.2029 15.2652C0.116737 14.7843 0.0667067 14.2979 0 13.8115C0 13.2695 0 12.7274 0 12.1854C0.055589 11.7518 0.10006 11.3154 0.172326 10.8818C0.630935 8.17736 1.81776 5.837 3.74392 3.88578C5.68953 1.91787 8.02983 0.680984 10.7592 0.200126C11.2317 0.11674 11.7098 0.0667086 12.1879 0ZM11.9211 10.0146C11.9211 11.1403 11.9294 12.266 11.9155 13.3917C11.9099 13.8115 12.0545 14.1339 12.3491 14.4285C14.0723 16.1407 15.7873 17.8612 17.505 19.5762C17.5717 19.6429 17.6356 19.7096 17.7051 19.7708C18.3221 20.3016 19.2532 20.0126 19.4617 19.2232C19.5757 18.784 19.4256 18.431 19.1143 18.1225C17.5022 16.5132 15.8957 14.901 14.2808 13.2972C14.1279 13.1472 14.0723 12.9971 14.0751 12.7886C14.0862 12.1298 14.0807 11.4683 14.0807 10.8096C14.0807 9.40591 14.0779 7.99947 14.0835 6.59581C14.0835 6.24837 13.9973 5.93984 13.7333 5.70636C13.3914 5.4034 12.9939 5.33391 12.577 5.51458C12.1601 5.69247 11.9322 6.02879 11.9294 6.49019C11.9183 7.66037 11.9211 8.83611 11.9211 10.0146Z"
                                fill="#8FC23D"
                              />
                            </svg>
                            <p>{location.work.name}</p>
                          </div>
                          <div className={styles.hours__items}>
                            {location.work.hours.map((hour, hourIndex) => (
                              <p key={hourIndex}>{hour}</p>
                            ))}
                          </div>
                        </div>

                        <div className={styles.workingHours}>
                          <div></div>
                          <div className={styles.hours__items}></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.second}>
                  <div className={styles.contactInfo}>
                    <p className={styles.contactInfo__title}>
                      {t("contactInformations.contant.contactInfo.title")}
                    </p>
                    <div className={styles.contactInfo__item}>
                      <div className={styles.contactInfo__item__number}>
                        {number.map((phoneNumber, index) => (
                          <div key={index}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="13"
                              viewBox="0 0 23 22"
                              fill="none"
                            >
                              <path
                                d="M9.53427 12.8812C7.33552 10.7775 6.84051 8.67822 6.7277 7.83455C6.69547 7.60326 6.78065 7.36976 6.95333 7.20235L8.72845 5.50399C8.98861 5.25508 9.03466 4.86298 8.84126 4.5656L6.00936 0.358266C5.79294 0.0256435 5.34628 -0.0955102 4.97791 0.0807134L0.439962 2.12491C0.14526 2.26148 -0.032021 2.56326 0.00481667 2.87826C0.244262 5.04141 1.22737 10.3634 6.69547 15.595C12.1636 20.8266 17.7215 21.7694 19.987 21.9963C20.3162 22.0272 20.6293 21.8619 20.7744 21.58L22.9156 17.2295C23.0998 16.8814 22.9731 16.4541 22.6278 16.2492L18.2303 13.542C17.9149 13.3526 17.5096 13.401 17.2495 13.6499L15.4744 15.3483C15.304 15.5157 15.0553 15.5928 14.8136 15.5642C13.9318 15.4562 11.733 14.9848 9.53427 12.8812Z"
                                fill="#8FC23D"
                              />
                            </svg>
                            <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                          </div>
                        ))}
                      </div>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="15"
                          viewBox="0 0 26 18"
                          fill="none"
                        >
                          <path
                            d="M17.2354 9.06879L25.9999 14.612V3.29419L17.2354 9.06879Z"
                            fill="#8FC23D"
                          />
                          <path
                            d="M0 3.29419V14.612L8.7646 9.06879L0 3.29419Z"
                            fill="#8FC23D"
                          />
                          <path
                            d="M24.3744 0H1.62443C0.813228 0 0.171028 0.6058 0.0488281 1.3832L12.9994 9.91379L25.95 1.3806C25.8278 0.603199 25.1856 0 24.3744 0Z"
                            fill="#8FC23D"
                          />
                          <path
                            d="M15.7454 10.0515L13.447 11.5673C13.3092 11.6557 13.1558 11.6999 12.9998 11.6999C12.8438 11.6999 12.6904 11.6557 12.5526 11.5673L10.2542 10.0515L0.0517578 16.5021C0.176558 17.2743 0.816158 17.8749 1.62476 17.8749H24.3748C25.1833 17.8749 25.8229 17.2743 25.9477 16.5021L15.7454 10.0515Z"
                            fill="#8FC23D"
                          />
                        </svg>
                        <a href={`mailto:${item.attributes.email}`}>
                          {item.attributes.email}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.map}
                    dangerouslySetInnerHTML={{ __html: map }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ContactInformations;
