import React, { useState } from "react";
import styles from "../scss/components/stepper.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { selectBasket, updateBasket } from "../store/features/basketSlice";

const Stepper = ({ list }) => {
  const { t } = useTranslation();
  const basket = useSelector(selectBasket);
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    t("stepper.stepsName.firstStep"),
    t("stepper.stepsName.secondStep"),
    t("stepper.stepsName.thirdStep"),
  ];

  const stepButtons = steps.map((step, index) => (
    <div
      className={`${styles.steps} ${currentStep >= index ? styles.active : ""}`}
      key={index}
    >
      <div className={styles.stepNumber}>{index + 1}</div>
      <div className={styles.stepName}>{step}</div>
    </div>
  ));
  const onPrev = () => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1);
      dispatch(updateBasket(basket));
    }
  };
  const onNext = () => {
    if (currentStep !== list.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <section className={styles.stepper}>
      <div className={styles.stepsContainer}>
        <div className={styles.stepsWrapper}>{stepButtons}</div>
      </div>
      <div>{React.cloneElement(list[currentStep], { onPrev, onNext })}</div>
    </section>
  );
};

export default Stepper;
