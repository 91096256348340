import { configureStore } from "@reduxjs/toolkit";
import favoritesReducer from "./features/favoritesSlice";
import basketReducer from "./features/basketSlice";
import viewedProductsReducer from "./features/viewedProductsSlice ";
import orderReducer from "./features/orderSlice";

const store = configureStore({
  reducer: {
    favorites: favoritesReducer,
    basket: basketReducer,
    viewedProducts: viewedProductsReducer,
    order: orderReducer,
  },
});

export default store;
