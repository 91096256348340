import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import styles from "../../scss/components/pages/productDetails.module.scss";
import { useTranslation } from "react-i18next";

import HeaderOtherPages from "../HeaderOtherPages";
import ProductCardDetails from "../ProductCardDetails";
import WithProductBuyCarousel from "../WithProductBuyCarousel";
import WatchedCarousel from "../WatchedCarousel";
import Footer from "../Footer";
import ProductDetailsBreadcrumb from "../breadcrumbs/ProductDetailsBreadcrumb";
import NotFound from "../pages/NotFound";
import Spinner from "../Spinner";
import PhoneWidget from "../mui/PhoneWidget";
import ScrollToTopWidget from "../mui/ScrollToTopWidget";

const ProductDetails = () => {
  const { t, i18n } = useTranslation();

  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Добавляем состояние isLoading
  const [widgetActive, setWidgetActive] = useState(true);

  const title = product?.data?.attributes?.title;
  const roTitle = product?.data?.attributes?.roTitle;
  const productName = product && (i18n.language === "ro" ? roTitle : title);

  const categorieRoName =
    product?.data?.attributes?.product_categories?.data[0]?.attributes?.roName;
  const categorieRuName =
    product?.data?.attributes?.product_categories?.data[0]?.attributes?.name;
  const categorieName =
    product && (i18n.language === "ro" ? categorieRoName : categorieRuName);
  const categorieId =
    product?.data?.attributes?.product_categories?.data[0]?.id;

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `https://api.prodcomfit.md/api/products/${productId}?pagination[page]=1&pagination[pageSize]=100&fields[0]=title&fields[1]=price&fields[2]=priceDiscount&fields[3]=new&fields[4]=article&fields[5]=quantity&fields[6]=roTitle&fields[7]=description&fields[8]=roDesriptions&populate[media][fields][0]=name&populate[media][fields][1]=url&populate[product_characteristics][fields][0]=name&populate[product_characteristics][fields][1]=roName&populate[product_categories][fields][0]=id&populate[product_categories][fields][1]=name&populate[product_categories][fields][2]=roName&populate[recommendations][fields][0]=title&populate[recommendations][fields][0]=id`
      )
      .then((res) => {
        setProduct(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
        setIsLoading(false);
      });
  }, [productId]);

  if (isLoading) {
    return <Spinner />;
  }
  if (!product) {
    return <NotFound />;
  }

  return (
    <div className={styles.productDetails}>
      <HeaderOtherPages setWidgetActive={setWidgetActive} />
      <div className={styles.contant}>
        <ProductDetailsBreadcrumb
          categorieId={categorieId}
          categorieName={categorieName}
          name={productName}
        />
        <ProductCardDetails
          setWidgetActive={setWidgetActive}
          product={product}
        />
        <WithProductBuyCarousel product={product} />
        {/*   <BlogPostCarousel /> */}
        <WatchedCarousel style={{ background: "#eaeaea" }} />
        {widgetActive ? (
          <div className={styles.widget}>
            <ScrollToTopWidget />
            <PhoneWidget />
          </div>
        ) : (
          ""
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetails;
