import React, { useEffect, useState, memo, useCallback, useMemo } from "react";
import i18next from "i18next";
import { Link } from "react-router-dom";
import axios from "axios";
import styles from "../scss/components/catalogCategoryDetails.module.scss";
import Spinner from "./Spinner";
import CatalogResultNotFound from "./CatalogResultNotFound";

// Оборачиваем компонент в React.memo для предотвращения ненужных перерисовок
const CatalogCategoryDetails = memo(({ section, name }) => {
  const sectionId = section?.data?.id || "";
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentLanguage = i18next.language;

  // Обработчик загрузки данных категорий
  const fetchCategoryData = useCallback(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/product-categories?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=name&fields[1]=roName&populate[image][fields][0]=name&populate[image][fields][1]=url&populate[product_section][fields][0]=id`
        )
        .then((response) => {
          if (response.data.data.length > 0) {
            setCategoryData((prevData) => [...prevData, ...response.data.data]);
            fetchData(page + 1); // рекурсивно вызываем функцию с следующей страницей
          } else {
            setLoading(false); // Завершаем загрузку, когда данные закончились
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
          setLoading(false); // Устанавливаем loading в false в случае ошибки
        });
    };

    fetchData(); // Начинаем с первой страницы
  }, []);

  useEffect(() => {
    fetchCategoryData();
  }, [fetchCategoryData]);

  // Мемоизация списка отфильтрованных категорий
  const filteredCategories = useMemo(() => {
    return categoryData.filter(
      (category) => sectionId === category.attributes.product_section?.data?.id
    );
  }, [categoryData, sectionId]);

  if (loading) {
    return <Spinner />;
  }


  return (
    <div className={styles.catalogCategoryDetails}>
      <div className="container">
        <div className={styles.catalogCategoryDetails__wrapper}>
          <p className={styles.title}>
            {currentLanguage === "ru"
              ? section?.data?.attributes?.name
              : currentLanguage === "ro"
              ? section?.data?.attributes?.roName
              : ""}
          </p>
          {filteredCategories.length > 0 ? (
            <div className={styles.items}>
              {filteredCategories.map((category) => {
                const categoryName = name[category.id];
                return (
                  <Link to={`/catalog/${category.id}`} key={category.id}>
                    <div className={styles.item}>
                      <div className={styles.img}>
                        <img
                          src={`https://api.prodcomfit.md${category.attributes?.image?.data?.attributes?.url}`}
                          alt={category.attributes.name}
                        />
                      </div>
                      <p>
                        {" "}
                        {currentLanguage === "ru"
                          ? category?.attributes?.name
                          : currentLanguage === "ro"
                          ? category?.attributes?.roName
                          : ""}
                      </p>
                    </div>
                  </Link>
                );
              })}
            </div>
          ) : (
            <CatalogResultNotFound />
          )}
        </div>
      </div>
    </div>
  );
});

export default CatalogCategoryDetails;
