import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "../../scss/components/pages/blogPost.module.scss";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import HeaderOtherPages from "../HeaderOtherPages";
import BlogPostItem from "../BlogPostItem";
import Footer from "../Footer";
import BlogPostBreadcrumb from "../breadcrumbs/BlogPostBreadcrumb";
import NotFound from "../pages/NotFound";
import Spinner from "../Spinner";
import InterestingPostCarousel from "../InterestingPostCarousel";
import PhoneWidget from "../mui/PhoneWidget";
import ScrollToTopWidget from "../mui/ScrollToTopWidget";

const BlogPost = () => {
  const [widgetActive, setWidgetActive] = useState(true);

  const { t, i18n } = useTranslation();
  const { postId } = useParams();
  const [post, setPost] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const url = process.env.REACT_APP_API_BLOG_POST;


  useEffect(() => {
    const apiUrl = `https://api.prodcomfit.md/api/blogs/${postId}?pagination[page]=1&pagination[pageSize]=1000&fields[0]=title&fields[1]=roTitle&fields[2]=date&fields[3]=Content&fields[4]=roContent&fields[5]=date&fields[6]=subtitle&fields[7]=roSubtitle&populate[imageInPost][fields][0]=name&populate[imageInPost][fields][1]=url`;

    setIsLoading(true);
    axios
      .get(apiUrl)
      .then((res) => {
        const postData = res.data;
        const title =
          i18n.language === "ro"
            ? postData.data?.attributes?.roTitle
            : i18n.language === "ru"
            ? postData.data?.attributes?.title
            : "";
        const subtitle =
          i18n.language === "ro"
            ? postData.data?.attributes?.roSubtitle
            : i18n.language === "ru"
            ? postData.data?.attributes?.subtitle
            : "";
        const content =
          i18n.language === "ro"
            ? postData.data?.attributes?.roContent
            : i18n.language === "ru"
            ? postData.data?.attributes?.Content
            : "";

        setPost({ ...postData, title, subtitle, content });

        // Устанавливаем isLoading в false после загрузки данных
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Axios Error:", error);

        // Устанавливаем isLoading в false в случае ошибки
        setIsLoading(false);

        // Handle the error or log a custom error message
      });
  }, [postId, i18n.language, url]);

  if (isLoading) {
    return <Spinner />;
  }
  if (!post) {
    return <NotFound />;
  }

  return (
    <div className={styles.blogPost}>
      <HeaderOtherPages setWidgetActive={setWidgetActive} />
      <div className={styles.contant}>
        <BlogPostBreadcrumb title={post.title || ""} />
        <BlogPostItem
          title={post.title}
          subtitle={post.subtitle}
          content={post.content}
          post={post}
        />
        {widgetActive ? (
          <div className={styles.widget}>
            <ScrollToTopWidget />
            <PhoneWidget />
          </div>
        ) : (
          ""
        )}
        <InterestingPostCarousel />
      </div>
      <Footer />
    </div>
  );
};

export default BlogPost;
