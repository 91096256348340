import React, { useEffect, useRef, useState } from "react";
import styles from "../scss/components/brandCarousel.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { useTranslation } from "react-i18next";

const BrandCarousel = () => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef();
  const [brandData, setBrandData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/brands?pagination[page]=${page}&pagination[pageSize]=100&populate[image][fields][0]=url&populate[image][fields][1]=name`
        )
        .then((res) => {
          const products = res.data.data;
          if (products.length > 0) {
            setBrandData((prevData) => [...prevData, ...products]);
            fetchData(page + 1); // Рекурсивный вызов с увеличением номера страницы
          } else {
            setIsLoading(false); // Завершаем загрузку, когда больше нет данных
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
          setIsLoading(false);
        });
    };
  
    fetchData();
  }, []);
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: null,
    prevArrow: null,
    draggable: true, 
    swipe: true, 
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow:2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
    ],
    afterChange: (current) => {
      setCurrentSlide(current);
    },
  };

  return (
    <div className={styles.brandCarousel}>
      <div className="container">
        <p className={styles.sectionName}>{t("carousel.title.brand")}</p>
        <div className={styles.embla}>
          <div className={styles.wrapper}>
            {brandData.length > 0 ? (
              <Slider
                ref={sliderRef}
                className={styles.carousel}
                {...sliderSettings}
              >
                {brandData.map((brand) => {
                  const image = brand.attributes?.image?.data?.attributes;
                  return (
                    <div className={styles.slide} key={brand.id}>
                      <div className={styles.image}>
                        <img
                          src={`https://api.prodcomfit.md${image?.url}`}
                          alt={brand.attributes?.title}
                        />
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandCarousel;
