import { createSlice } from "@reduxjs/toolkit";

// Функция для загрузки данных из localStorage
const loadFromLocalStorage = () => {
  const serializedData = localStorage.getItem("favorites");
  return JSON.parse(serializedData) || [];
};

// Проверяем, существует ли ключ "favorites" в localStorage
if (!localStorage.getItem("favorites")) {
  // Если ключ "favorites" не существует, создаем его и устанавливаем пустой массив
  localStorage.setItem("favorites", JSON.stringify([]));
}

const favoritesSlice = createSlice({
  name: "favorites",
  initialState: {
    favorites: loadFromLocalStorage(), // Загружаем данные из localStorage
  },
  reducers: {
    addToFavorites: (state, action) => {
      const existingProductIndex = state.favorites.findIndex(
        (fav) => fav.id === action.payload.id
      );

      if (existingProductIndex !== -1) {
        // Если товар уже в избранном, обновляем его
        state.favorites[existingProductIndex] = action.payload;
        /* console.log(
          "Товар обновлен в избранном. ID товара:",
          action.payload.id
        ); */
      } else {
        // Если товара нет в избранном, добавляем его
        state.favorites.push(action.payload);
        /* console.log("Добавлено в избранное. ID товара:", action.payload.id); */
      }
     /*  console.log(
        "Текущий массив избранных товаров:",
        JSON.stringify(state.favorites, null, 2)
      ); */

      // Сохраняем данные в localStorage
      const serializedData = JSON.stringify(state.favorites);
      localStorage.setItem("favorites", serializedData);
    },
    removeFromFavorites: (state, action) => {
      // Удаляем товар из избранного
      state.favorites = state.favorites.filter(
        (fav) => fav.id !== action.payload.id
      );
     /*  console.log("Удалено из избранного. ID товара:", action.payload.id);
      console.log(
        "Текущий массив избранных товаров:",
        JSON.stringify(state.favorites, null, 2)
      ); */

      // Сохраняем данные в localStorage
      const serializedData = JSON.stringify(state.favorites);
      localStorage.setItem("favorites", serializedData);
    },
  },
});

export const { addToFavorites, removeFromFavorites } = favoritesSlice.actions;

export const selectFavorites = (state) => {
  return state.favorites.favorites;
};

export default favoritesSlice.reducer;
