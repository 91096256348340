import React, { useEffect, useRef, useState } from "react";
import styles from "../scss/components/withProductBuyCarousel.module.scss";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WithProductBuyCarouselCard from "./WithProductBuyCarouselCard";

const fetchProductData = async () => {
  try {
    const res = await axios.get(
      "https://api.prodcomfit.md/api/products?pagination[page]=1&pagination[pageSize]=1000&populate[recomendations][populate]=*"
    );
    return res.data.data;
  } catch (error) {
    console.error("Ошибка при запросе данных:", error);
    return [];
  }
};

const WithProductBuyCarousel = ({ product }) => {
  const { t, i18n } = useTranslation();
  const id = product.data.id;
   const token = process.env.REACT_APP_TOKEN;
  const [productData, setProductData] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchProductData().then((data) => {
      setProductData(data);
      const productItem = data.find((item) => item.id === id);
      setFilteredProduct(productItem);
    });
  }, [id]);
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: null,
    prevArrow: null,
    draggable: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
    ],
    afterChange: (current) => {
      setCurrentSlide(current);
    },
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(true),
  };
  useEffect(() => {
    let timer;
    if (isDragging) {
      // Запускаем таймер при установке isDragging в true
      timer = setTimeout(() => {
        setIsDragging(false);
      }, 100);
    }
    return () => clearTimeout(timer);
  }, [isDragging]);
  const [commentData, setCommentData] = useState([]);
  useEffect(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/product-comments?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=article&fields[1]=estimation`
        )
        .then((res) => {
          const products = res.data.data;
          if (products.length > 0) {
            setCommentData((prevData) => [...prevData, ...products]);
            fetchData(page + 1);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
          setIsLoading(false);
        });
    };

    fetchData();
  }, []);
  const [hitData, setHitData] = useState({});
  useEffect(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/order-products?pagination[page]=${page}&pagination[pageSize]=1000&fields[0]=article&fields[1]=quantity`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const orderProductData = response.data.data;
          const salesById = orderProductData.reduce((acc, product) => {
            const { article, quantity } = product.attributes;
            acc[article] = (acc[article] || 0) + quantity;
            return acc;
          }, {});
          const topSellingProducts = Object.keys(salesById)
            .sort((a, b) => salesById[b] - salesById[a])
            .slice(0, 14);
          setHitData(
            topSellingProducts.reduce((acc, id) => {
              acc[id] = true;
              return acc;
            }, {})
          );
          const nextPageExists =
            response.data.meta.pagination.page *
              response.data.meta.pagination.pageSize <
            response.data.meta.pagination.totalCount;
          if (nextPageExists) {
            fetchData(page + 1);
          } else {
          }
        })
        .catch((error) => {
          /*  console.error("Error fetching order products data:", error); */
        });
    };

    fetchData();
  }, []);
  const renderRecommendations = (recommendations) =>
    recommendations.map((recommendation) => {
      // Фильтрация и сортировка комментариев для данного продукта
      const sortedComments = commentData
        .filter(
          (comment) =>
            comment.attributes.article === recommendation.attributes.article
        )
        .sort((a, b) => {
          const dateA = a.attributes.date || a.attributes.createdAt;
          const dateB = b.attributes.date || b.attributes.createdAt;
          return new Date(dateB) - new Date(dateA);
        });
      // Вычисляем среднюю оценку и количество отзывов
      const averageRating =
        sortedComments.reduce(
          (total, comment) => total + comment.attributes.estimation,
          0
        ) / (sortedComments.length || 1); // Деление на ноль обрабатываем
      const reviewCount = sortedComments.length;

      const isHit = hitData[recommendation.attributes.article] === true;

      return (
        <div key={recommendation.attributes.article} className={styles.item}>
          <WithProductBuyCarouselCard
            isDragging={isDragging}
            id={recommendation.id}
            title={recommendation.attributes.title}
            roTitle={recommendation.attributes.roTitle}
            translateTitle={
              i18n.language === "ro"
                ? recommendation.attributes.roTitle
                : recommendation.attributes.title
            }
            article={recommendation.attributes.article}
            price={recommendation.attributes.price}
            image={
              recommendation.attributes.imageMain.data?.attributes?.url || ""
            }
            priceDiscount={recommendation.attributes.priceDiscount}
            widgetNew={recommendation.attributes.new}
            quantity={recommendation.attributes.quantity}
            averageRating={averageRating}
            reviewCount={reviewCount}
            isHit={isHit}
          />
        </div>
      );
    });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentSlidesToShow, setCurrentSlidesToShow] = useState(0);
  const getSlidesToShow = (width, settings) => {
    if (width < 767) {
      return settings.responsive.find((s) => s.breakpoint === 767).settings
        .slidesToShow;
    } else if (width >= 767 && width < 1024) {
      return settings.responsive.find((s) => s.breakpoint === 1024).settings
        .slidesToShow;
    } else if (width >= 1024 && width < 1300) {
      return settings.responsive.find((s) => s.breakpoint === 1300).settings
        .slidesToShow;
    } else {
      return settings.slidesToShow;
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setCurrentSlidesToShow(getSlidesToShow(windowWidth, sliderSettings));
  }, [windowWidth, sliderSettings]);
  useEffect(() => {
    const sliderElement = sliderRef.current;
    if (!sliderElement || !sliderElement.classList) return;

    if (
      filteredProduct.attributes.recomendations.data.length <
      currentSlidesToShow
    ) {
      sliderElement.classList.add(styles.noTransform);
    } else {
      sliderElement.classList.remove(styles.noTransform);
    }
  }, [filteredProduct, currentSlidesToShow]);

  return (
    <div
      className={`${styles.withProductBuyCarousel} ${
        filteredProduct &&
        filteredProduct.attributes.recomendations.data.length === 0
          ? styles.hidden
          : ""
      }`}
    >
      <div className="container">
        <div className={styles.withProductBuyCarousel__wrapper}>
          <p className={styles.title}>{t("carousel.title.withProductBuy")}</p>

          {filteredProduct &&
          filteredProduct.attributes.recomendations.data.length > 0 ? (
            <Slider
              ref={sliderRef}
              className={`${styles.carousel} ${
                filteredProduct.attributes.recomendations.data.length <
                currentSlidesToShow
                  ? styles.noTransform
                  : ""
              }`}
              {...sliderSettings}
            >
              {filteredProduct &&
                renderRecommendations(
                  filteredProduct.attributes.recomendations.data
                )}
            </Slider>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default WithProductBuyCarousel;
