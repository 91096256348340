import React from "react";
import AdminHeader from "../AdminHeader";
import NewOrderItem from "../NewOrderItem";

import styles from "../../scss/components/newOrder.module.scss";

const NewOrder = () => {
  return (
    <div>
      <AdminHeader />
      <NewOrderItem />
    </div>
  );
};

export default NewOrder;
