import React from "react";
import styles from "../scss/components/blogPostItem.module.scss";
import ReactMarkdown from "react-markdown";

const BlogPostItem = ({ post, title, content, subtitle }) => {
  const imageInPost = post?.data?.attributes?.imageInPost?.data.attributes.url;
  const date = post?.data?.attributes?.date;

  return (
    <div className={styles.blogPostItem}>
      <div className={styles.blogPostItem__container}>
        <div className={styles.blogPostItem__wrapper}>
          <div className={styles.header}>
            <div className={styles.img}>
              {imageInPost && (
                <img
                  src={`https://api.prodcomfit.md${imageInPost}`}
                  alt={title}
                />
              )}
            </div>
            <div className={styles.content}>
              <p className={styles.date}>{date}</p>
              <p className={styles.title}>{title}</p>
              <p className={styles.subtitle}>{subtitle}</p>
            </div>
          </div>
          <div className={styles.contant}>
            <ReactMarkdown className={styles.descriptions}>
              {content}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPostItem;
