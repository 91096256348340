import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setOrderData, selectOrderData } from "../store/features/orderSlice";
import { selectBasket } from "../store/features/basketSlice";
import { removeFromBasket } from "../store/features/basketSlice";

import BasketCard from "./BasketCard";
import styles from "../scss/components/stepperFirstStep.module.scss";

function calculateDiscountedPrice(originalPrice, discountPercentage) {
  const discountedPrice = (originalPrice * (100 - discountPercentage)) / 100;
  return Math.round(discountedPrice * 100) / 100;
}

const StepperFirstStep = ({ products, onNext }) => {
  const [updateProduct, setUpdateProduct] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [totalBasket, setTotalBasket] = useState(0);
  const [finalTotalBasket, setFinalTotalBasket] = useState(0);
  const [basketTotals, setBasketTotals] = useState({});
  const [appliedDiscount, setAppliedDiscount] = useState(null);

  // Эффект для обновления Redux store при изменении updateProduct
  useEffect(() => {
    if (updateProduct !== products) {
      // Здесь диспатчим экшен для обновления products в store
      // dispatch(updateProductsInStore(updateProduct));
    }
  }, [updateProduct, products, dispatch]);
  // Эффект для инициализации updateProduct
  useEffect(() => {
    setUpdateProduct(products);
  }, [products]);
  const updateTotalBasket = (totalPrice, id) => {
    setBasketTotals((prevTotals) => ({ ...prevTotals, [id]: totalPrice }));
  };
  useEffect(() => {
    // Только один useEffect для обновления totalBasket
    setTotalBasket(
      Object.values(basketTotals).reduce((acc, curr) => acc + curr, 0)
    );
  }, [basketTotals]);
  const [promocodData, setPromocodData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async (page = 1) => {
      try {
        const res = await axios.get(
          `https://api.prodcomfit.md/api/promocodes?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=code&fields[1]=discount&fields[2]=actionFrom&fields[3]=actionTo`
        );
        const promocodes = res.data.data;
        if (promocodes.length > 0) {
          setPromocodData((prevData) => [...prevData, ...promocodes]);
          fetchData(page + 1); // Рекурсивный вызов с увеличением номера страницы
        } else {
          setIsLoading(false); // Завершаем загрузку, когда больше нет данных
        }
      } catch (error) {
        console.error("Ошибка при запросе данных:", error);
        setIsLoading(false); // Устанавливаем состояние загрузки в false в случае ошибки
      }
    };
  
    fetchData(); 
  }, []);
  const payment = promocodData.map((item) => item.attributes.code);
  const [enterPromo, setEnterPromo] = useState("");
  const [tempValue, setTempValue] = useState("");
  const [appliedPromo, setAppliedPromo] = useState(null);
  const handleChange = (e) => {
    setTempValue(e.target.value);
  };
  const [isPromoValid, setIsPromoValid] = useState(false);
  const [isPromoExpired, setIsPromoExpired] = useState(false);
  const [promoDoesNotExist, setPromoDoesNotExist] = useState(false);

  useEffect(() => {
    // Устанавливаем начальные значения
    setIsPromoExpired(false);
    setPromoDoesNotExist(false);
    setFinalTotalBasket(totalBasket);
    setAppliedDiscount(null);
    // Если промокод не был введён, не нужно выполнять проверку
    if (!appliedPromo) {
      return;
    }
    const foundPromo = promocodData.find(
      (promo) => promo.attributes.code === appliedPromo.trim()
    );
    if (foundPromo) {
      const currentDate = new Date();
      const actionFrom = foundPromo.attributes.actionFrom
        ? new Date(foundPromo.attributes.actionFrom)
        : null;
      const actionTo = foundPromo.attributes.actionTo
        ? new Date(foundPromo.attributes.actionTo)
        : null;

      // Проверяем диапазон дат, если они заданы, иначе считаем промокод всегда действительным
      if (
        (actionFrom &&
          actionTo &&
          currentDate >= actionFrom &&
          currentDate <= actionTo) ||
        (!actionFrom && !actionTo)
      ) {
        const discount = foundPromo.attributes.discount;
        const newTotal = totalBasket * (1 - discount / 100);
        setFinalTotalBasket(newTotal);
        setAppliedDiscount(discount);
        setIsPromoValid(true);
      } else {
        setIsPromoExpired(true);
        setIsPromoValid(false);
      }
    } else {
      // Промокод не найден после введения
      setPromoDoesNotExist(true);
      setIsPromoValid(false);
    }
  }, [appliedPromo, promocodData, totalBasket]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setIsPromoValid(payment.includes(tempValue));
      setAppliedPromo(tempValue);
      setTempValue("");
    }
  };

  // Функция для обновления состояния при удалении товара из корзины
  const handleRemoveFromBasket = (id) => {
    dispatch(removeFromBasket({ id }));
    setBasketTotals((prevTotals) => {
      const newTotals = { ...prevTotals };
      delete newTotals[id];
      return newTotals;
    });
    // Также обновите updateProduct, если оно используется
    if (updateProduct) {
      setUpdateProduct((prevProducts) =>
        prevProducts.filter((product) => product.id !== id)
      );
    }
  };

  const handleProceed = async () => {
    let updatedProducts;
    if (Array.isArray(updateProduct)) {
      updatedProducts = await Promise.all(
        updateProduct.map(async (product) => {
          const discountedPrice = calculateDiscountedPrice(
            product.price,
            product.priceDiscount
          );
          const summary = discountedPrice * product.selectquantity;
          return {
            id: product.id,
            article: product.article,
            ruName: product.title,
            roName: product.roTitle,
            price: product.price,
            total: summary,
            discount: product.priceDiscount,
            discountPrice: discountedPrice,
            quantity: product.selectquantity,
          };
        })
      );
    }
    if (!updateProduct) {
      updatedProducts = products.map((product) => {
        const discountedPrice = calculateDiscountedPrice(
          product.price,
          product.priceDiscount
        );
        const summary = discountedPrice * product.selectquantity;
        return {
          id: product.id,
          article: product.article,
          ruName: product.title,
          roName: product.roTitle,
          price: product.price,
          total: summary,
          discount: product.priceDiscount,
          discountPrice: discountedPrice,
          quantity: product.selectquantity,
        };
      });
    }
    const selectedPromo = promocodData.find(
      (item) => item.attributes.code === appliedPromo
    );
    let promoPayload = {
      code: "",
      discount: 0,
      discountPrice: 0,
    };
    // Проверка на существование selectedPromo и его свойств перед использованием
    if (selectedPromo && selectedPromo.attributes) {
      const currentDate = new Date();
      const actionFrom = selectedPromo.attributes.actionFrom
        ? new Date(selectedPromo.attributes.actionFrom)
        : null;
      const actionTo = selectedPromo.attributes.actionTo
        ? new Date(selectedPromo.attributes.actionTo)
        : null;
      // Проверяем диапазон дат, если они заданы, иначе считаем промокод всегда действительным
      if (
        (actionFrom &&
          actionTo &&
          currentDate >= actionFrom &&
          currentDate <= actionTo) ||
        (!actionFrom && !actionTo)
      ) {
        const discountAmount = Number(
          ((totalBasket * selectedPromo.attributes.discount) / 100).toFixed(2)
        );
        const promoDiscount = selectedPromo.attributes.discount;
        // Если промокод действителен, обновляем promoPayload данными промокода
        promoPayload = {
          code: appliedPromo,
          discount: promoDiscount,
          discountPrice: discountAmount,
        };
      }
    }
    const finalPriceBasket = parseFloat(Number(finalTotalBasket).toFixed(2));
    const orderData = {
      products: updatedProducts,
      total: finalPriceBasket,
      promo: promoPayload, // Здесь мы используем promoPayload, который может быть пустым объектом или содержать данные о скидке
    };
    localStorage.setItem("orderData", JSON.stringify(orderData));
    dispatch(setOrderData(orderData));
    onNext();
  };
  /* console.log("products", products); */
  /*  console.log("updateProduct", updateProduct); */
  return (
    <div className={styles.firstStep}>
      <div className={styles.firstStepItem}>
        <div className={styles.firstStepItemHeader}>
          <p>{t("stepper.stepperFirstStep.headerTitle.info")}</p>
          <p>{t("stepper.stepperFirstStep.headerTitle.quentity")}</p>
          <p>{t("stepper.stepperFirstStep.headerTitle.price")}</p>
          <p>{t("stepper.stepperFirstStep.headerTitle.total")}</p>
        </div>
        <div className={styles.firstStepGrid}>
          {updateProduct &&
            updateProduct.map((product) => (
              <BasketCard
                key={product.id}
                article={product.article}
                id={product.id}
                title={product.title}
                roTitle={product.roTitle}
                image={product.image}
                price={product.price}
                priceDiscount={product.priceDiscount}
                quantity={product.quantity}
                selectquantity={product.selectquantity}
                updateTotalBasket={updateTotalBasket}
                setUpdateProduct={setUpdateProduct}
                onRemoveFromBasket={() => handleRemoveFromBasket(product.id)}
              />
            ))}
        </div>
      </div>
      <div className={styles.order}>
        <p className={styles.orderTitle}>
          {t("stepper.stepperFirstStep.order.title")}
        </p>
        <div className={styles.orderHeader}>
          <p>
            {products.length}
            <span>{t("stepper.stepperFirstStep.order.titleQuentity")}</span>
          </p>
          <p className={styles.orderPrice}>
            {(totalBasket.toFixed(2) * 100).toFixed(0) / 100}
            <span>{t("word.lei")}</span>
          </p>
        </div>
        <div className={styles.promocode}>
          <p>{t("stepper.stepperFirstStep.order.promocod")}</p>
          <input
            type="text"
            value={tempValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={t(
              "stepper.stepperFirstStep.order.promocodPlaceholder"
            )}
          />
        </div>
        {appliedDiscount !== null ? (
          <p className={styles.promocodeDiscount}>
            {t("stepper.stepperFirstStep.order.discount")}
            <span>{appliedDiscount}</span>%
          </p>
        ) : isPromoExpired ? (
          <p className={styles.promocodeDiscount}>
            {t("stepper.stepperFirstStep.order.promoExpired")}
          </p>
        ) : promoDoesNotExist ? (
          <p className={styles.promocodeDiscount}>
            {t("stepper.stepperFirstStep.order.promoDoesNotExist")}
          </p>
        ) : null}

        <div className={styles.orderFooter}>
          <p>{t("stepper.stepperFirstStep.order.titleTotal")}</p>
          <p className={styles.orderPrice}>
            {(finalTotalBasket.toFixed(2) * 100).toFixed(0) / 100}
            <span>{t("word.lei")}</span>
          </p>
        </div>
        <div onClick={handleProceed} className={styles.orderBtn}>
          <p>{t("stepper.stepperFirstStep.order.continue")}</p>
        </div>
      </div>
    </div>
  );
};

export default StepperFirstStep;
