import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "../scss/components/adminItems.module.scss";
import { useTranslation } from "react-i18next";
import PaginationRounded from "./mui/Pagination";
import { useDebounce } from "use-debounce";
import { Link } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import Spinner from "./Spinner";

const AdminItems = () => {
  const { t, i18n } = useTranslation();
  const [orderDataCount, setOrderDataCount] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [deleteDataConfirm, setDeleteDataConfirm] = useState(false);
  const [deleteOrderId, setDeleteOrderId] = useState(null);

  const [storedUserData, setStoredUserData] = useState(
    JSON.parse(localStorage.getItem("user-data")) || {}
  );
  const token = storedUserData.token;

  useEffect(() => {
    axios
      .get(
        "https://api.prodcomfit.md/api/orders?pagination[page]=1&pagination[pageSize]=100&fields[0]=id",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((response) => {
        setOrderDataCount(response.data.data);
      })
      .catch((error) => console.error("Ошибка при запросе данных:", error));
  }, []);

  const fetchDataInChunks = useCallback(async () => {
    const pageSize = 50; // количество элементов в одном чанке
    const totalChunks = Math.ceil(orderDataCount.length / pageSize);

    setIsLoading(true); // Начинаем загрузку, показываем спиннер

    for (let i = 0; i < totalChunks; i++) {
      try {
        const response = await axios.get(
          `https://api.prodcomfit.md/api/orders?pagination[page]=${
            i + 1
          }&pagination[pageSize]=${pageSize}&fields[0]=id&fields[1]=dispatched&fields[2]=createdAt&fields[3]=name&fields[4]=number&fields[5]=email&fields[6]=citySelect&fields[7]=city&fields[8]=streetHouseApartment&fields[9]=comment&fields[10]=postalIndex&fields[11]=delivery&fields[12]=usedPromocode&fields[13]=usedPromocodeDiscount&fields[14]=usedPromocodeDiscountPrice&fields[15]=payment&populate[products][fields][0]=id`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setOrderData((prevData) => [...prevData, ...response.data.data]);
        // Выключаем спиннер после загрузки первого чанка данных
        if (i === 0) {
          setIsLoading(false);
        }
      } catch (error) {
        /*    console.error("Ошибка при запросе данных:", error); */
        if (i === 0) {
          setIsLoading(false);
        }
      }
    }
  });

  useEffect(() => {
    if (orderDataCount.length > 0) {
      fetchDataInChunks();
    }
  }, [orderDataCount]);
  const isMountedRef = useRef(true);

  useEffect(() => {
    isMountedRef.current = true;
    return () => (isMountedRef.current = false);
  }, []);

  const dispatchedValues = orderData.map((item) => item.attributes.dispatched);
  const falseCount = dispatchedValues.filter((value) => value === false).length;
  const trueCount = dispatchedValues.filter((value) => value === true).length;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000); // 1000 мс задержка
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [forceRender, setForceRender] = useState(false);
  const [filteredItemsByDate, setFilteredItemsByDate] = useState([]);

  useEffect(() => {
    // Установка начальной даты на начало текущего месяца
    const today = new Date();
    const oneMonthAgo = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      today.getDate()
    );
    setStartDate(formatDate(oneMonthAgo));

    // Установка начальной даты на сегодня
    setEndDate(formatDate(today));
  }, []);
  const handleDeleteOrderConfirmOpen = (orderId) => {
    setDeleteOrderId(orderId);
    setDeleteDataConfirm(true);
  };
  const handleDeleteOrderConfirmClose = () => {
    setDeleteDataConfirm(false);
    setDeleteOrderId(null);
  };
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    // Дополнительные действия при изменении начальной даты
  };
  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
    // Дополнительные действия при изменении конечной даты
  };
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const getRelevanceScore = (item, searchTerm) => {
    let score = 0;
    let multiplier = 128; // Выбираем начальный множитель, который можно будет уменьшать

    // Проверка на точное совпадение ID
    if (item.id.toString() === searchTerm) {
      score += multiplier; // Большой вес для точных совпадений ID
    } else if (item.id.toString().includes(searchTerm)) {
      score += multiplier / 2; // Меньший вес для частичных совпадений ID
    }

    multiplier /= 2; // Уменьшаем множитель для следующего поля

    // Дата создания
    const createdAtStr = new Date(item.attributes.createdAt).toLocaleDateString(
      "ru-RU"
    );
    if (createdAtStr === searchTerm) {
      score += multiplier;
    } else if (createdAtStr.includes(searchTerm)) {
      score += multiplier / 2;
    }

    multiplier /= 2; // Продолжаем уменьшать множитель для следующего поля

    // Имя
    if (item.attributes.name && item.attributes.name === searchTerm) {
      score += multiplier;
    } else if (
      item.attributes.name &&
      item.attributes.name.includes(searchTerm)
    ) {
      score += multiplier / 2;
    }

    multiplier /= 2;

    // Номер
    if (item.attributes.number && item.attributes.number === searchTerm) {
      score += multiplier;
    } else if (
      item.attributes.number &&
      item.attributes.number.includes(searchTerm)
    ) {
      score += multiplier / 2;
    }

    multiplier /= 2;

    // Email
    if (item.attributes.email && item.attributes.email === searchTerm) {
      score += multiplier;
    } else if (
      item.attributes.email &&
      item.attributes.email.includes(searchTerm)
    ) {
      score += multiplier / 2;
    }

    multiplier /= 2;

    // Город (из списка)
    if (
      item.attributes.citySelect &&
      item.attributes.citySelect === searchTerm
    ) {
      score += multiplier;
    } else if (
      item.attributes.citySelect &&
      item.attributes.citySelect.includes(searchTerm)
    ) {
      score += multiplier / 2;
    }

    multiplier /= 2;

    // Улица, дом, квартира
    if (
      item.attributes.streetHouseApartment &&
      item.attributes.streetHouseApartment === searchTerm
    ) {
      score += multiplier;
    } else if (
      item.attributes.streetHouseApartment &&
      item.attributes.streetHouseApartment.includes(searchTerm)
    ) {
      score += multiplier / 2;
    }

    multiplier /= 2;

    // Почтовый индекс
    if (
      item.attributes.postalIndex &&
      item.attributes.postalIndex === searchTerm
    ) {
      score += multiplier;
    } else if (
      item.attributes.postalIndex &&
      item.attributes.postalIndex.includes(searchTerm)
    ) {
      score += multiplier / 2;
    }

    multiplier /= 2;

    // Доставка
    if (item.attributes.delivery && item.attributes.delivery === searchTerm) {
      score += multiplier;
    } else if (
      item.attributes.delivery &&
      item.attributes.delivery.includes(searchTerm)
    ) {
      score += multiplier / 2;
    }

    multiplier /= 2;

    // Использованный промокод
    if (
      item.attributes.usedPromocode &&
      item.attributes.usedPromocode === searchTerm
    ) {
      score += multiplier;
    } else if (
      item.attributes.usedPromocode &&
      item.attributes.usedPromocode.includes(searchTerm)
    ) {
      score += multiplier / 2;
    }

    return score;
  };
  // Фильтрация элементов на основе дат
  const filteredItems = useMemo(() => {
    // Проверяем, являются ли startDate и endDate валидными датами
    const isStartDateValid = startDate && !isNaN(new Date(startDate).getTime());
    const isEndDateValid = endDate && !isNaN(new Date(endDate).getTime());

    // Если обе даты невалидны, возвращаем все элементы из filteredRecords
    if (!isStartDateValid && !isEndDateValid) {
      return filteredRecords;
    }

    // Применяем фильтрацию к filteredRecords
    return filteredRecords.filter((item) => {
      const itemDate = new Date(item.attributes.createdAt);

      // Создаем новые объекты дат для начала и конца дня
      const startOfDay = isStartDateValid
        ? new Date(new Date(startDate).setHours(0, 0, 0, 0))
        : null;
      const endOfDay = isEndDateValid
        ? new Date(new Date(endDate).setHours(23, 59, 59, 999))
        : null;

      return (
        (!startOfDay || itemDate >= startOfDay) &&
        (!endOfDay || itemDate <= endOfDay)
      );
    });
  }, [filteredRecords, startDate, endDate]);
  // Фильтрация элементов на основе запроса
  useEffect(() => {
    // Обрезаем пробелы в начале
    const trimmedSearchTerm = debouncedSearchTerm.trim();
    // Определяем, какой массив данных использовать для поиска
    const dataToSearch =
      filteredItemsByDate.length > 0 ? filteredItemsByDate : orderData;

    const filtered = dataToSearch.filter((item) => {
      const itemIDString = item.id.toString();
      const createdAtStr = new Date(
        item.attributes.createdAt
      ).toLocaleDateString("ru-RU");

      return (
        (item.id && itemIDString.includes(trimmedSearchTerm)) ||
        createdAtStr.includes(trimmedSearchTerm) ||
        (item.attributes.name &&
          item.attributes.name.includes(trimmedSearchTerm)) ||
        (item.attributes.number &&
          item.attributes.number.includes(trimmedSearchTerm)) ||
        (item.attributes.email &&
          item.attributes.email.includes(trimmedSearchTerm)) ||
        (item.attributes.citySelect &&
          item.attributes.citySelect.includes(trimmedSearchTerm)) ||
        (item.attributes.streetHouseApartment &&
          item.attributes.streetHouseApartment.includes(trimmedSearchTerm)) ||
        (item.attributes.postalIndex &&
          item.attributes.postalIndex.includes(trimmedSearchTerm)) ||
        (item.attributes.delivery &&
          item.attributes.delivery.includes(trimmedSearchTerm)) ||
        (item.attributes.usedPromocode &&
          item.attributes.usedPromocode.includes(trimmedSearchTerm))
      );
    });
    // Поиск по приоритету
    const sortedByRelevance = filtered.sort((a, b) => {
      let scoreA = getRelevanceScore(a, trimmedSearchTerm);
      let scoreB = getRelevanceScore(b, trimmedSearchTerm);

      return scoreB - scoreA;
    });
    setFilteredRecords(sortedByRelevance);
    setSortConfig({ key: null, direction: "ascending" });

    /* console.log(sortedByRelevance); */
  }, [debouncedSearchTerm, orderData, filteredItemsByDate]);
  // Сортировка элементов на клика
  const sortedFilteredItems = useMemo(() => {
    let items = [...filteredItems];

    // Если активен поиск, сортируем только по текущему запросу
    if (debouncedSearchTerm) {
      const sortedBySearch = items.sort((a, b) => {
        let scoreA = getRelevanceScore(a, debouncedSearchTerm);
        let scoreB = getRelevanceScore(b, debouncedSearchTerm);

        return scoreB - scoreA;
      });
      return sortedBySearch;
    }

    // Сортировка по полю attributes.dispatched (false первые) по умолчанию
    items.sort((a, b) => {
      let aValue = a.attributes.dispatched;
      let bValue = b.attributes.dispatched;

      if (aValue === bValue) {
        return 0;
      } else {
        // Если aValue - false, то сортируем его выше
        return aValue ? 1 : -1;
      }
    });

    // Сортировка по другим полям
    if (sortConfig.key !== null && sortConfig.key !== "attributes.dispatched") {
      const getNestedValue = (obj, path) => {
        return path.split(".").reduce((value, key) => {
          return value && value[key] !== undefined ? value[key] : null;
        }, obj);
      };

      items.sort((a, b) => {
        let aValue = getNestedValue(a, sortConfig.key);
        let bValue = getNestedValue(b, sortConfig.key);

        // Обработка булевых значений
        if (typeof aValue === "boolean") {
          if (aValue === bValue) {
            return 0;
          } else {
            // Если aValue - false, то сортируем его выше
            return aValue ? 1 : -1;
          }
        }

        // Обработка чисел
        if (typeof aValue === "number" && typeof bValue === "number") {
          return sortConfig.direction === "ascending"
            ? aValue - bValue
            : bValue - aValue;
        }

        // Обработка строк
        if (typeof aValue === "string" && typeof bValue === "string") {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    return items;
  }, [filteredItems, sortConfig, debouncedSearchTerm]);
  // Обработчик клика по заголовку столбца
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key) {
      // Если ключ уже установлен, переключаем направление
      direction =
        sortConfig.direction === "ascending" ? "descending" : "ascending";
    }
    setSortConfig({ key, direction });
    setForceRender(!forceRender); // Переключаем для принудительной перерисовки

    // Выводим отсортированные элементы в консоль
    const sortedItems = sortedFilteredItems;
    /* console.log("Sorted Items:", sortedItems); */
  };
  const paginatedItemsMemo = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return sortedFilteredItems.slice(indexOfFirstItem, indexOfLastItem);
  }, [sortedFilteredItems, currentPage, itemsPerPage, orderData]);

  const pageCount = useMemo(
    () => Math.ceil(filteredItems.length / itemsPerPage),
    [filteredItems.length, itemsPerPage]
  );
  const onPageChange = useCallback((event, newPage) => {
    setCurrentPage(newPage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const handleOrderDelete = async () => {
    /*  console.log("Начало удаления заказа"); */
    try {
      if (!deleteOrderId) {
        /*   console.error("Ошибка: Не удалось получить ID заказа для удаления."); */
        return;
      }
      /*  console.log("ID заказа для удаления:", deleteOrderId); */

      // Предположим, что данные заказа хранятся в состоянии `data`
      const order = data.find((order) => order.id === deleteOrderId);
      if (!order) {
        /*  console.error(`Заказ с ID ${deleteOrderId} не найден.`); */
        return;
      }

      const orderProductsData = order.attributes.products.data;
      /*  console.log(`Товары в заказе для удаления:`, orderProductsData); */

      if (orderProductsData.length > 0) {
        await Promise.all(
          orderProductsData.map(async (product) => {
            /*   console.log(`Попытка удаления товара с ID: ${product.id}`); */
            const response = await axios.delete(
              `https://api.prodcomfit.md/api/order-products/${product.id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            /*   console.log(`Ответ на запрос удаления товара с ID ${product.id}:`, response.status); */
          })
        );
      }

      // После удаления всех товаров удаляем сам заказ
      const deleteOrderResponse = await axios.delete(
        `https://api.prodcomfit.md/api/orders/${deleteOrderId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      /* console.log(`Заказ с ID ${deleteOrderId} успешно удален.`, deleteOrderResponse.status); */

      // Обновление состояния для отражения удаления заказа
      if (!isMountedRef.current) return;
      setData((prevData) =>
        prevData.filter((order) => order.id !== deleteOrderId)
      );
      setDeleteDataConfirm(false);
      setDeleteOrderId(null);
    } catch (error) {
      /*     console.error("Ошибка при удалении заказа и его товаров:", error); */
    }
  };

  useEffect(() => {
    isMountedRef.current = true;
    return () => (isMountedRef.current = false);
  }, []);
  useEffect(() => {
    setData(paginatedItemsMemo);
  }, [paginatedItemsMemo, orderData]);

  const tableRowCount = (
    <>
      <span className={styles["table-row-count"]}>
        {t("admin.field.all")} ({orderData.length})
      </span>

      <span className={styles["table-row-count"]}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="15"
          width="15"
          viewBox="0 0 640 512"
        >
          <path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM288 368a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm211.3-43.3c-6.2-6.2-16.4-6.2-22.6 0L416 385.4l-28.7-28.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l40 40c6.2 6.2 16.4 6.2 22.6 0l72-72c6.2-6.2 6.2-16.4 0-22.6z" />
        </svg>
        {t("admin.field.sent")} ({trueCount || 0})
      </span>

      <span className={styles["table-row-count"]}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="15"
          width="15"
          viewBox="0 0 640 512"
        >
          <path
            fill="orange"
            d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zm48 96a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm0 240a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm0-192c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16 16s16-7.2 16-16V288c0-8.8-7.2-16-16-16z"
          />
        </svg>{" "}
        {t("admin.field.waiting")} ({falseCount || 0})
      </span>
    </>
  );

  const mappedRecords = data.map((item) => (
    <tr key={item.id}>
      <td className={`${styles.item} ${styles["sticky-left"]}`}>
        {item.attributes.dispatched === false ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="30"
            width="30"
            viewBox="0 0 640 512"
          >
            <path
              fill="orange"
              d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zm48 96a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm0 240a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm0-192c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16 16s16-7.2 16-16V288c0-8.8-7.2-16-16-16z"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="30"
            width="30"
            viewBox="0 0 640 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM288 368a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm211.3-43.3c-6.2-6.2-16.4-6.2-22.6 0L416 385.4l-28.7-28.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l40 40c6.2 6.2 16.4 6.2 22.6 0l72-72c6.2-6.2 6.2-16.4 0-22.6z" />
          </svg>
        )}
      </td>
      <td>{item.id}</td>
      <td>{new Date(item.attributes.createdAt).toLocaleDateString("ru-RU")}</td>
      <td>{item.attributes.name}</td>
      <td>{item.attributes.number}</td>
      <td>{item.attributes.email}</td>
      <td>{item.attributes.citySelect}</td>
      <td>{item.attributes.city}</td>
      <td>{item.attributes.streetHouseApartment}</td>
      <td>
        {item.attributes.comment === null || item.attributes.comment === ""
          ? t("adminOrderProduct.textAbsent")
          : t("adminOrderProduct.textExists")}
      </td>
      <td>{item.attributes.postalIndex}</td>
      <td>{item.attributes.delivery}</td>
      <td>
        {item.attributes.usedPromocode === null ||
        item.attributes.usedPromocode === ""
          ? "-"
          : item.attributes.usedPromocode}
      </td>
      <td>{item.attributes.usedPromocodeDiscount}</td>
      <td>{item.attributes.usedPromocodeDiscountPrice}</td>
      <td>{item.attributes?.products?.data?.length || 0}</td>
      <td>{item.attributes.payment}</td>
      <td className={styles["sticky-right"]}>
        <button onClick={() => handleDeleteOrderConfirmOpen(item.id)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="14"
            viewBox="0 0 448 512"
          >
            <path
              fill="red"
              d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
            />
          </svg>
        </button>
        <Link to={`/order/${item.id}`}>
          <button className={`${styles.btn} ${styles["btn--primary"]}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="16"
              width="16"
              viewBox="0 0 512 512"
            >
              <path
                fill="#000"
                d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
              />
            </svg>
          </button>
        </Link>
      </td>
    </tr>
  ));

  return (
    <div className={styles.adminItems}>
      <div className={styles.adminItems__container}>
        <div className={styles.adminItems__wrapper}>
          <div className={styles["table-widget"]}>
            <span className={styles["caption-container"]}>
              <div>
                <span className={styles["table-title"]}>
                  {t("admin.field.order")}
                </span>
                {tableRowCount}
              </div>
              <div className={styles.tools}>
                <div className={styles.sortData}>
                  <p>{t("admin.field.textSort")}</p>
                  <span>|</span>
                  <div>
                    <span>{t("admin.field.textSortFrom")}</span>
                    <input
                      type="date"
                      value={startDate}
                      onChange={handleStartDateChange}
                    />
                    <span>{t("admin.field.textSortTo")}</span>
                    <input
                      type="date"
                      value={endDate}
                      onChange={handleEndDateChange}
                    />
                  </div>
                </div>
                <div className={styles.search}>
                  <input
                    type="text"
                    placeholder={t("admin.field.searchPlaceholder")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="16"
                    viewBox="0 0 512 512"
                    fill="#a0a7b1"
                  >
                    <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                  </svg>
                </div>
              </div>
              <Link to="/newOrder">
                <button className={styles.addNewOrder}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="16"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="#fff"
                      d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"
                    />
                  </svg>
                  {t("admin.field.textAddOrder")}
                </button>
              </Link>
            </span>

            <div id="tableWrapper" className={styles["table-wrapper"]}>
              {isLoading ? (
                <Spinner />
              ) : (
                <table>
                  <thead className={styles.tableHeader}>
                    <tr>
                      <th
                        className={styles["sticky-left"]}
                        onClick={() => requestSort("attributes.dispatched")}
                      >
                        <div className={styles["sortable-header"]}>
                          {t("admin.field.state")}
                          {sortConfig.key === "attributes.dispatched" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              width="18"
                              viewBox="0 0 576 512"
                            >
                              {sortConfig.direction === "ascending" ? (
                                <path d="M151.6 42.4C145.5 35.8 137 32 128 32s-17.5 3.8-23.6 10.4l-88 96c-11.9 13-11.1 33.3 2 45.2s33.3 11.1 45.2-2L96 146.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V146.3l32.4 35.4c11.9 13 32.2 13.9 45.2 2s13.9-32.2 2-45.2l-88-96zM320 480h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
                              ) : (
                                <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 480c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320z" />
                              )}
                            </svg>
                          )}
                        </div>
                      </th>
                      <th onClick={() => requestSort("id")}>
                        <div className={styles["sortable-header"]}>
                          {t("admin.field.id")}
                          {sortConfig.key === "id" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              width="18"
                              viewBox="0 0 576 512"
                            >
                              {sortConfig.direction === "ascending" ? (
                                <path d="M151.6 42.4C145.5 35.8 137 32 128 32s-17.5 3.8-23.6 10.4l-88 96c-11.9 13-11.1 33.3 2 45.2s33.3 11.1 45.2-2L96 146.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V146.3l32.4 35.4c11.9 13 32.2 13.9 45.2 2s13.9-32.2 2-45.2l-88-96zM320 480h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
                              ) : (
                                <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 480c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320z" />
                              )}
                            </svg>
                          )}
                        </div>
                      </th>
                      <th onClick={() => requestSort("attributes.createdAt")}>
                        <div className={styles["sortable-header"]}>
                          {t("admin.field.date")}
                          {sortConfig.key === "attributes.createdAt" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              width="18"
                              viewBox="0 0 576 512"
                            >
                              {sortConfig.direction === "ascending" ? (
                                <path d="M151.6 42.4C145.5 35.8 137 32 128 32s-17.5 3.8-23.6 10.4l-88 96c-11.9 13-11.1 33.3 2 45.2s33.3 11.1 45.2-2L96 146.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V146.3l32.4 35.4c11.9 13 32.2 13.9 45.2 2s13.9-32.2 2-45.2l-88-96zM320 480h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
                              ) : (
                                <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 480c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320z" />
                              )}
                            </svg>
                          )}
                        </div>
                      </th>
                      <th onClick={() => requestSort("attributes.name")}>
                        <div className={styles["sortable-header"]}>
                          {t("admin.field.name")}
                          {sortConfig.key === "attributes.name" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              width="18"
                              viewBox="0 0 576 512"
                            >
                              {sortConfig.direction === "ascending" ? (
                                <path d="M151.6 42.4C145.5 35.8 137 32 128 32s-17.5 3.8-23.6 10.4l-88 96c-11.9 13-11.1 33.3 2 45.2s33.3 11.1 45.2-2L96 146.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V146.3l32.4 35.4c11.9 13 32.2 13.9 45.2 2s13.9-32.2 2-45.2l-88-96zM320 480h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
                              ) : (
                                <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 480c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320z" />
                              )}
                            </svg>
                          )}
                        </div>
                      </th>
                      <th onClick={() => requestSort("attributes.number")}>
                        <div className={styles["sortable-header"]}>
                          {t("admin.field.number")}
                          {sortConfig.key === "attributes.number" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              width="18"
                              viewBox="0 0 576 512"
                            >
                              {sortConfig.direction === "ascending" ? (
                                <path d="M151.6 42.4C145.5 35.8 137 32 128 32s-17.5 3.8-23.6 10.4l-88 96c-11.9 13-11.1 33.3 2 45.2s33.3 11.1 45.2-2L96 146.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V146.3l32.4 35.4c11.9 13 32.2 13.9 45.2 2s13.9-32.2 2-45.2l-88-96zM320 480h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
                              ) : (
                                <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 480c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320z" />
                              )}
                            </svg>
                          )}
                        </div>
                      </th>
                      <th onClick={() => requestSort("attributes.email")}>
                        <div className={styles["sortable-header"]}>
                          E-mail
                          {sortConfig.key === "attributes.email" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              width="18"
                              viewBox="0 0 576 512"
                            >
                              {sortConfig.direction === "ascending" ? (
                                <path d="M151.6 42.4C145.5 35.8 137 32 128 32s-17.5 3.8-23.6 10.4l-88 96c-11.9 13-11.1 33.3 2 45.2s33.3 11.1 45.2-2L96 146.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V146.3l32.4 35.4c11.9 13 32.2 13.9 45.2 2s13.9-32.2 2-45.2l-88-96zM320 480h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
                              ) : (
                                <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 480c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320z" />
                              )}
                            </svg>
                          )}
                        </div>
                      </th>
                      <th onClick={() => requestSort("attributes.citySelect")}>
                        <div className={styles["sortable-header"]}>
                          {t("admin.field.citySelect")}
                          {sortConfig.key === "attributes.citySelect" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              width="18"
                              viewBox="0 0 576 512"
                            >
                              {sortConfig.direction === "ascending" ? (
                                <path d="M151.6 42.4C145.5 35.8 137 32 128 32s-17.5 3.8-23.6 10.4l-88 96c-11.9 13-11.1 33.3 2 45.2s33.3 11.1 45.2-2L96 146.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V146.3l32.4 35.4c11.9 13 32.2 13.9 45.2 2s13.9-32.2 2-45.2l-88-96zM320 480h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
                              ) : (
                                <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 480c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320z" />
                              )}
                            </svg>
                          )}
                        </div>
                      </th>
                      <th onClick={() => requestSort("attributes.city")}>
                        <div className={styles["sortable-header"]}>
                          {t("admin.field.city")}
                          {sortConfig.key === "attributes.city" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              width="18"
                              viewBox="0 0 576 512"
                            >
                              {sortConfig.direction === "ascending" ? (
                                <path d="M151.6 42.4C145.5 35.8 137 32 128 32s-17.5 3.8-23.6 10.4l-88 96c-11.9 13-11.1 33.3 2 45.2s33.3 11.1 45.2-2L96 146.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V146.3l32.4 35.4c11.9 13 32.2 13.9 45.2 2s13.9-32.2 2-45.2l-88-96zM320 480h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
                              ) : (
                                <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 480c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320z" />
                              )}
                            </svg>
                          )}
                        </div>
                      </th>
                      <th
                        onClick={() =>
                          requestSort("attributes.streetHouseApartment")
                        }
                      >
                        <div className={styles["sortable-header"]}>
                          {t("admin.field.street")}
                          {sortConfig.key ===
                            "attributes.streetHouseApartment" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              width="18"
                              viewBox="0 0 576 512"
                            >
                              {sortConfig.direction === "ascending" ? (
                                <path d="M151.6 42.4C145.5 35.8 137 32 128 32s-17.5 3.8-23.6 10.4l-88 96c-11.9 13-11.1 33.3 2 45.2s33.3 11.1 45.2-2L96 146.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V146.3l32.4 35.4c11.9 13 32.2 13.9 45.2 2s13.9-32.2 2-45.2l-88-96zM320 480h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
                              ) : (
                                <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 480c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320z" />
                              )}
                            </svg>
                          )}
                        </div>
                      </th>
                      <th onClick={() => requestSort("attributes.comment")}>
                        <div className={styles["sortable-header"]}>
                          {t("admin.field.message")}
                          {sortConfig.key === "attributes.comment" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              width="18"
                              viewBox="0 0 576 512"
                            >
                              {sortConfig.direction === "ascending" ? (
                                <path d="M151.6 42.4C145.5 35.8 137 32 128 32s-17.5 3.8-23.6 10.4l-88 96c-11.9 13-11.1 33.3 2 45.2s33.3 11.1 45.2-2L96 146.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V146.3l32.4 35.4c11.9 13 32.2 13.9 45.2 2s13.9-32.2 2-45.2l-88-96zM320 480h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
                              ) : (
                                <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 480c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320z" />
                              )}
                            </svg>
                          )}
                        </div>
                      </th>
                      <th onClick={() => requestSort("attributes.postalIndex")}>
                        <div className={styles["sortable-header"]}>
                          {t("admin.field.postalIndex")}
                          {sortConfig.key === "attributes.postalIndex" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              width="18"
                              viewBox="0 0 576 512"
                            >
                              {sortConfig.direction === "ascending" ? (
                                <path d="M151.6 42.4C145.5 35.8 137 32 128 32s-17.5 3.8-23.6 10.4l-88 96c-11.9 13-11.1 33.3 2 45.2s33.3 11.1 45.2-2L96 146.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V146.3l32.4 35.4c11.9 13 32.2 13.9 45.2 2s13.9-32.2 2-45.2l-88-96zM320 480h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
                              ) : (
                                <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 480c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320z" />
                              )}
                            </svg>
                          )}{" "}
                        </div>
                      </th>
                      <th onClick={() => requestSort("attributes.delivery")}>
                        <div className={styles["sortable-header"]}>
                          {t("admin.field.delivery")}
                          {sortConfig.key === "attributes.delivery" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              width="18"
                              viewBox="0 0 576 512"
                            >
                              {sortConfig.direction === "ascending" ? (
                                <path d="M151.6 42.4C145.5 35.8 137 32 128 32s-17.5 3.8-23.6 10.4l-88 96c-11.9 13-11.1 33.3 2 45.2s33.3 11.1 45.2-2L96 146.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V146.3l32.4 35.4c11.9 13 32.2 13.9 45.2 2s13.9-32.2 2-45.2l-88-96zM320 480h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
                              ) : (
                                <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 480c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320z" />
                              )}
                            </svg>
                          )}{" "}
                        </div>
                      </th>
                      <th
                        onClick={() => requestSort("attributes.usedPromocode")}
                      >
                        {" "}
                        <div className={styles["sortable-header"]}>
                          {t("admin.field.usedPromocode")}
                          {sortConfig.key === "attributes.usedPromocode" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              width="18"
                              viewBox="0 0 576 512"
                            >
                              {sortConfig.direction === "ascending" ? (
                                <path d="M151.6 42.4C145.5 35.8 137 32 128 32s-17.5 3.8-23.6 10.4l-88 96c-11.9 13-11.1 33.3 2 45.2s33.3 11.1 45.2-2L96 146.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V146.3l32.4 35.4c11.9 13 32.2 13.9 45.2 2s13.9-32.2 2-45.2l-88-96zM320 480h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
                              ) : (
                                <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 480c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320z" />
                              )}
                            </svg>
                          )}{" "}
                        </div>
                      </th>
                      <th
                        onClick={() =>
                          requestSort("attributes.usedPromocodeDiscount")
                        }
                      >
                        {" "}
                        <div className={styles["sortable-header"]}>
                          {t("admin.field.usedPromocodeDiscount")}
                          {sortConfig.key ===
                            "attributes.usedPromocodeDiscount" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              width="18"
                              viewBox="0 0 576 512"
                            >
                              {sortConfig.direction === "ascending" ? (
                                <path d="M151.6 42.4C145.5 35.8 137 32 128 32s-17.5 3.8-23.6 10.4l-88 96c-11.9 13-11.1 33.3 2 45.2s33.3 11.1 45.2-2L96 146.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V146.3l32.4 35.4c11.9 13 32.2 13.9 45.2 2s13.9-32.2 2-45.2l-88-96zM320 480h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
                              ) : (
                                <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 480c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320z" />
                              )}
                            </svg>
                          )}{" "}
                        </div>
                      </th>
                      <th
                        onClick={() =>
                          requestSort("attributes.usedPromocodeDiscountPrice")
                        }
                      >
                        {" "}
                        <div className={styles["sortable-header"]}>
                          {t("admin.field.usedPromocodeDiscountPrice")}
                          {sortConfig.key ===
                            "attributes.usedPromocodeDiscountPrice" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              width="18"
                              viewBox="0 0 576 512"
                            >
                              {sortConfig.direction === "ascending" ? (
                                <path d="M151.6 42.4C145.5 35.8 137 32 128 32s-17.5 3.8-23.6 10.4l-88 96c-11.9 13-11.1 33.3 2 45.2s33.3 11.1 45.2-2L96 146.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V146.3l32.4 35.4c11.9 13 32.2 13.9 45.2 2s13.9-32.2 2-45.2l-88-96zM320 480h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
                              ) : (
                                <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 480c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320z" />
                              )}
                            </svg>
                          )}{" "}
                        </div>
                      </th>
                      <th
                        onClick={() =>
                          requestSort("attributes.products.data.length")
                        }
                      >
                        {" "}
                        <div className={styles["sortable-header"]}>
                          {t("admin.field.items")}
                          {sortConfig.key ===
                            "attributes.products.data.length" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              width="18"
                              viewBox="0 0 576 512"
                            >
                              {sortConfig.direction === "ascending" ? (
                                <path d="M151.6 42.4C145.5 35.8 137 32 128 32s-17.5 3.8-23.6 10.4l-88 96c-11.9 13-11.1 33.3 2 45.2s33.3 11.1 45.2-2L96 146.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V146.3l32.4 35.4c11.9 13 32.2 13.9 45.2 2s13.9-32.2 2-45.2l-88-96zM320 480h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
                              ) : (
                                <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 480c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320z" />
                              )}
                            </svg>
                          )}
                        </div>
                      </th>
                      <th onClick={() => requestSort("attributes.payment")}>
                        <div className={styles["sortable-header"]}>
                          {t("admin.field.payment")}
                          {sortConfig.key === "attributes.payment" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              width="18"
                              viewBox="0 0 576 512"
                            >
                              {sortConfig.direction === "ascending" ? (
                                <path d="M151.6 42.4C145.5 35.8 137 32 128 32s-17.5 3.8-23.6 10.4l-88 96c-11.9 13-11.1 33.3 2 45.2s33.3 11.1 45.2-2L96 146.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V146.3l32.4 35.4c11.9 13 32.2 13.9 45.2 2s13.9-32.2 2-45.2l-88-96zM320 480h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
                              ) : (
                                <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 480c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320z" />
                              )}
                            </svg>
                          )}
                        </div>
                      </th>
                      <th className={styles["sticky-right"]}></th>
                    </tr>
                  </thead>
                  <tbody className={styles.tbody} id="table-rows">
                    {mappedRecords}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <div>
          <PaginationRounded
            pageCount={pageCount}
            currentPage={currentPage}
            onPageChange={onPageChange}
          />
        </div>
      </div>
      {deleteDataConfirm && (
        <>
          <div
            onClick={handleDeleteOrderConfirmClose}
            className={styles.confirmOverlay}
          ></div>
          <div className={styles.confirmContent}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 512 512"
            >
              <path
                fill="#FFD43B"
                d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
              />
            </svg>

            <p className={styles.confirmTitle}>
              {t("adminOrderProduct.textDeleteOrderConfirm")}
            </p>
            <p className={styles.confirmDescriptions}>
              {t("adminOrderProduct.textDeleteOrderConfirmDescription")}
            </p>

            <div className={styles.confirmBtn}>
              <button onClick={handleOrderDelete} className={styles.saveData}>
                {t("adminOrderProduct.textYes")}
              </button>
              <button
                onClick={handleDeleteOrderConfirmClose}
                className={styles.deleteData}
              >
                {t("adminOrderProduct.textNo")}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminItems;
