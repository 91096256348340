import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "../scss/components/newOrder.module.scss";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useDebounce } from "use-debounce";
import { useNavigate } from "react-router-dom";

const NewOrderItem = ({ orderData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18next.language;
  const [productsData, setProductsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [storedUserData, setStoredUserData] = useState(
    JSON.parse(localStorage.getItem("user-data")) || {}
  );
  const token = storedUserData.token;

  useEffect(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/products?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=article&fields[1]=roTitle&fields[2]=title&fields[3]=price`
        )
        .then((res) => {
          const products = res.data.data;
          if (products.length > 0) {
            setProductsData((prevData) => [...prevData, ...products]);
            fetchData(page + 1); // Рекурсивный вызов с увеличением номера страницы
          } else {
            setIsLoading(false); // Завершаем загрузку, когда больше нет данных
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
          setIsLoading(false);
        });
    };

    fetchData();
  }, []);


  const [productsValue, setProductsValue] = useState([]);

  const [nameValue, setNameValue] = useState("");
  const [numberValue, setNumberValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [citySelectValue, setCitySelectValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [streetHouseApartmentValue, setStreetHouseApartmentValue] =
    useState("");
  const [postalIndexValue, setPostalIndexValue] = useState("");
  const [deliveryValue, setDeliveryValue] = useState("");
  const [usedPromocodeValue, setUsedPromocodeValue] = useState("");
  const [usedPromocodeDiscountValue, setUsedPromocodeDiscountValue] =
    useState("");
  const [usedPromocodeDiscountPriceValue, setUsedPromocodeDiscountPriceValue] =
    useState("");
  const [paymentValue, setPaymentValue] = useState("");
  const numericUsedPromocodeDiscountValue = parseFloat(
    usedPromocodeDiscountValue
  );
  const numericUsedPromocodeDiscountPriceValue = parseFloat(
    usedPromocodeDiscountPriceValue
  );
  const numericPaymentValue = parseFloat(paymentValue);
  const [commentValue, setCommentValue] = useState("");
  const [adminCommentsValue, setAdminCommentsValue] = useState("");
  const options = {
    true: t("admin.field.sent"),
    false: t("admin.field.waiting"),
  };
  const [dispatchedValue, setDispatchedValue] = useState(false);
  const handleDispatcValueChange = (event) => {
    const value = event.target.value === options["true"];
    setDispatchedValue(value); // Обновляем dispatchedValue
  };
  useEffect(() => {
    setNameValue(nameValue || "");
    setEmailValue(emailValue || "");
    setNumberValue(numberValue || "");
    setCitySelectValue(citySelectValue || "");
    setCityValue(cityValue || "");
    setStreetHouseApartmentValue(streetHouseApartmentValue || "");
    setPostalIndexValue(postalIndexValue || "");
    setDeliveryValue(deliveryValue || "");
    setUsedPromocodeValue(usedPromocodeValue || "");
    setUsedPromocodeDiscountValue(usedPromocodeDiscountValue || "");
    setUsedPromocodeDiscountPriceValue(usedPromocodeDiscountPriceValue || "");
    setPaymentValue(paymentValue || "");
    setCommentValue(commentValue || "");
    setAdminCommentsValue(adminCommentsValue || "");
  }, [
    nameValue,
    numberValue,
    emailValue,
    citySelectValue,
    cityValue,
    streetHouseApartmentValue,
    postalIndexValue,
    deliveryValue,
    usedPromocodeValue,
    usedPromocodeDiscountValue,
    usedPromocodeDiscountPriceValue,
    paymentValue,
    commentValue,
    adminCommentsValue,
  ]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "dispatched":
        setDispatchedValue(value);
        break;
      case "name":
        setNameValue(value);
        break;
      case "email":
        setEmailValue(value);
        break;
      case "number":
        setNumberValue(value);
        break;
      case "citySelect":
        setCitySelectValue(value);
        break;
      case "city":
        setCityValue(value);
        break;
      case "streetHouseApartment":
        setStreetHouseApartmentValue(value);
        break;
      case "postalIndex":
        setPostalIndexValue(value);
        break;
      case "delivery":
        setDeliveryValue(value);
        break;
      case "usedPromocode":
        setUsedPromocodeValue(value);
        break;
      case "usedPromocodeDiscount":
        setUsedPromocodeDiscountValue(value);
        break;
      case "usedPromocodeDiscountPrice":
        setUsedPromocodeDiscountPriceValue(value);
        break;
      case "payment":
        setPaymentValue(value);
        break;
      case "comment":
        setCommentValue(value);
        break;
      case "adminComments":
        setAdminCommentsValue(value);
        break;

      default:
        break;
    }
  };
  const [editItem, setEditItem] = useState(false);
  const [createOrderConfirm, setCreateOrderConfirm] = useState(false);
  const [cancelCreateConfirm, setCancelCreateConfirm] = useState(false);
  const [isProductNotFound, setIsProductNotFound] = useState(false);
  const [originalProduct, setOriginalProduct] = useState({});
  const [editedProduct, setEditedProduct] = useState(originalProduct || {});
  const [addItem, setAddItem] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [foundProduct, setFoundProduct] = useState(null);
  const [foundProductPriceDiscount, setFoundProductPriceDiscount] = useState(0);
  const foundProductDiscountValue = foundProductPriceDiscount.toString();
  const debouncedDiscountValue = useDebounce(foundProductDiscountValue, 1000);
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const [foundProductQuantity, setFoundProductQuantity] = useState("1");
  const debouncedFoundProductQuantity = useDebounce(foundProductQuantity, 1000);
  const [foundProductTotalPrice, setFoundProductTotalPrice] = useState(0);
  const [editingProductIndex, setEditingProductIndex] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isAddProductNotFound, setIsAddProductNotFound] = useState(false);

  useEffect(() => {
    const searchProduct = async () => {
      // Check if the user has entered a search term
      if (debouncedSearchTerm[0] && Array.isArray(productsData)) {
        const found = productsData.find(
          (product) =>
            String(product?.attributes?.article).toLowerCase() ===
            String(debouncedSearchTerm[0]).toLowerCase()
        );

        setFoundProduct(found);
        setIsAddProductNotFound(!found);
      }
    };
    setFoundProduct(null);
    searchProduct();
  }, [debouncedSearchTerm, productsData]);
  const handleAddInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };
  useEffect(() => {}, [debouncedDiscountValue]);
  const handleDiscountChange = (event) => {
    const value = parseFloat(event.target.value);
    setFoundProductPriceDiscount(isNaN(value) ? 0 : value);
  };
  const foundProductDiscountedPrice = foundProduct
    ? isNaN(parseFloat(foundProduct.attributes.price))
      ? 0
      : parseFloat(foundProduct.attributes.price) -
        (parseFloat(foundProduct.attributes.price) *
          foundProductPriceDiscount) /
          100
    : 0;
  const handleFoundProductQuantityChange = (event) => {
    const value = event.target.valueAsNumber || event.target.value;
    const newValue = value === undefined || value === "" ? 1 : value;
    setFoundProductQuantity(newValue);
  };
  useEffect(() => {}, [debouncedFoundProductQuantity]);
  const calculateTotalPrice = () => {
    if (foundProduct) {
      const price = parseFloat(foundProduct.attributes.price) || 0;
      const discountedPrice =
        price - (price * foundProductPriceDiscount) / 100 || 0;
      const quantity = foundProductQuantity || 1;

      let total = discountedPrice * quantity;
      total = parseFloat(total.toFixed(2));

      setFoundProductTotalPrice(total);
    } else {
      setFoundProductTotalPrice(0);
    }
  };
  useEffect(() => {
    calculateTotalPrice();
  }, [foundProduct, foundProductPriceDiscount, foundProductQuantity]);
  const handleAddItemOpen = () => {
    setAddItem(true);
  };
  const handleCreateOrderConfirmOpen = () => {
    setCreateOrderConfirm(true);
  };
  const handleCreateOrderConfirmClose = () => {
    setCreateOrderConfirm(false);
  };
  const handleCancelCreateConfirmOpen = () => {
    setCancelCreateConfirm(true);
  };
  const handleCancelCreateConfirmClose = () => {
    setCancelCreateConfirm(false);
  };
  const handleAddItemClose = () => {
    setAddItem(false);
    setFoundProduct("");
    setFoundProductQuantity("1");
    setFoundProductPriceDiscount(0);
    setAddItem(false);
    setSearchTerm("");
  };
  const handleAddProduct = (
    foundProduct,
    foundProductDiscountValue,
    foundProductDiscountedPrice,
    foundProductQuantity,
    foundProductTotalPrice
  ) => {
    const newProduct = {
      article: foundProduct?.attributes?.article,
      ruName: foundProduct?.attributes?.title,
      roName: foundProduct?.attributes?.roTitle,
      price: foundProduct?.attributes?.price,
      discount: foundProductDiscountValue,
      discountPrice: foundProductDiscountedPrice,
      quantity: foundProductQuantity,
      pricePosition: foundProductTotalPrice,
    };

    setProductsValue((prevProducts) => [...prevProducts, newProduct]);
    setFoundProduct(null);
    setSearchTerm("");
    setFoundProductQuantity("1");
    setFoundProductPriceDiscount(0);
    setAddItem(false);
  };
  const handleRemoveProduct = (productIndex) => {
    const updatedProducts = productsValue.filter(
      (item, index) => index !== productIndex
    );
    setProductsValue(updatedProducts);
  };
  const searchProductByArticle = (article) => {
    const foundProduct = productsData.find(
      (product) =>
        product.attributes.article.toLowerCase() === article.toLowerCase()
    );

    if (foundProduct) {
      setIsProductNotFound(false);
      setEditedProduct((prevProduct) => {
        const updatedProduct = {
          ...prevProduct,
          ...foundProduct.attributes,
          article: foundProduct.attributes.article,
          ruName: foundProduct.attributes.title,
          roName: foundProduct.attributes.roTitle,
        };

        // Пересчет discountPrice и pricePosition
        const price = parseFloat(updatedProduct.price) || 0;
        const discount = parseFloat(updatedProduct.discount) || 0;
        const quantity = parseFloat(updatedProduct.quantity) || 1;

        const discountPrice = price - (price * discount) / 100;
        const pricePosition = discountPrice * quantity;

        return {
          ...updatedProduct,
          discountPrice: discountPrice.toFixed(2),
          pricePosition: pricePosition.toFixed(2),
        };
      });
    } else {
      setIsProductNotFound(true);
    }
  };
  const handleEditItemOpen = (index) => {
    setEditingProductIndex(index);
    const productToEdit = productsValue[index];

    if (productToEdit) {
      setEditedProduct(productToEdit);
      setOriginalProduct(productToEdit);
      setEditItem(true);
    }
  };
  const cancelEditing = () => {
    setEditedProduct(originalProduct);
    setEditItem(false);
  };
  const handleEditItemClose = () => {
    setEditItem(false);
    setOriginalProduct({});
    setFoundProduct("");
    setFoundProductQuantity("1");
    setFoundProductPriceDiscount(0);
    setSearchTerm("");
  };
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;

    // Проверяем, является ли поле числовым (за исключением поля 'article')
    const isNumericField = ["price", "discount", "quantity"].includes(name);
    const updatedValue = isNumericField ? parseFloat(value) || 0 : value;

    setEditedProduct((prevProduct) => {
      // Обновляем текущее значение
      const updatedProduct = { ...prevProduct, [name]: updatedValue };

      // Если поле является артикулом, выполняем поиск продукта
      if (name === "article") {
        searchProductByArticle(value);
      }

      // Пересчитываем цены, если изменились числовые поля
      if (isNumericField) {
        const price = parseFloat(updatedProduct.price) || 0;
        const discount = parseFloat(updatedProduct.discount) || 0;
        const quantity = parseFloat(updatedProduct.quantity) || 1;

        const discountPrice = price - (price * discount) / 100;
        const pricePosition = discountPrice * quantity;

        return {
          ...updatedProduct,
          discountPrice: discountPrice.toFixed(2),
          pricePosition: pricePosition.toFixed(2),
        };
      }

      return updatedProduct;
    });
  };
  const handleSaveEditProduct = () => {
    if (editingProductIndex === null) {
      return;
    }

    // Создаем новый объект продукта, исключая ненужные поля
    const { title, roTitle, ...updatedProductData } = editedProduct;

    // Обновляем элемент в массиве
    const updatedProducts = productsValue.map((item, index) =>
      index === editingProductIndex ? updatedProductData : item
    );

    setProductsValue(updatedProducts); // Обновляем состояние productsValue
    handleEditItemClose(); // Закрываем форму редактирования и сбрасываем индекс
    setEditingProductIndex(null); // Сбрасываем индекс редактируемого продукта
  };
  const handleCancelData = () => {
    setOriginalProduct({});
    setProductsValue([]);
    setDispatchedValue(false);
    setNameValue("");
    setEmailValue("");
    setNumberValue("");
    setCitySelectValue("");
    setCityValue("");
    setStreetHouseApartmentValue("");
    setPostalIndexValue("");
    setDeliveryValue("");
    setUsedPromocodeValue("");
    setUsedPromocodeDiscountValue("");
    setUsedPromocodeDiscountPriceValue("");
    setPaymentValue("");
    setCommentValue("");
    setAdminCommentsValue("");
    navigate(`/admin`);
  };
  useEffect(() => {
    // Проверка на заполненность всех необходимых полей
    const checkFormValidity = () => {
      return (
        nameValue.trim() !== "" &&
        numberValue.trim() !== "" &&
        citySelectValue.trim() !== "" &&
        streetHouseApartmentValue.trim() !== "" &&
        deliveryValue.trim() !== "" &&
        paymentValue.trim() !== ""
      );
    };

    setIsFormValid(checkFormValidity());
  }, [
    nameValue,
    numberValue,
    citySelectValue,
    streetHouseApartmentValue,
    deliveryValue,
    paymentValue,
  ]);
  const handleAddOrder = (e) => {
    e.preventDefault();

    const postDataInfo = {
      data: {
        email: emailValue,
        name: nameValue,
        number: numberValue,
        citySelect: citySelectValue,
        city: cityValue,
        streetHouseApartment: streetHouseApartmentValue,
        postalIndex: deliveryValue,
        comment: commentValue,
        delivery: deliveryValue,
        usedPromocode: usedPromocodeValue,
        usedPromocodeDiscount: numericUsedPromocodeDiscountValue,
        usedPromocodeDiscountPrice: numericUsedPromocodeDiscountPriceValue,
        payment: numericPaymentValue,
        dispatched: dispatchedValue,
        adminComments: adminCommentsValue,
      },
    };
    axios
      .post("https://api.prodcomfit.md/api/orders", postDataInfo, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((orderResponse) => {
        const orderId = orderResponse.data.data.id;
        const postProductRequests = productsValue.map((product, index) => {
          const productPostData = {
            data: {
              article: product.article,
              discount: product.discount,
              discountPrice: product.discountPrice,
              price: product.price,
              quantity: product.quantity,
              roName: product.roName,
              ruName: product.ruName,
              pricePosition: product.total,
              order: orderId,
            },
          };
          return axios.post(
            "https://api.prodcomfit.md/api/order-products",
            productPostData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        });
        return Promise.all(postProductRequests).then((responses) => {
          handleCancelData();
          navigate(orderId ? `/order/${orderId}` : "/admin");

          return orderId;
        });
      })
      .catch((error) => {
        console.error("Error during the order processing:", error);
      });
  };
  useEffect(() => {
    // После изменения productsValue пересчитываем сумму
    const totalPayment = productsValue.reduce(
      (accumulator, product) => accumulator + parseFloat(product.pricePosition),
      0
    );

    // Проверка наличия скидки
    if (
      usedPromocodeDiscountValue &&
      usedPromocodeDiscountValue !== null &&
      usedPromocodeDiscountValue !== undefined &&
      usedPromocodeDiscountValue !== 0
    ) {
      const discountAmount = (totalPayment * usedPromocodeDiscountValue) / 100;
      setUsedPromocodeDiscountPriceValue(discountAmount.toFixed(2));
      setPaymentValue((totalPayment - discountAmount).toFixed(2));
    } else {
      setUsedPromocodeDiscountPriceValue(""); // Если скидка не применяется, сбрасываем значение
      setPaymentValue(totalPayment.toFixed(2));
    }
  }, [productsValue, usedPromocodeDiscountValue]);
  const mappedRecords = productsValue.map((item, index) => (
    <tr key={index}>
      <td className={`${styles.stock} ${styles["sticky-left"]}`}>
        {item.article}
      </td>
      <td>{item.ruName}</td>
      <td>{item.price}</td>
      <td>{item.discount}</td>
      <td>{item.discountPrice}</td>
      <td>{item.quantity}</td>
      <td>{item.pricePosition}</td>
      <td className={styles["sticky-right"]}>
        <button onClick={() => handleRemoveProduct(index)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="14"
            viewBox="0 0 448 512"
          >
            <path
              fill="red"
              d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
            />
          </svg>
        </button>
        <button
          onClick={() => {
            handleEditItemOpen(index);
          }}
          className={`${styles.btn} ${styles["btn--primary"]}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="16"
            viewBox="0 0 512 512"
          >
            <path
              fill="rgb(73, 69, 255)"
              d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
            />
          </svg>
        </button>
      </td>
    </tr>
  ));
  return (
    <div className={styles.orderItems}>
      <div className={styles.orderItems__container}>
        <div className={styles.orderItems__wrapper}>
          <div className={styles.header}>
            <div className={styles.tools}>
              <div className={styles.stateChange}>
                <select
                  value={dispatchedValue ? options["true"] : options["false"]}
                  onChange={handleDispatcValueChange}
                  className="your-select-style"
                >
                  {Object.entries(options).map(([key, displayValue]) => (
                    <option key={key} value={options[key]}>
                      {displayValue}
                    </option>
                  ))}
                </select>
              </div>

              <div className={styles.tools__btn}>
                <button
                  onClick={handleCreateOrderConfirmOpen}
                  disabled={!isFormValid}
                  className={`${styles.saveData} ${
                    !isFormValid ? styles.disabled : ""
                  }`}
                >
                  {t("adminOrderProduct.textSave")}
                </button>
                <button
                  onClick={handleCancelCreateConfirmOpen}
                  className={styles.deleteData}
                >
                  {t("adminOrderProduct.textCancel")}
                </button>
              </div>
            </div>
          </div>
          <div className={styles.information}>
            <div className={styles.information__header}>
              <div>
                <p>{t("admin.field.name")}*</p>
                <input
                  type="text"
                  name="name"
                  value={nameValue}
                  onChange={handleChange}
                />
              </div>
              <div>
                <p>{t("admin.field.number")}*</p>
                <input
                  type="text"
                  name="number"
                  onChange={handleChange}
                  value={numberValue}
                />
              </div>
              <div>
                <p>E-mail</p>
                <input
                  type="text"
                  name="email"
                  value={emailValue}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={styles.information__main}>
              <div className={styles.information__mainInf}>
                <div className={styles.information__mainInf__items}>
                  <div>
                    <p>{t("admin.field.citySelect")}*</p>
                    <input
                      type="text"
                      name="citySelect"
                      onChange={handleChange}
                      value={citySelectValue}
                    />
                  </div>
                  <div>
                    <p>{t("admin.field.city")}</p>
                    <input
                      type="text"
                      name="city"
                      onChange={handleChange}
                      value={cityValue}
                    />
                  </div>
                  <div>
                    <p>{t("admin.field.street")}*</p>
                    <input
                      type="text"
                      name="streetHouseApartment"
                      onChange={handleChange}
                      value={streetHouseApartmentValue}
                    />
                  </div>

                  <div>
                    <p>{t("admin.field.postalIndex")}</p>
                    <input
                      type="text"
                      name="postalIndex"
                      onChange={handleChange}
                      value={postalIndexValue}
                    />
                  </div>
                  <div>
                    <p>{t("admin.field.delivery")}*</p>
                    <input
                      type="text"
                      name="delivery"
                      onChange={handleChange}
                      value={deliveryValue}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.information__footer}>
              <div>
                <p>{t("admin.field.usedPromocode")}</p>
                <input
                  type="text"
                  name="usedPromocode"
                  onChange={handleChange}
                  value={usedPromocodeValue}
                />
              </div>
              <div>
                <p>{t("admin.field.usedPromocodeDiscount")}</p>
                <input
                  type="text"
                  name="usedPromocodeDiscount"
                  onChange={handleChange}
                  value={usedPromocodeDiscountValue}
                />
              </div>
              <div>
                <p>{t("admin.field.usedPromocodeDiscountPrice")}</p>
                <input
                  type="text"
                  name="usedPromocodeDiscountPrice"
                  onChange={handleChange}
                  readOnly
                  value={usedPromocodeDiscountPriceValue}
                />
              </div>
              <div>
                <p>{t("admin.field.payment")}*</p>
                <input
                  type="text"
                  name="payment"
                  onChange={handleChange}
                  readOnly
                  value={paymentValue}
                />
              </div>
            </div>
          </div>
          <div className={styles["table-widget"]}>
            <div className={styles["table-wrapper"]}>
              <table>
                <thead className={styles.tableHeader}>
                  <tr>
                    <th className={styles["sticky-left"]}>
                      {t("adminOrderProduct.article")}
                    </th>
                    <th>{t("adminOrderProduct.name")}</th>

                    <th>{t("adminOrderProduct.price")}</th>
                    <th>{t("adminOrderProduct.discount")}</th>
                    <th>{t("adminOrderProduct.discountPrice")}</th>
                    <th>{t("adminOrderProduct.quantity")}</th>
                    <th>{t("adminOrderProduct.pricePosition")}</th>
                    <th className={styles["sticky-right"]}></th>
                  </tr>
                </thead>
                <tbody id="table-rows">{mappedRecords}</tbody>
              </table>
              <button onClick={handleAddItemOpen} className={styles.addProduct}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="16"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="rgb(73, 69, 255)"
                    d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className={styles.note}>
            <div>
              <p className={styles.noteTitle}>{t("admin.field.message")}</p>
              <textarea
                name="comment"
                cols="30"
                rows="10"
                value={commentValue}
                onChange={handleChange}
              ></textarea>
            </div>

            <div>
              <p className={styles.noteTitle}>{t("adminOrderProduct.note")}</p>
              <textarea
                value={adminCommentsValue}
                onChange={handleChange}
                placeholder={t("adminOrderProduct.notePlaceholder")}
                name="adminComments"
                cols="30"
                rows="10"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      {addItem && (
        <div>
          <div className={styles.modalOverlay} onClick={handleAddItemClose} />

          <div className={styles.modalContent}>
            <svg
              onClick={handleAddItemClose}
              className={styles.modalClose}
              xmlns="http://www.w3.org/2000/svg"
              height="16"
              width="16"
              viewBox="0 0 384 512"
            >
              <path
                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                fill="black"
              />
            </svg>

            <div className={styles.modalContent__items}>
              <div>
                <p>{t("adminOrderProduct.article")}</p>
                <input onChange={handleAddInputChange} type="text" />
                {isAddProductNotFound && (
                  <p className={styles.texNotFound}>
                    {t("adminOrderProduct.texNotFound")}
                  </p>
                )}
              </div>
              {foundProduct && (
                <>
                  <div>
                    <p>{t("adminOrderProduct.nameRu")}</p>
                    <input
                      readOnly
                      type="text"
                      value={foundProduct.attributes.title}
                    />
                  </div>
                  <div>
                    <p>{t("adminOrderProduct.nameRo")}</p>
                    <input
                      readOnly
                      type="text"
                      value={foundProduct.attributes.roTitle}
                    />
                  </div>
                  <div>
                    <p>{t("adminOrderProduct.price")}</p>
                    <input
                      readOnly
                      type="text"
                      value={foundProduct.attributes.price}
                    />
                  </div>
                </>
              )}
              <div>
                <p>{t("adminOrderProduct.discount")}</p>
                <input
                  value={foundProductPriceDiscount}
                  onChange={handleDiscountChange}
                  type="number"
                />
              </div>
              <div>
                <p>{t("adminOrderProduct.discountPrice")}</p>
                <input
                  readOnly
                  value={
                    isNaN(foundProductDiscountedPrice)
                      ? 0
                      : foundProductDiscountedPrice.toFixed(2)
                  }
                  type="number"
                />
              </div>
              <div>
                <p>{t("adminOrderProduct.quantity")}</p>
                <input
                  value={foundProductQuantity}
                  onChange={handleFoundProductQuantityChange}
                  type="number"
                />
              </div>
              <div>
                <p>{t("adminOrderProduct.pricePosition")}</p>
                <input readOnly value={foundProductTotalPrice} type="text" />
              </div>
            </div>
            <div className={styles.modalTools}>
              <button
                onClick={() =>
                  handleAddProduct(
                    foundProduct,
                    foundProductDiscountValue,
                    foundProductDiscountedPrice,
                    foundProductQuantity,
                    foundProductTotalPrice
                  )
                }
                className={`${styles.saveData} ${
                  !foundProduct?.attributes?.article ? styles.disabled : ""
                }`}
                disabled={!foundProduct?.attributes?.article}
              >
                {t("adminOrderProduct.textSave")}
              </button>
            </div>
          </div>
        </div>
      )}
      {editItem && (
        <div>
          <div className={styles.modalOverlay} onClick={handleEditItemClose} />

          <div className={styles.modalContent}>
            <svg
              onClick={handleEditItemClose}
              className={styles.modalClose}
              xmlns="http://www.w3.org/2000/svg"
              height="16"
              width="16"
              viewBox="0 0 384 512"
            >
              <path
                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                fill="black"
              />
            </svg>

            <div className={styles.modalContent__items}>
              <div>
                <p>{t("adminOrderProduct.article")}</p>
                <input
                  name="article"
                  type="text"
                  value={editedProduct.article || ""}
                  onChange={handleEditInputChange}
                />
                {isProductNotFound && (
                  <p className={styles.texNotFound}>
                    {t("adminOrderProduct.texNotFound")}
                  </p>
                )}
              </div>
              <div>
                <p>{t("adminOrderProduct.nameRu")}</p>
                <input
                  name="ruName"
                  type="text"
                  readOnly
                  value={editedProduct.ruName || ""}
                  onChange={handleEditInputChange}
                />
              </div>
              <div>
                <p>{t("adminOrderProduct.nameRo")}</p>
                <input
                  name="roName"
                  type="text"
                  readOnly
                  value={editedProduct.roName || ""}
                  onChange={handleEditInputChange}
                />
              </div>
              <div>
                <p>{t("adminOrderProduct.price")}</p>
                <input
                  name="price"
                  type="number"
                  readOnly
                  value={editedProduct.price || ""}
                  onChange={handleEditInputChange}
                />
              </div>
              <div>
                <p>{t("adminOrderProduct.discount")}</p>
                <input
                  name="discount"
                  type="number"
                  value={editedProduct.discount || "0"}
                  onChange={handleEditInputChange}
                />
              </div>
              <div>
                <p>{t("adminOrderProduct.discountPrice")}</p>
                <input
                  name="discountPrice"
                  type="number"
                  readOnly
                  value={editedProduct.discountPrice}
                  onChange={handleEditInputChange}
                />
              </div>
              <div>
                <p>{t("adminOrderProduct.quantity")}</p>
                <input
                  name="quantity"
                  type="number"
                  value={editedProduct.quantity || "1"}
                  onChange={handleEditInputChange}
                />
              </div>
              <div>
                <p>{t("adminOrderProduct.pricePosition")}</p>
                <input
                  name="pricePosition"
                  type="number"
                  readOnly
                  value={editedProduct.pricePosition || ""}
                  onChange={handleEditInputChange}
                />
              </div>
            </div>
            <div className={styles.modalTools}>
              <button onClick={cancelEditing} className={styles.deleteData}>
                {t("adminOrderProduct.textCancel")}
              </button>

              <button
                className={`${styles.saveData} ${
                  isProductNotFound ? styles.disabled : ""
                }`}
                /* disabled={isProductNotFound} */
                onClick={handleSaveEditProduct}
              >
                {t("adminOrderProduct.textSave")}
              </button>
            </div>
          </div>
        </div>
      )}
      {createOrderConfirm && (
        <>
          <div
            onClick={handleCreateOrderConfirmClose}
            className={styles.confirmOverlay}
          ></div>
          <div className={styles.confirmContent}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 512 512"
            >
              <path
                fill="#FFD43B"
                d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
              />
            </svg>

            <p className={styles.confirmTitle}>
              {t("adminOrderProduct.textCreateOrderConfirm")}
            </p>
            <p className={styles.confirmDescriptions}>
              {t("adminOrderProduct.textCreateOrderConfirmDescription")}
            </p>

            <div className={styles.confirmBtn}>
              <button
                onClick={(e) => handleAddOrder(e)}
                className={styles.saveData}
                disabled={!isFormValid}
              >
                {t("adminOrderProduct.textYes")}
              </button>
              <button
                onClick={handleCreateOrderConfirmClose}
                className={styles.deleteData}
              >
                {t("adminOrderProduct.textNo")}
              </button>
            </div>
          </div>
        </>
      )}
      {cancelCreateConfirm && (
        <>
          <div
            onClick={handleCancelCreateConfirmClose}
            className={styles.confirmOverlay}
          ></div>
          <div className={styles.confirmContent}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 512 512"
            >
              <path
                fill="#FFD43B"
                d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
              />
            </svg>

            <p className={styles.confirmTitle}>
              {t("adminOrderProduct.textCancelCreateOrderConfirm")}
            </p>
            <p className={styles.confirmDescriptions}>
              {t("adminOrderProduct.textCancelCreateOrderConfirmDescription")}
            </p>

            <div className={styles.confirmBtn}>
              <button onClick={handleCancelData} className={styles.saveData}>
                {t("adminOrderProduct.textYes")}
              </button>
              <button
                onClick={handleCancelCreateConfirmClose}
                className={styles.deleteData}
              >
                {t("adminOrderProduct.textNo")}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NewOrderItem;
