import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import styles from "../scss/components/catalogChapterDetails.module.scss";
import Spinner from "./Spinner";
import CatalogResultNotFound from "./CatalogResultNotFound";

const CatalogChapterDetails = () => {
  const { t } = useTranslation();
  const currentLanguage = i18next.language;

  const [chapterData, setChapterData] = useState([]);
  const [loading, setLoading] = useState(true);

  // useCallback для запроса данных
  const fetchData = useCallback((page = 1) => {
    axios
      .get(
        `https://api.prodcomfit.md/api/product-shapters?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=name&fields[1]=roName&populate[image][fields][0]=name&populate[image][fields][1]=url`
      )
      .then((res) => {
        const products = res.data.data;
        if (products.length > 0) {
          setChapterData((prevData) => [...prevData, ...products]);
          fetchData(page + 1); // Рекурсивный вызов с увеличением номера страницы
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Ошибка при запросе данных:", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // useMemo для локализации имен глав
  const localizedChapterData = useMemo(() => {
    return chapterData.map((chapter) => ({
      ...chapter,
      localizedTitle:
        currentLanguage === "ru"
          ? chapter.attributes.name
          : currentLanguage === "ro"
          ? chapter.attributes.roName
          : "",
    }));
  }, [chapterData, currentLanguage]);

  if (loading) {
    return <Spinner />;
  }

  if (chapterData.length === 0) {
    return <CatalogResultNotFound />;
  }

  return (
    <div className={styles.catalogChapterDetails}>
      <div className="container">
        <div className={styles.catalogChapterDetails__wrapper}>
          <p className={styles.title}>{t("catalog.title")}</p>
          <div className={styles.items}>
            {localizedChapterData.map((chapter) => (
              <Link key={chapter.id} to={`/catalog/chapter/${chapter.id}`}>
                <div className={styles.item}>
                  <div className={styles.img}>
                    <img
                      src={`https://api.prodcomfit.md${chapter.attributes?.image?.data?.attributes?.url}`}
                      alt={chapter.attributes.name}
                    />
                  </div>
                  <p>{chapter.localizedTitle}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CatalogChapterDetails);
