import React, { useEffect, useState } from "react";
import styles from "../../scss/components/pages/catalog.module.scss";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

import HeaderOtherPages from "../HeaderOtherPages";
import CatalogItems from "../CatalogItems";
import Footer from "../Footer";
import CatalogBreadcrumb from "../breadcrumbs/CatalogBreadcrumb";
import Spinner from "../Spinner";
import NotFound from "../NotFoundError";
import PhoneWidget from "../mui/PhoneWidget";
import ScrollToTopWidget from "../mui/ScrollToTopWidget";

const Catalog = () => {
  const [categoryData, setCategoryData] = useState(null);
  const { categoryId } = useParams();
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chapterData, setChapterData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.prodcomfit.md/api/product-categories/${categoryId}?pagination[page]=1&pagination[pageSize]=1000&populate=*`
        );
        setCategoryData(response.data);
      } catch (error) {
        console.error("Error fetching product data:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    const fetchChapterData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/products-sections?pagination[page]=${page}&pagination[pageSize]=100&populate=*`
        )
        .then((res) => {
          const products = res.data.data;
          if (products.length > 0) {
            setChapterData((prevData) => [...prevData, ...products]);
            fetchData(page + 1); // Рекурсивный вызов с увеличением номера страницы
          } else {
            setIsLoading(false); // Завершаем загрузку, когда больше нет данных
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
          setIsLoading(false);
          setLoading(false);
          setError(error);
        });
    };
    fetchData();
    fetchChapterData();
  }, [categoryId]);
  const getTitle = () => {
    if (!categoryData || !categoryData.data) return "";
    const attributes = categoryData.data.attributes;
    return i18n.language === "ro" ? attributes?.roName : attributes?.name;
  };
  const sectionTitle = () => {
    if (
      !categoryData ||
      !categoryData.data ||
      !categoryData.data.attributes.product_section
    )
      return "";
    const attributes =
      categoryData.data.attributes.product_section.data.attributes;
    return i18n.language === "ro" ? attributes?.roName : attributes?.name;
  };
  const sectionId = categoryData?.data?.attributes.product_section.data?.id;
  const title = getTitle();
  const sectionName = sectionTitle();
  const [chapterId, setChapterId] = useState(null);
  const [chapterTitle, setChapterTitle] = useState("");

  useEffect(() => {
    if (chapterData && categoryData) {
      const sectionId = categoryData.data?.attributes.product_section.data?.id;
      chapterData.forEach((category) => {
        if (category.id.toString() === sectionId.toString()) {
          const chapters = category.attributes.product_shapters.data;
          if (chapters && chapters.length > 0) {
            // Берем первую главу из категории
            const chapter = chapters[0];
            setChapterId(chapter.id);
            setChapterTitle(
              i18n.language === "ro"
                ? chapter.attributes.roName
                : chapter.attributes.name
            );
          }
        }
      });
    }
  }, [chapterData, categoryData, i18n.language]);
  const [widgetActive, setWidgetActive] = useState(true);
  return (
    <div className={styles.catalog}>
      <HeaderOtherPages setWidgetActive={setWidgetActive} />
      <div className={styles.contant}>
        {loading ? (
          <Spinner />
        ) : categoryData ? (
          <>
            <CatalogBreadcrumb
              chapterId={chapterId}
              chapterTitle={chapterTitle}
              sectionId={sectionId}
              sectionName={sectionName}
              categoryName={title}
            />
            <CatalogItems
              setWidgetActive={setWidgetActive}
              categoryDataName={title}
              categoryData={categoryId}
            />
          </>
        ) : (
          <NotFound />
        )}
        {widgetActive ? (
          <div className={styles.widget}>
            <ScrollToTopWidget />
            <PhoneWidget />
          </div>
        ) : (
          ""
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Catalog;
