import React, { useState, useEffect } from "react";
import styles from "../../scss/components/pages/aboutUs.module.scss";
import HeaderOtherPages from "../HeaderOtherPages";
import AboutUsDescriptions from "../AboutUsDescriptions";
import BannerAdvantages from "../BannerAdvantages";
import ContactUs from "../ContactUs";
import Footer from "../Footer";
import AboutUsBreadcrumb from "../breadcrumbs/AboutUsBreadcrumb";
import PhoneWidget from "../mui/PhoneWidget";
import ScrollToTopWidget from "../mui/ScrollToTopWidget";

const AboutUs = () => {
  const [widgetActive, setWidgetActive] = useState(true);
  return (
    <div className={styles.aboutUs}>
       <HeaderOtherPages setWidgetActive={setWidgetActive} />
      <div className={styles.contant}>
        <AboutUsBreadcrumb />
        <AboutUsDescriptions />
        <BannerAdvantages style={{ background: "#F4F5F9" }} />
        <ContactUs />
      
        {widgetActive ? (
          <div className={styles.widget}>
            <ScrollToTopWidget />
            <PhoneWidget />
          </div>
        ) : (
          ""
        )}
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
