import React from "react";
import styles from "../scss/components/favoritesEmpty.module.scss";
import image from "../assets/favoritesEmpty.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FavoritesEmpty = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.favoritesEmpty}>
      <div className="container">
        <div className={styles.image}>
          <img src={image} alt="image" />
        </div>
        <div className={styles.contant}>
          <p className={styles.title}>{t("favoritesEmpty.title")}</p>
          <div className={styles.description}>
            <p>{t("favoritesEmpty.description.firstParagraph")}</p>
            <p>{t("favoritesEmpty.description.secondParagraph")}</p>
            <p>{t("favoritesEmpty.description.thirdParagraph")}</p>
          </div>
          <Link to="/catalog/chapter">
            <div className={styles.btn}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
              >
                <path
                  d="M6.20703 10.187L1.79376 5.77381L6.20703 1.36055"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p>{t("favoritesEmpty.btn")}</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FavoritesEmpty;
