import React from "react";
import styles from "../scss/components/notFoundError.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import error from "../assets/404.png"


const NotFoundError = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.notFoundError}>
      <div className="container">
        <div className={styles.notFoundError__wrapper}>
          <div className={styles.img}>
            <img src={error} alt="image" />
          </div>
          <div className={styles.descriptions}>
            <p className={styles.title}>{t("notFoundError.title")}</p>
            <p className={styles.subtitle}>{t("notFoundError.description")}</p>
          </div>
          <Link to="/">
            <div className={styles.btn}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="11"
                viewBox="0 0 8 11"
                fill="none"
              >
                <path
                  d="M6.18164 9.9292L1.76837 5.516L6.18164 1.10273"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p>{t("notFoundError.btn")}</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundError;
