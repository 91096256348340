import { createSlice } from "@reduxjs/toolkit";

const loadBasketFromLocalStorage = () => {
  const serializedData = localStorage.getItem("basket");
  return JSON.parse(serializedData) || [];
};

if (!localStorage.getItem("basket")) {
  localStorage.setItem("basket", JSON.stringify([]));
}

const basketSlice = createSlice({
  name: "basket",
  initialState: {
    basket: loadBasketFromLocalStorage(),
  },
  reducers: {
    addToBasket: (state, action) => {
      const existingProductIndex = state.basket.findIndex(
        (item) => item.id === action.payload.id
      );

      if (action.payload.quantity !== null && action.payload.quantity > 0) {
        if (existingProductIndex !== -1) {
          // Удаляем продукт, чтобы затем добавить его с новыми данными
          state.basket = state.basket.filter(
            (item) => item.id !== action.payload.id
          );
        }
        state.basket.push(action.payload);

        const serializedData = JSON.stringify(state.basket);
        localStorage.setItem("basket", serializedData);
      }
    },
    removeFromBasket: (state, action) => {
      state.basket = state.basket.filter(
        (item) => item.id !== action.payload.id
      );

      const serializedData = JSON.stringify(state.basket);
      localStorage.setItem("basket", serializedData);
    },
    updateBasketItemQuantity: (state, action) => {
      const existingProductIndex = state.basket.findIndex(
        (item) => item.id === action.payload.id
      );

      if (existingProductIndex !== -1) {
        // Удаляем продукт, чтобы затем добавить его с новыми данными
        state.basket = state.basket.filter(
          (item) => item.id !== action.payload.id
        );

        if (action.payload.selectquantity > 0) {
          state.basket.push(action.payload);
        }

        const serializedData = JSON.stringify(state.basket);
        localStorage.setItem("basket", serializedData);
      }
    },
    clearBasket: (state) => {
      state.basket = [];
      localStorage.removeItem("basket"); // Это обновит localStorage вместе с состоянием
    },
    // Новый редьюсер для обновления всей корзины
    updateBasket: (state, action) => {
      // Полностью заменяем текущую корзину новыми данными
      state.basket = action.payload;

      // Обновляем localStorage
      const serializedData = JSON.stringify(state.basket);
      localStorage.setItem("basket", serializedData);
    },
  },
});

export const {
  addToBasket,
  removeFromBasket,
  updateBasketItemQuantity,
  clearBasket,
  updateBasket,
} = basketSlice.actions;

export const selectBasket = (state) => {
  return state.basket.basket;
};

export default basketSlice.reducer;
