import React, { useEffect, useRef, useState } from "react";
import styles from "../scss/components/productItems.module.scss";
import axios from "axios";
import PaginationRounded from "./mui/Pagination";
import ProductCard from "./ProductCard";
import { useTranslation } from "react-i18next";
import Spinner from "./Spinner";

function calculateDiscountedPrice(originalPrice, discountPercentage) {
  const discountedPrice = (originalPrice * (100 - discountPercentage)) / 100;
  return Math.round(discountedPrice * 100) / 100;
}

const ProductItems = ({
  sorting,
  category,
  selectedCharacteristics,
  setMaxPrice,
  setMinPrice,
  setSafeMaxPrice,
  setValue,
  value,
}) => {
  const { t, i18n } = useTranslation();
  const [productData, setProductData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [filteredData, setFilteredData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [salesById, setSalesById] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage]);
  const token = process.env.REACT_APP_TOKEN;

  const [commentData, setCommentData] = useState([]);
  useEffect(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/product-comments?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=article&fields[1]=estimation`
        )
        .then((res) => {
          const products = res.data.data;
          if (products.length > 0) {
            setCommentData((prevData) => [...prevData, ...products]);
            fetchData(page + 1);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
          setIsLoading(false);
        });
    };

    fetchData();
  }, []);

  const [hitData, setHitData] = useState({});
  useEffect(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/order-products?pagination[page]=${page}&pagination[pageSize]=1000&fields[0]=article&fields[1]=quantity`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const orderProductData = response.data.data;
          const salesById = orderProductData.reduce((acc, product) => {
            const { article, quantity } = product.attributes;
            acc[article] = (acc[article] || 0) + quantity;
            return acc;
          }, {});
          const topSellingProducts = Object.keys(salesById)
            .sort((a, b) => salesById[b] - salesById[a])
            .slice(0, 14);
          setHitData(
            topSellingProducts.reduce((acc, id) => {
              acc[id] = true;
              return acc;
            }, {})
          );
          const nextPageExists =
            response.data.meta.pagination.page *
              response.data.meta.pagination.pageSize <
            response.data.meta.pagination.totalCount;
          if (nextPageExists) {
            fetchData(page + 1);
          } else {
          }
        })
        .catch((error) => {
          /*  console.error("Error fetching order products data:", error); */
        });
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/products?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=title&fields[1]=price&fields[2]=priceDiscount&fields[3]=new&fields[4]=article&fields[5]=quantity&fields[6]=roTitle&populate[imageMain][fields][0]=name&populate[imageMain][fields][1]=url&populate[product_characteristics][fields][0]=id&populate[product_categories][fields][0]=id`
        )
        .then((res) => {
          const products = res.data.data;
          if (products.length > 0) {
            setProductData((prevData) => [...prevData, ...products]);
            fetchData(page + 1); // Рекурсивный вызов с увеличением номера страницы
          } else {
            setIsLoading(false); // Завершаем загрузку, когда больше нет данных
            // Обновляем максимальную цену один раз после получения всех данных
            const maxPriceFromLoadedProducts = Math.max(
              ...res.data.data.flatMap((p) =>
                calculateDiscountedPrice(
                  p.attributes.price,
                  p.attributes.priceDiscount
                )
              ),
              0
            );
            setMaxPrice(maxPriceFromLoadedProducts);
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
          setIsLoading(false); // Устанавливаем isLoading в false в случае ошибки
        });
    };

    fetchData(); // Начинаем с первой страницы
  }, []);

  useEffect(() => {
    if (!productData.length) return;
    let sortedAndFilteredData = [...productData];
    if (category) {
      sortedAndFilteredData = sortedAndFilteredData.filter((product) =>
        product.attributes.product_categories.data.some(
          (cat) => cat.id == category
        )
      );
      setFilteredData(sortedAndFilteredData);
      if (sortedAndFilteredData.length) {
        const prices = sortedAndFilteredData.map((item) =>
          calculateDiscountedPrice(
            item.attributes.price,
            item.attributes.priceDiscount
          )
        );
        const maxFilteredPrice = Math.max(...prices);
        const minFilteredPrice = Math.min(...prices);
        setMaxPrice(maxFilteredPrice);
        setMinPrice(minFilteredPrice);
        setSafeMaxPrice(maxFilteredPrice);
        /*  console.log("valueProductItems", value); */
        setValue([minFilteredPrice, maxFilteredPrice]);
      } else {
        setMaxPrice(0);
        setMinPrice(0);
      }
    }
  }, [productData, category]);
  useEffect(() => {
    if (value[1] !== Infinity) {
      let newDisplayData = filteredData

        // Фильтр по цене
        .filter((item) => {
          const discountedPrice = calculateDiscountedPrice(
            item.attributes.price,
            item.attributes.priceDiscount
          );
          /*  console.log("Discounted Price:", discountedPrice); */
          return discountedPrice >= value[0] && discountedPrice <= value[1];
        })
        // Сортировка
        .sort((a, b) => {
          switch (sorting) {
            case "A-Z":
              return a.attributes.title.localeCompare(b.attributes.title);
            case "Z-A":
              return b.attributes.title.localeCompare(a.attributes.title);
            case "low-to-high":
              return (
                calculateDiscountedPrice(
                  a.attributes.price,
                  a.attributes.priceDiscount
                ) -
                calculateDiscountedPrice(
                  b.attributes.price,
                  b.attributes.priceDiscount
                )
              );
            case "high-to-low":
              return (
                calculateDiscountedPrice(
                  b.attributes.price,
                  b.attributes.priceDiscount
                ) -
                calculateDiscountedPrice(
                  a.attributes.price,
                  a.attributes.priceDiscount
                )
              );
            case "hits":
              return (
                (salesById[b.attributes.article] || 0) -
                (salesById[a.attributes.article] || 0)
              );

            case "discount":
              return b.attributes.priceDiscount - a.attributes.priceDiscount;
            default:
              return 0;
          }
        });
      // Фильтр по характеристикам
      if (Object.keys(selectedCharacteristics).length > 0) {
        newDisplayData = newDisplayData.filter((product) =>
          product.attributes.product_characteristics.data.some(
            (characteristic) => selectedCharacteristics[characteristic.id]
          )
        );
      }

      /*  console.log("itemValue", value); */
      setDisplayData(newDisplayData); // Обновляем данные для отображения
      /* console.log("New Display Data", newDisplayData); */
    } else {
      setDisplayData(filteredData); // Если значения фильтра сброшены, сбросьте `displayData` до исходного списка данных
      /*    console.log("Filter values reset to initial state"); */
    }
  }, [value, sorting, selectedCharacteristics]);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  if (isLoading) {
    // Если данные загружаются, можно показать спиннер или другой индикатор загрузки
    return <Spinner />;
  }
  const onPageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className={styles.productItems}>
      <div className={styles.items}>
        {displayData
          .slice(indexOfFirstItem, indexOfLastItem)
          .map((product, index) => {
            // Фильтрация и сортировка комментариев для данного продукта
            const sortedComments = commentData
              .filter(
                (comment) =>
                  comment.attributes.article === product.attributes.article
              )
              .sort((a, b) => {
                const dateA = a.attributes.date || a.attributes.createdAt;
                const dateB = b.attributes.date || b.attributes.createdAt;
                return new Date(dateB) - new Date(dateA);
              });
            // Вычисляем среднюю оценку и количество отзывов
            const averageRating =
              sortedComments.reduce(
                (total, comment) => total + comment.attributes.estimation,
                0
              ) / (sortedComments.length || 1); // Деление на ноль обрабатываем
            const reviewCount = sortedComments.length;
            const isHit = hitData[product.attributes?.article] === true;

            return (
              <ProductCard
                product={product}
                key={product.id}
                id={product.id}
                article={product.attributes?.article}
                title={product.attributes.title}
                roTitle={product.attributes.roTitle}
                translateTitle={
                  i18n.language === "ro"
                    ? product.attributes.roTitle
                    : product.attributes.title
                }
                price={product.attributes?.price}
                image={product.attributes?.imageMain?.data?.attributes?.url}
                widgetNew={product.attributes?.new}
                priceDiscount={product.attributes?.priceDiscount}
                quantity={product.attributes?.quantity}
                averageRating={averageRating}
                reviewCount={reviewCount}
                isHit={isHit}
              />
            );
          })}
      </div>
      {displayData.length === 0 && displayData.length === 0 && (
        <div className={styles.productItemsEmpty}>
          <p>{t("header.headerWord.searchEmpty")}</p>
        </div>
      )}
      <div
        className={
          displayData.length !== 0 && displayData.length > 20
            ? styles.pagination
            : styles.paginationHidden
        }
      >
        <PaginationRounded
          pageCount={Math.ceil(displayData.length / itemsPerPage)}
          currentPage={currentPage}
          onPageChange={(event, newPage) => setCurrentPage(newPage)}
        />
      </div>
    </div>
  );
};

export default ProductItems;
