import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import styles from "../../scss/components/pages/admin.module.scss";

import AdminHeader from "../AdminHeader";
import OrderItems from "../OrderItems";
import Login from "../Login";

const OrderDetails = () => {
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [storedUserData, setStoredUserData] = useState(
    JSON.parse(localStorage.getItem("user-data")) || {}
  );
  const token = storedUserData.token;

  const fetchSectionData = useCallback(() => {
    axios
      .get(
        `https://api.prodcomfit.md/api/orders/${orderId}?pagination[page]=1&pagination[pageSize]=1000&populate=*`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOrderData(res.data);
        setLoading(false);
        /*   console.log("Данные успешно загружены."); */
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
        setLoading(false);
        /*  console.log("Произошла ошибка при загрузке данных."); */
      });
  }, [orderId]);

  useEffect(() => {
    fetchSectionData();
  }, [fetchSectionData]);

  return (
    <div>
      {localStorage.getItem("user-data") === undefined ? (
        <Login />
      ) : (
        <>
          <AdminHeader />
          <OrderItems orderData={orderData} token={token} />
        </>
      )}
    </div>
  );
};

export default OrderDetails;
