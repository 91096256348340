import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import HeaderOtherPages from "../HeaderOtherPages";
import CatalogCategoryDetails from "../CatalogCategoryDetails";
import Footer from "../Footer";
import CatalogCategoryBreadcrumb from "../breadcrumbs/CatalogCategoryBreadcrumb";
import Spinner from "../Spinner";
import NotFound from "../pages/NotFound";
import PhoneWidget from "../mui/PhoneWidget";
import ScrollToTopWidget from "../mui/ScrollToTopWidget";
import styles from "../../scss/components/pages/catalogCategory.module.scss";

const CatalogCategory = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18next.language;
  const { sectionId } = useParams();
  const [sectionData, setSectionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [widgetActive, setWidgetActive] = useState(true);

  const fetchSectionData = useCallback(() => {
    /*  console.log("Запрос данных начат."); */
    axios
      .get(
        `https://api.prodcomfit.md/api/products-sections/${sectionId}?pagination[page]=1&pagination[pageSize]=1000&fields[0]=id&fields[1]=name&fields[2]=roName&populate[product_categories][populate][fields][0]=id&populate[product_categories][fields][1]=name&populate[product_categories][populate][fields][1]=roName&populate[product_shapters][fields][0]=id&populate[product_shapters][fields][1]=name&populate[product_shapters][fields][2]=roName`
      )
      .then((res) => {
        setSectionData(res.data);
        setLoading(false);
        /*   console.log("Данные успешно загружены."); */
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
        setLoading(false);
        /*  console.log("Произошла ошибка при загрузке данных."); */
      });
  }, [sectionId]);

  useEffect(() => {
    fetchSectionData();
  }, [fetchSectionData]);

  const titles = useMemo(() => {
    const categories = sectionData?.data?.attributes?.product_categories;
    return (
      categories?.data?.reduce((acc, category) => {
        const categoryId = category.id;
        const categoryTitle =
          i18n.language === "ro"
            ? category.attributes?.roName
            : category.attributes?.name;
        acc[categoryId] = categoryTitle;
        return acc;
      }, {}) || {}
    );
  }, [sectionData, i18n.language]);
  const title = useMemo(() => {
    return currentLanguage === "ro"
      ? sectionData?.data?.attributes?.roName || ""
      : sectionData?.data?.attributes?.name || "";
  }, [sectionData, currentLanguage]);

  const chapterId =
    sectionData?.data?.attributes?.product_shapters?.data[0]?.id || "";

  const chapterTitle = useMemo(() => {
    return currentLanguage === "ro"
      ? sectionData?.data?.attributes?.product_shapters?.data[0]?.attributes
          ?.roName || ""
      : sectionData?.data?.attributes?.product_shapters?.data[0]?.attributes
          ?.name || "";
  }, [sectionData, currentLanguage]);

  if (loading) {
    /* console.log("Отображаем Spinner во время загрузки данных."); */
    return <Spinner />;
  }

  if (!sectionData) {
    /* console.log("Отображаем NotFound, так как нет данных."); */
    return <NotFound />;
  }
  return (
    <div className={styles.catalogCategory}>
      <HeaderOtherPages setWidgetActive={setWidgetActive} />
      <div className={styles.contant}>
        <CatalogCategoryBreadcrumb
          chapterId={chapterId}
          chapterTitle={chapterTitle}
          name={title}
        />
        <CatalogCategoryDetails name={titles} section={sectionData} />
        {widgetActive ? (
          <div className={styles.widget}>
            <ScrollToTopWidget />
            <PhoneWidget />
          </div>
        ) : (
          ""
        )}
      </div>
      <Footer />
    </div>
  );
};

export default React.memo(CatalogCategory);
