import React, { useState } from "react";
import styles from "../../scss/components/pages/home.module.scss";


import HeaderIsland from "../HeaderIsland";
import BannerAdvantages from "../BannerAdvantages";
import BannerProductRange from "../BannerProductRange";
import WatchedCarousel from "../WatchedCarousel";
import NewCarousel from "../NewCarousel";
import HitCarousel from "../HitCarousel";
import DiscountCarousel from "../DiscountCarousel";
import BannerAboutUs from "../BannerAboutUs";
import BrandCarousel from "../BrandCarousel";
import ContactUs from "../ContactUs";
import BannerDispatch from "../BannerDispatch";
import BlogPostCarousel from "../BlogPostCarousel";
import Footer from "../Footer";
import PhoneWidget from "../mui/PhoneWidget";
import ScrollToTopWidget from "../mui/ScrollToTopWidget";
import MainBannerChapter from "../MainBannerChapter";
import MainBannerCarousel from "../MainBannerCarousel";

const Home = () => {
  const [widgetActive, setWidgetActive] = useState(true);

  return (
    <div className={styles.home}>
      <HeaderIsland setWidgetActive={setWidgetActive} />
      <MainBannerCarousel />
      <div className={styles.contant}>
        <MainBannerChapter />
        <BannerAdvantages style={{ background: 'white' }}/>
        <BannerProductRange />
        <HitCarousel />
        <DiscountCarousel />
        <BannerDispatch />
        <NewCarousel />
        <BannerAboutUs />
        <BrandCarousel />
        <ContactUs />
        <BlogPostCarousel />
        <WatchedCarousel  style={{ background: 'white' }}/>
        {widgetActive ? (
          <div className={styles.widget}>
            <ScrollToTopWidget />
            <PhoneWidget />
          </div>
        ) : (
          ""
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Home;
