import { createSlice } from "@reduxjs/toolkit";

// Функция для получения данных заказа из localStorage
const getOrderDataFromLocalStorage = () => {
  const storedOrderData = localStorage.getItem("orderData");
  return storedOrderData ? JSON.parse(storedOrderData) : {};
};

// Начальное состояние для formData
const initialFormData = {
  name: "",
  number: "",
  email: "",
  citySelect: "",
  city: "",
  streetHouseApartment: "",
  postalIndex: "",
  comment: "",
  delivery: "",
  agreedToTerms: false,
};

// Создаем slice для заказов
const orderSlice = createSlice({
  name: "order",
  initialState: {
    orderData: getOrderDataFromLocalStorage(), // Загружаем начальное состояние из localStorage, если оно есть
    formData: initialFormData, // Добавляем начальное состояние для formData
    products: null,
    orderDetails: null,
    currentOrder: null,
  },
  reducers: {
    // Экшен для установки данных заказа
    setOrderData: (state, action) => {
      state.orderData = action.payload;
      localStorage.setItem("orderData", JSON.stringify(state.orderData));
    },
    // Экшен для обновления данных формы в orderData
    updateOrderDataWithForm: (state, action) => {
      // Объединяем текущие orderData с новыми данными из formData
      state.orderData = {
        ...state.orderData,
        ...action.payload, // action.payload должен быть объектом formData
      };
      localStorage.setItem("orderData", JSON.stringify(state.orderData));
    },
    // Экшен для установки продуктов
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    // Экшен для установки деталей заказа
    setOrderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    // Экшен для сохранения текущего заказа
    saveCurrentOrder: (state, action) => {
      state.currentOrder = action.payload;
      localStorage.setItem("currentOrder", JSON.stringify(action.payload));
    },
    // Экшен для сброса данных заказа
    resetOrderData: (state) => {
      state.orderData = {};
      state.formData = initialFormData; // Сброс formData до начального состояния
      state.products = null;
      state.orderDetails = null;
      state.currentOrder = null;
      localStorage.removeItem("orderData");
      localStorage.removeItem("currentOrder");
    },
  },
});

// Экспорт экшенов
export const {
  setOrderData,
  updateOrderDataWithForm,
  setProducts,
  setOrderDetails,
  saveCurrentOrder,
  resetOrderData,
} = orderSlice.actions;

// Селекторы для доступа к данным из состояния
export const selectOrderData = (state) => state.order.orderData;
export const selectFormData = (state) => state.order.formData;
export const selectProducts = (state) => state.order.products;
export const selectPromo = (state) => state.order.promo;

export const selectTotal = (state) => state.order.total ?? 0;
export const selectDiscountPrice = (state) => state.order.promo?.discountPrice ?? 0;

export const selectOrderDetails = (state) => state.order.orderDetails;
export const selectCurrentOrder = (state) => state.order.currentOrder;


export default orderSlice.reducer;
