import React, { useEffect, useState, useMemo, useRef } from "react";
import styles from "../scss/components/newCarousel.module.scss";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import NewProductCard from "./NewProductCard";

const NewCarousel = () => {
  const { t, i18n } = useTranslation();
  const token = process.env.REACT_APP_TOKEN;
  const [newData, setNewData] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const sliderRef = useRef();
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: null,
    prevArrow: null,
    draggable: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
    ],
    afterChange: (current) => {
      setCurrentSlide(current);
    },
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(true),
  };
  useEffect(() => {
    let timer;
    if (isDragging) {
      // Запускаем таймер при установке isDragging в true
      timer = setTimeout(() => {
        setIsDragging(false);
      }, 100);
    }
    return () => clearTimeout(timer);
  }, [isDragging]);
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/products?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=title&fields[1]=price&fields[2]=priceDiscount&fields[3]=new&fields[4]=article&fields[5]=quantity&fields[6]=roTitle&fields[7]=roDesriptions&fields[8]=description&populate[imageMain][fields][0]=name&populate[imageMain][fields][1]=url`
        
          )
        .then((res) => {
          const products = res.data.data;
          if (products.length > 0) {
            setNewData((prevData) => [...prevData, ...products]);
            fetchData(page + 1);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
          setIsLoading(false);
        });
    };

    fetchData();
  }, []);
  const [commentData, setCommentData] = useState([]);
  useEffect(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/product-comments?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=article&fields[1]=estimation`
        )
        .then((res) => {
          const products = res.data.data;
          if (products.length > 0) {
            setCommentData((prevData) => [...prevData, ...products]);
            fetchData(page + 1);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
          setIsLoading(false);
        });
    };

    fetchData();
  }, []);

  const filteredNewData = useMemo(() => {
    return newData
      .filter(
        (item) =>
          item.attributes.new === true &&
          item.attributes.quantity !== null &&
          item.attributes.quantity !== 0
      )
      .slice(0, 14);
  }, [newData]);

  const [hitData, setHitData] = useState({});
  useEffect(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/order-products?pagination[page]=${page}&pagination[pageSize]=1000&fields[0]=article&fields[1]=quantity`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const orderProductData = response.data.data;
          const salesById = orderProductData.reduce((acc, product) => {
            const { article, quantity } = product.attributes;
            acc[article] = (acc[article] || 0) + quantity;
            return acc;
          }, {});
          const topSellingProducts = Object.keys(salesById)
            .sort((a, b) => salesById[b] - salesById[a])
            .slice(0, 14);
          setHitData(
            topSellingProducts.reduce((acc, id) => {
              acc[id] = true;
              return acc;
            }, {})
          );
          const nextPageExists =
            response.data.meta.pagination.page *
              response.data.meta.pagination.pageSize <
            response.data.meta.pagination.totalCount;
          if (nextPageExists) {
            fetchData(page + 1);
          } else {
          }
        })
        .catch((error) => {
          /*  console.error("Error fetching order products data:", error); */
        });
    };

    fetchData();
  }, []);

  return (
    <div
      className={`${styles.newCarousel} ${
        filteredNewData.length === 0 ? styles.hidden : ""
      }`}
    >
      <div className="container">
        <div className={styles.newCarousel__wrapper}>
          <p className={styles.title}>{t("carousel.title.new")}</p>
          <div className={styles.embla}>
            <div className={styles.embla__container}>
              {filteredNewData.length > 0 ? (
                <Slider
                  ref={sliderRef}
                  className={styles.carousel}
                  {...sliderSettings}
                >
                  {filteredNewData.map((item) => {
                    // Фильтрация и сортировка комментариев для данного продукта
                    const sortedComments = commentData
                      .filter(
                        (comment) =>
                          comment.attributes.article === item.attributes.article
                      )
                      .sort((a, b) => {
                        const dateA =
                          a.attributes.date || a.attributes.createdAt;
                        const dateB =
                          b.attributes.date || b.attributes.createdAt;
                        return new Date(dateB) - new Date(dateA);
                      });
                    // Вычисляем среднюю оценку и количество отзывов
                    const averageRating =
                      sortedComments.reduce(
                        (total, comment) =>
                          total + comment.attributes.estimation,
                        0
                      ) / (sortedComments.length || 1); // Деление на ноль обрабатываем
                    const reviewCount = sortedComments.length;

                    const isHit = hitData[item.attributes.article] === true;

                    if (item && item.attributes) {
                      return (
                        <div key={item.id} className={styles.item}>
                          <NewProductCard
                            isDragging={isDragging}
                            id={item.id}
                            title={item.attributes.title}
                            roTitle={item.attributes.roTitle}
                            translateTitle={
                              i18n.language === "ro"
                                ? item.attributes.roTitle
                                : item.attributes.title
                            }
                            article={item.attributes.article}
                            price={item.attributes.price}
                            image={
                              item.attributes?.imageMain?.data?.attributes.url
                            }
                            priceDiscount={item.attributes.priceDiscount}
                            widgetNew={item.attributes.new}
                            quantity={item.attributes.quantity}
                            averageRating={averageRating}
                            reviewCount={reviewCount}
                            isHit={isHit}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </Slider>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCarousel;
