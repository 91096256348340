import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import styles from "../scss/components/headerIsland.module.scss";
import logo from "../assets/logo.png";
import { useSelector } from "react-redux";
import { selectFavorites } from "../store/features/favoritesSlice";
import { selectBasket } from "../store/features/basketSlice";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const HeaderIsland = ({ setWidgetActive }) => {
  const { t } = useTranslation();
  const language = i18next.language;

  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  useEffect(() => {
    const handleLanguageChange = (lng) => {
      setCurrentLanguage(lng);
    };
    i18next.on("languageChanged", handleLanguageChange);
    return () => {
      i18next.off("languageChanged", handleLanguageChange);
    };
  }, []);
  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
  };
  const [sectionsData, setSectionsData] = useState([]);
  const [menuSectionsData, setMenuSectionsData] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  const [menuDataLoaded, setMenuDataLoaded] = useState(false);
  const [openCatalog, setOpenCatalog] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [hoveredSection, setHoveredSection] = useState(null);
  const [catalogDataLoaded, setCatalogDataLoaded] = useState(false);

  const handleOpenCatalog = () => {
    if (!openCatalog) {
      setOpenMenu(false);
      setSearchOpen(false);
      if (!catalogDataLoaded) {
        // Проверка, были ли уже загружены данные
        loadCatalogData(); // Если нет, то загрузите данные
        setCatalogDataLoaded(true); // Устанавливаем флаг, что данные теперь загружены
      }
    }
    setOpenCatalog(!openCatalog);
  };
  const loadCatalogData = () => {
    // Запрос к API для каталога
    axios
      .get(
        "https://api.prodcomfit.md/api/product-shapters?pagination[page]=1&pagination[pageSize]=1000&populate[product_sections][fields][0]=name&populate[product_sections][fields][1]=roName"
      )
      .then((res) => {
        // Добавьте проверку на null здесь
        if (res.data && res.data.data) {
          setSectionsData(res.data.data);
        }
      });
  };

  const handleSectionClick = (e, section) => {
    e.stopPropagation(); // Останавливаем всплытие события
    setSelectedSection(section);
    setOpenCatalog(false); // Принудительно устанавливаем в false
  };
  const handleCategoryClick = (e, category) => {
    e.stopPropagation(); // Останавливаем всплытие события
    setSelectedCategory(category);
    setOpenCatalog(false); // Принудительно устанавливаем в false
  };
  const handleMouseEnter = (section) => {
    setHoveredSection(section);
  };
  const handleOpenMenu = () => {
    if (!openMenu) {
      setSearchOpen(false);
      setOpenCatalog(false);
      if (!menuDataLoaded) {
        // Проверка, были ли уже загружены данные
        loadMenuData(); // Если нет, то загрузите данные
        setMenuDataLoaded(true); // Устанавливаем флаг, что данные теперь загружены
      }
    }
    setOpenMenu(!openMenu);
  };

  const loadMenuData = () => {
    // Запрос к API для меню (ваш код загрузки данных)
    axios
      .get(
        "https://api.prodcomfit.md/api/products-sections?[fields][0]=id&[fields][1]=name&[fields][2]=roName"
      )
      .then((res) => {
        // Добавьте проверку на null здесь
        if (res.data && res.data.data) {
          setMenuSectionsData(res.data.data);
        }
      });
  };
  const favorites = useSelector(selectFavorites);
  const basket = useSelector(selectBasket);

  //Поиск
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [postSearchResults, setPostSearchResults] = useState([]);
  const [productSearchResults, setProductSearchResults] = useState([]);
  const [postData, setPostData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [apiDataLoaded, setApiDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleDynamicSearch = (query) => {
    setOpenMenu(false);
    setOpenCatalog(false);
    if (query.length >= 7) {
      handleSearch(query);
      setSearchOpen(true);
    } else {
      setSearchOpen(false);
    }
  };
  const handleSearch = (query) => {
    // Запрос к API только если данные еще не загружены
    if (!apiDataLoaded) {
      // Запрос к API для блогов
      const fetchBlogData = async (page = 1) => {
        try {
          const res = await axios.get(
            `https://api.prodcomfit.md/api/blogs?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=title&fields[1]=roTitle&populate[imagePostCard][fields][0]=name&populate[imagePostCard][fields][1]=url`
          );
          const data = res.data.data;
          if (data.length > 0) {
            setPostData((prevData) => [...prevData, ...data]);
            fetchBlogData(page + 1); // Рекурсивный вызов с увеличением номера страницы
          } else {
            setIsLoading(false); // Завершаем загрузку, когда больше нет данных
          }
        } catch (error) {
          console.error("Ошибка при запросе данных:", error);
          setIsLoading(false); // Устанавливаем состояние загрузки в false в случае ошибки
        }
      };

      // Запрос к API для продуктов
      const fetchProductData = async (page = 1) => {
        try {
          const res = await axios.get(
            `https://api.prodcomfit.md/api/products?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=title&fields[1]=roTitle&fields[2]=article&populate[imageMain][fields][0]=name&populate[imageMain][fields][1]=url`
          );
          const data = res.data.data;
          if (data.length > 0) {
            setProductData((prevData) => [...prevData, ...data]);
            fetchProductData(page + 1); // Рекурсивный вызов с увеличением номера страницы
          } else {
            setIsLoading(false); // Завершаем загрузку, когда больше нет данных
          }
        } catch (error) {
          console.error("Ошибка при запросе данных:", error);
          setIsLoading(false); // Устанавливаем состояние загрузки в false в случае ошибки
        }
      };

      fetchProductData();
      fetchBlogData();

      // Установка состояния, что данные уже загружены
      setApiDataLoaded(true);
    }
    const postResults = postData.filter((post) => {
      const titleField = language === "ru" ? "title" : "roTitle";
      return (
        post.attributes[titleField] &&
        post.attributes[titleField].toLowerCase().includes(query.toLowerCase())
      );
    });
    const productResults = productData.filter((product) => {
      const titleField = language === "ru" ? "title" : "roTitle";
      const articleField = language === "ru" ? "article" : "article";
      return (
        (product.attributes[titleField] &&
          product.attributes[titleField]
            .toLowerCase()
            .includes(query.toLowerCase())) ||
        (product.attributes[articleField] &&
          product.attributes[articleField]
            .toLowerCase()
            .includes(query.toLowerCase()))
      );
    });

    const maxResults = 15;
    const limitedPostResults = postResults.slice(0, maxResults);
    const limitedProductResults = productResults.slice(0, maxResults);

    setPostSearchResults(limitedPostResults);
    setProductSearchResults(limitedProductResults);

    if (limitedPostResults.length > 0 || limitedProductResults.length > 0) {
      setSearchOpen(true);
      setOpenMenu(false);
      setOpenCatalog(false);
    } else {
      setSearchOpen(false);
    }
  };

  const toggleSearchOpen = () => {
    if (!searchOpen) {
      setOpenMenu(false);
      setOpenCatalog(false);
    }
    clearSearchQuery();
    setSearchOpen(!searchOpen);
  };
  const [openSearchMb, setOpenSearchMb] = useState(false);
  const toggleOpenSearchMb = () => {
    setOpenSearchMb(!openSearchMb);
  };
  const clearSearchQuery = () => {
    setSearchQuery(""); // Очистить значение searchQuery
    handleDynamicSearch(""); // Вызвать функцию поиска с пустым значением
  };
  const handleClick = () => {
    clearSearchQuery();
    setOpenSearchMb();
  };
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  useEffect(() => {
    if (openMenu || searchOpen || openCatalog) {
      setWidgetActive(false);
    } else {
      setWidgetActive(true);
    }
  }, [openMenu, openCatalog, searchOpen]);
  const isBackgroundNeeded = searchOpen || openCatalog;

  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = () => {
    const scrollY = window.scrollY || window.pageYOffset;
    setIsScrolled(scrollY > 0);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`${styles.header} ${
        isBackgroundNeeded ? styles.headerWithBackground : ""
      }`}
    >
      <div
        className={`${styles.bottom} ${
          openCatalog ? styles.bottomActive : ""
        } ${searchOpen && openSearchMb ? styles.searchOpenActiveMb : ""}`}
      >
        <div className="container">
          <div className={styles.bottom__wrapper}>
            <div className={styles.logoBm}>
              <Link to="/">
                <img className={styles.logo} src={logo} alt="logo" />
              </Link>
            </div>
            <div
              onClick={handleOpenCatalog}
              className={
                openCatalog
                  ? `${styles.catalog} ${styles.catalogActive}`
                  : styles.catalog
              }
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#8fc23d"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M4 18L20 18"
                    stroke="8fc23d"
                    strokeWidth="2"
                    strokeLinecap="round"
                  ></path>{" "}
                  <path
                    d="M4 12L20 12"
                    stroke="8fc23d"
                    strokeWidth="2"
                    strokeLinecap="round"
                  ></path>{" "}
                  <path
                    d="M4 6L20 6"
                    stroke="8fc23d"
                    strokeWidth="2"
                    strokeLinecap="round"
                  ></path>{" "}
                </g>
              </svg>
              <p>{t("header.headerWord.catalogName")}</p>
            </div>
            <div className={styles.search}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 17 16"
                fill="#8FC23D"
              >
                <path
                  d="M11.9768 10.0629H11.269L11.0242 9.81132C11.8967 8.77303 12.4219 7.42367 12.4219 5.94627C12.4219 2.66209 9.83117 0 6.63505 0C3.43893 0 0.848145 2.66209 0.848145 5.94627C0.848145 9.23045 3.43889 11.8925 6.63505 11.8925C8.07286 11.8925 9.38605 11.3528 10.3965 10.4608L10.6414 10.7124V11.4351L15.0928 16L16.4193 14.6369L11.9768 10.0629ZM6.63505 10.0629C4.42269 10.0629 2.62874 8.21955 2.62874 5.94627C2.62874 3.67299 4.42269 1.82963 6.63505 1.82963C8.84741 1.82963 10.6414 3.67299 10.6414 5.94627C10.6414 8.21955 8.84741 10.0629 6.63505 10.0629Z"
                  fill="#8FC23D"
                />
              </svg>
              <input
                type="text"
                placeholder={t("header.headerWord.searchPlaceholder")}
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  handleDynamicSearch(e.target.value);
                }}
              />
              <button
                onClick={() => {
                  if (searchQuery) {
                    toggleSearchOpen();
                  }
                }}
                disabled={searchQuery && searchOpen}
              >
                {t("header.headerWord.searchBtn")}
              </button>
            </div>
            <div className={styles.actions}>
              <div className={styles.actions__searchMb}>
                <svg
                  onClick={toggleOpenSearchMb}
                  className={
                    openSearchMb ? styles.hidden : styles.searchMb__mainSvg
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="25"
                  height="25"
                  viewBox="0,0,256,256"
                >
                  <g
                    fill="#8fc23d"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="1"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                    strokeDasharray=""
                    strokeDashoffset="0"
                    fontFamily="none"
                    fontWeight="none"
                    fontSize="none"
                    textAnchor="none"
                  >
                    <g transform="scale(8.53333,8.53333)">
                      <path d="M13,3c-5.511,0 -10,4.489 -10,10c0,5.511 4.489,10 10,10c2.39651,0 4.59738,-0.85101 6.32227,-2.26367l5.9707,5.9707c0.25082,0.26124 0.62327,0.36648 0.97371,0.27512c0.35044,-0.09136 0.62411,-0.36503 0.71547,-0.71547c0.09136,-0.35044 -0.01388,-0.72289 -0.27512,-0.97371l-5.9707,-5.9707c1.41266,-1.72488 2.26367,-3.92576 2.26367,-6.32227c0,-5.511 -4.489,-10 -10,-10zM13,5c4.43012,0 8,3.56988 8,8c0,4.43012 -3.56988,8 -8,8c-4.43012,0 -8,-3.56988 -8,-8c0,-4.43012 3.56988,-8 8,-8z"></path>
                    </g>
                  </g>
                </svg>
                <div className={openSearchMb ? styles.activ : styles.hidden}>
                  <svg
                    onClick={handleClick}
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="100"
                    height="100"
                    viewBox="0,0,256,256"
                  >
                    <g
                      fill="#b7b8c5"
                      fillRule="nonzero"
                      stroke="none"
                      strokeWidth="1"
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      strokeMiterlimit="10"
                      strokeDasharray=""
                      strokeDashoffset="0"
                      fontFamily="none"
                      fontWeight="none"
                      fontSize="none"
                      textAnchor="none"
                    >
                      <g transform="scale(10.66667,10.66667)">
                        <path d="M4.99023,3.99023c-0.40692,0.00011 -0.77321,0.24676 -0.92633,0.62377c-0.15312,0.37701 -0.06255,0.80921 0.22907,1.09303l6.29297,6.29297l-6.29297,6.29297c-0.26124,0.25082 -0.36647,0.62327 -0.27511,0.97371c0.09136,0.35044 0.36503,0.62411 0.71547,0.71547c0.35044,0.09136 0.72289,-0.01388 0.97371,-0.27511l6.29297,-6.29297l6.29297,6.29297c0.25082,0.26124 0.62327,0.36648 0.97371,0.27512c0.35044,-0.09136 0.62411,-0.36503 0.71547,-0.71547c0.09136,-0.35044 -0.01388,-0.72289 -0.27512,-0.97371l-6.29297,-6.29297l6.29297,-6.29297c0.29576,-0.28749 0.38469,-0.72707 0.22393,-1.10691c-0.16075,-0.37985 -0.53821,-0.62204 -0.9505,-0.60988c-0.2598,0.00774 -0.50638,0.11632 -0.6875,0.30273l-6.29297,6.29297l-6.29297,-6.29297c-0.18827,-0.19353 -0.4468,-0.30272 -0.7168,-0.30273z"></path>
                      </g>
                    </g>
                  </svg>
                  <input
                    type="text"
                    placeholder={t("header.headerWord.searchPlaceholder")}
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      handleDynamicSearch(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={styles.actions__number}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                >
                  <path
                    d="M6.63416 9.36811C5.10422 7.83817 4.75979 6.31143 4.68129 5.69786C4.65886 5.52964 4.71813 5.35983 4.83829 5.23807L6.07345 4.00291C6.25448 3.82188 6.28652 3.53671 6.15195 3.32044L4.18145 0.260557C4.03086 0.0186499 3.72007 -0.069462 3.46374 0.0587007L0.306136 1.54539C0.101076 1.64471 -0.022281 1.86419 0.00335155 2.09328C0.169963 3.66648 0.854031 7.53699 4.65886 11.3418C8.46369 15.1466 12.331 15.8323 13.9074 15.9973C14.1365 16.0198 14.3544 15.8996 14.4553 15.6945L15.9452 12.5305C16.0733 12.2774 15.9852 11.9666 15.7449 11.8176L12.685 9.84872C12.4656 9.71095 12.1836 9.74619 12.0026 9.92722L10.7674 11.1624C10.6489 11.2841 10.4758 11.3402 10.3076 11.3194C9.69405 11.2409 8.16411 10.8981 6.63416 9.36811Z"
                    fill="#8FC23D"
                  />
                </svg>
                <a href="tel:+37360275589">+373 60 275 589</a>
              </div>
              <div className={styles.actions__lang}>
                <p
                  className={currentLanguage === "ro" ? styles.langActive : ""}
                  onClick={() => {
                    i18next.changeLanguage("ro");
                  }}
                  style={{ cursor: "pointer" }}

                >
                  RO
                </p>
                <p
                  className={currentLanguage === "ru" ? styles.langActive : ""}
                  onClick={() => {
                    i18next.changeLanguage("ru");
                  }}
                  style={{ cursor: "pointer" }}

                >
                  RU
                </p>
              </div>

              <Link
                to="/favorites"
                className={`${styles.actions__favorites} ${
                  isScrolled ? styles.activ : ""
                } ${openSearchMb ? styles.hidden : ""}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="#8fc23d"
                    d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"
                  />
                </svg>
                <div>
                  <span>{favorites.length}</span>
                </div>
              </Link>

              <Link
                to="/basket"
                className={`${styles.actions__basket} ${
                  isScrolled ? styles.activ : ""
                } ${openSearchMb ? styles.hidden : ""}`}
              >
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 48 38"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
                    fill="#8FC23D"
                    stroke="none"
                  >
                    <path
                      d="M12 388 c3 -7 19 -14 36 -16 18 -2 32 -6 32 -10 0 -4 7 -50 15 -102
10 -63 21 -99 32 -107 11 -9 55 -13 130 -13 93 0 113 3 113 15 0 12 -21 15
-112 17 -78 2 -113 7 -116 16 -3 9 23 12 108 12 61 0 120 4 130 10 19 10 47
131 35 150 -4 6 -70 10 -155 10 -122 0 -149 3 -154 15 -7 19 -100 22 -94 3z"
                    />
                    <path d="M144 96 c-9 -24 4 -48 23 -44 12 2 18 12 18 28 0 29 -32 41 -41 16z" />
                    <path
                      d="M303 94 c-3 -8 2 -23 11 -32 15 -15 17 -15 32 0 21 21 11 48 -16 48
-11 0 -23 -7 -27 -16z"
                    />
                  </g>
                </svg>
                <div>
                  <span>{basket.length}</span>
                </div>
              </Link>

              <div className={styles.actions__burger}>
                <svg
                  onClick={handleOpenMenu}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#8fc23d"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M4 18L20 18"
                      stroke="8fc23d"
                      strokeWidth="2"
                      strokeLinecap="round"
                    ></path>{" "}
                    <path
                      d="M4 12L20 12"
                      stroke="8fc23d"
                      strokeWidth="2"
                      strokeLinecap="round"
                    ></path>{" "}
                    <path
                      d="M4 6L20 6"
                      stroke="8fc23d"
                      strokeWidth="2"
                      strokeLinecap="round"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${styles.islandBotton} ${searchOpen ? styles.hidden : ""} ${
          isScrolled ? styles.hidden : ""
        }`}
      >
        <div className={styles.islandBotton__lang}>
          <p
            className={
              currentLanguage === "ro" ? styles.islandBottonLangActive : ""
            }
            onClick={() => {
              i18next.changeLanguage("ro");
            }}
          >
            RO
          </p>
          <p
            className={
              currentLanguage === "ru" ? styles.islandBottonLangActive : ""
            }
            onClick={() => {
              i18next.changeLanguage("ru");
            }}
          >
            RU
          </p>
        </div>
        <div className={styles.islandBotton__number}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <path
              d="M6.63416 9.36811C5.10422 7.83817 4.75979 6.31143 4.68129 5.69786C4.65886 5.52964 4.71813 5.35983 4.83829 5.23807L6.07345 4.00291C6.25448 3.82188 6.28652 3.53671 6.15195 3.32044L4.18145 0.260557C4.03086 0.0186499 3.72007 -0.069462 3.46374 0.0587007L0.306136 1.54539C0.101076 1.64471 -0.022281 1.86419 0.00335155 2.09328C0.169963 3.66648 0.854031 7.53699 4.65886 11.3418C8.46369 15.1466 12.331 15.8323 13.9074 15.9973C14.1365 16.0198 14.3544 15.8996 14.4553 15.6945L15.9452 12.5305C16.0733 12.2774 15.9852 11.9666 15.7449 11.8176L12.685 9.84872C12.4656 9.71095 12.1836 9.74619 12.0026 9.92722L10.7674 11.1624C10.6489 11.2841 10.4758 11.3402 10.3076 11.3194C9.69405 11.2409 8.16411 10.8981 6.63416 9.36811Z"
              fill="#fff"
            />
          </svg>
          <a href="tel:+37360275589">+373 60 275 589</a>
        </div>
        <div className={styles.islandBotton__action}>
          <Link to="/favorites" className={styles.islandBotton__favorites}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="17"
              viewBox="0 0 20 17"
              fill="none"
            >
              <path
                d="M10.5201 14.6227C10.143 14.8695 9.82101 15.0701 9.57801 15.2176C9.33457 15.068 9.01161 14.8645 8.63326 14.6142C7.75916 14.036 6.59873 13.214 5.44311 12.2347C4.28283 11.2515 3.156 10.1337 2.32641 8.9681C1.48958 7.79235 1.01855 6.65721 1.01855 5.61841C1.01855 3.1852 2.926 1.26359 5.21709 1.26359V1.26362L5.22512 1.26356C6.62744 1.25229 7.95152 1.97858 8.72868 3.21168L9.56974 4.54614L10.4178 3.21614C11.2018 1.98679 12.5249 1.26053 13.9284 1.26358C16.2166 1.27575 18.118 3.19163 18.1308 5.6207C18.1301 6.68136 17.6567 7.82643 16.8221 9.00244C15.9933 10.1704 14.8674 11.285 13.7081 12.2627C12.5533 13.2365 11.3936 14.0508 10.5201 14.6227Z"
                fill="#fff"
                stroke="#fff"
                strokeWidth="2"
              />
            </svg>
            <div>
              <span>{favorites.length}</span>
            </div>
          </Link>

          <Link to="/basket" className={styles.islandBotton__basket}>
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 48 38"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
                fill="#fff"
                stroke="none"
              >
                <path
                  d="M12 388 c3 -7 19 -14 36 -16 18 -2 32 -6 32 -10 0 -4 7 -50 15 -102
10 -63 21 -99 32 -107 11 -9 55 -13 130 -13 93 0 113 3 113 15 0 12 -21 15
-112 17 -78 2 -113 7 -116 16 -3 9 23 12 108 12 61 0 120 4 130 10 19 10 47
131 35 150 -4 6 -70 10 -155 10 -122 0 -149 3 -154 15 -7 19 -100 22 -94 3z"
                />
                <path d="M144 96 c-9 -24 4 -48 23 -44 12 2 18 12 18 28 0 29 -32 41 -41 16z" />
                <path
                  d="M303 94 c-3 -8 2 -23 11 -32 15 -15 17 -15 32 0 21 21 11 48 -16 48
-11 0 -23 -7 -27 -16z"
                />
              </g>
            </svg>
            <div>
              <span>{basket.length}</span>
            </div>
          </Link>
        </div>
      </div>

      <div className={searchOpen ? styles.serchOpen : styles.serchClose}>
        <div
          onClick={toggleSearchOpen}
          className={styles.serchOpen__wrapper}
        ></div>
        <div className="container">
          <div className={styles.serchOpen__contant}>
            {productSearchResults.map((product) => {
              const imageUrl =
                product?.attributes?.imageMain?.data?.attributes?.url || "";
              return (
                <Link
                  key={product.id}
                  to={`/catalog/product/${product.id}`}
                  onClick={toggleSearchOpen}
                >
                  <div className={styles.serchOpenItem}>
                    <div className={styles.serchOpenProduct}>
                      <div className={styles.serchOpenImage}>
                        <img
                          src={`https://api.prodcomfit.md${imageUrl}`}
                          alt=""
                        />
                      </div>
                      <div className={styles.serchOpenDescriptions}>
                        <p className={styles.serchOpenTitle}>
                          {language === "ro"
                            ? product.attributes.roTitle
                            : product.attributes.title}
                        </p>
                        <p className={styles.serchOpenArticle}>
                          {product.attributes.article || ""}
                        </p>
                      </div>
                    </div>
                    <p className={styles.serchOpenElement}>
                      {t("header.headerWord.searchElementProduct")}
                    </p>
                  </div>
                </Link>
              );
            })}
            {postSearchResults.map((post) => (
              <Link
                key={post.id}
                to={`/blog/${post.id}`}
                onClick={toggleSearchOpen}
              >
                <div className={styles.serchOpenItem}>
                  <div className={styles.serchOpenProduct}>
                    <div className={styles.serchOpenImage}>
                      <img
                        src={`https://api.prodcomfit.md${post.attributes?.imagePostCard?.data[0].attributes?.url}`}
                        alt=""
                      />
                    </div>
                    <div className={styles.serchOpenDescriptions}>
                      <p className={styles.serchOpenTitlePost}>
                        {language === "ro"
                          ? post.attributes.roTitle
                          : post.attributes.title}
                      </p>
                    </div>
                  </div>
                  <p className={styles.serchOpenElement}>
                    {t("header.headerWord.searchElementPost")}
                  </p>
                </div>
              </Link>
            ))}
            {productSearchResults.length === 0 &&
              postSearchResults.length === 0 && (
                <p className={styles.serchOpenEmpty}>
                  {t("header.headerWord.searchEmpty")}
                </p>
              )}
          </div>
        </div>
      </div>

      <div className={openCatalog ? styles.catalogOpen : styles.catalogClose}>
        <div
          onClick={handleOpenCatalog}
          className={styles.catalogOpen__wrapper}
        ></div>
        <div className="container">
          <div className={styles.catalogOpen__contant}>
            <div className={styles.catalogOpen__sections}>
              {sectionsData.map((section, index) => (
                <div
                  key={section.id}
                  className={`${styles.catalogOpen__sections} ${
                    section === (hoveredSection || selectedSection)
                      ? styles.selected
                      : ""
                  }`}
                  onClick={(e) => handleSectionClick(e, section)}
                  onMouseEnter={() => handleMouseEnter(section)}
                >
                  <div className={styles.catalogOpen__sectionNameWrapper}>
                    <Link to={`/catalog/chapter/${section.id}`}>
                      <p>
                        {language === "ro"
                          ? section.attributes.roName
                          : language === "ru"
                          ? section.attributes.name
                          : ""}
                      </p>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.catalogOpen__category}>
              {hoveredSection?.attributes?.product_sections ||
              selectedSection?.attributes?.product_sections ? (
                <div className={styles.catalogOpen__category}>
                  {(
                    hoveredSection || selectedSection
                  ).attributes.product_sections?.data.map((category) => (
                    <div
                      onClick={(e) => handleCategoryClick(e, category)}
                      key={category.id}
                    >
                      <Link to={`/catalog/section/${category.id}`}>
                        <p>
                          {language === "ro"
                            ? category.attributes.roName
                            : language === "ru"
                            ? category.attributes.name
                            : ""}
                        </p>
                      </Link>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {openMenu && (
        <div className={styles.burgerMenu}>
          <div
            onClick={handleOpenMenu}
            className={styles.catalogOpen__wrapper}
          ></div>
          <div className={styles.menuContent}>
            <div
              onClick={handleOpenMenu}
              className={styles.menuContent__header}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="19"
                  viewBox="0 0 22 19"
                  fill="none"
                >
                  <rect
                    x="0.266602"
                    y="0.669434"
                    width="21.25"
                    height="2.0038"
                    rx="1"
                    fill="white"
                  />
                  <rect
                    x="0.266602"
                    y="8.68457"
                    width="21.25"
                    height="2.0038"
                    rx="1"
                    fill="white"
                  />
                  <rect
                    x="0.266602"
                    y="16.6997"
                    width="21.25"
                    height="2.0038"
                    rx="1"
                    fill="white"
                  />
                </svg>
                <p>Каталог товаров</p>
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="15"
                height="15"
                viewBox="0,0,256,256"
              >
                <g
                  fill="#fff"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth="1"
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  strokeMiterlimit="10"
                  strokeDasharray=""
                  strokeDashoffset="0"
                  fontFamily="none"
                  fontWeight="none"
                  fontSize="none"
                  textAnchor="none"
                >
                  <g transform="scale(8.53333,8.53333)">
                    <path d="M7,4c-0.25587,0 -0.51203,0.09747 -0.70703,0.29297l-2,2c-0.391,0.391 -0.391,1.02406 0,1.41406l7.29297,7.29297l-7.29297,7.29297c-0.391,0.391 -0.391,1.02406 0,1.41406l2,2c0.391,0.391 1.02406,0.391 1.41406,0l7.29297,-7.29297l7.29297,7.29297c0.39,0.391 1.02406,0.391 1.41406,0l2,-2c0.391,-0.391 0.391,-1.02406 0,-1.41406l-7.29297,-7.29297l7.29297,-7.29297c0.391,-0.39 0.391,-1.02406 0,-1.41406l-2,-2c-0.391,-0.391 -1.02406,-0.391 -1.41406,0l-7.29297,7.29297l-7.29297,-7.29297c-0.1955,-0.1955 -0.45116,-0.29297 -0.70703,-0.29297z"></path>
                  </g>
                </g>
              </svg>
            </div>

            <nav className={styles.menuContent__navList}>
              <ul>
                {menuSectionsData.map((item) => (
                  <li key={item.id}>
                    <Link
                      onClick={handleOpenMenu}
                      to={`/catalog/section/${item.id}`}
                    >
                      {language === "ro"
                        ? item.attributes.roName
                        : language === "ru"
                        ? item.attributes.name
                        : ""}

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="13"
                        viewBox="0 0 8 13"
                        fill="none"
                      >
                        <path
                          d="M1 1.91748L6 6.91748L1 11.9175"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderIsland;
