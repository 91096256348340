import React, { useState } from "react";
import styles from "../scss/components/basketItem.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { selectOrderData, resetOrderData } from "../store/features/orderSlice";
import { clearBasket, updateBasket } from "../store/features/basketSlice";
import { useTranslation } from "react-i18next";
import axios from "axios";

import Stepper from "./Stepper";

import StepperFirstStep from "./StepperFirstStep";
import StepperSecondStep from "./StepperSecondStep";
import StepperSuccessStep from "./StepperSuccessStep";

const BasketItem = ({ products, currentStep, onPrev, onNext }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  React.useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "basket") {
        const newBasket = JSON.parse(e.newValue);
        dispatch(updateBasket(newBasket));
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [dispatch]);

  const orderData = useSelector(selectOrderData) || "";
  const name = orderData.name;
  const number = orderData.number;
  const email = orderData.email;
  const citySelect = orderData.citySelect;
  const city = orderData.city;
  const streetHouseApartment = orderData.streetHouseApartment;
  const postalIndex = orderData.postalIndex;
  const comment = orderData.comment;
  const delivery = orderData.delivery;
  const usedPromocode = orderData?.promo?.code;
  const usedPromocodeDiscount = orderData?.promo?.discount;
  const usedPromocodeDiscountPrice = orderData?.promo?.discountPrice;
  const payment = orderData.total;
  const orderProducts = orderData.products;

  const handleSubmit = (e) => {
    e.preventDefault();
    const postDataInfo = {
      data: {
        email: email,
        name: name,
        number: number,
        citySelect: citySelect,
        city: city,
        streetHouseApartment: streetHouseApartment,
        postalIndex: postalIndex,
        comment: comment,
        delivery: delivery,
        usedPromocode: usedPromocode,
        usedPromocodeDiscount: usedPromocodeDiscount,
        usedPromocodeDiscountPrice: usedPromocodeDiscountPrice,
        payment: payment,
        dispatched: false,
        adminComments: "",
      },
    };
    axios
      .post("https://api.prodcomfit.md/api/orders", postDataInfo)
      .then((orderResponse) => {
        const orderId = orderResponse.data.data.id;
        const postProductRequests = orderProducts.map((product, index) => {
          const productPostData = {
            data: {
              article: product.article,
              discount: product.discount,
              discountPrice: product.discountPrice,
              price: product.price,
              quantity: product.quantity,
              roName: product.roName,
              ruName: product.ruName,
              pricePosition: product.total,
              order: orderId,
            },
          };
          return axios.post(
            "https://api.prodcomfit.md/api/order-products",
            productPostData
          );
        });
        return Promise.all(postProductRequests).then((responses) => {
          dispatch(clearBasket());
          dispatch(resetOrderData());
          return orderId;
        });
      })
      .catch((error) => {
        console.error("Error during the order processing:", error);
      });
  };

  return (
    <div className={styles.basketItem}>
      <div className="container">
        <p className={styles.title}>{t("pagesAloneName.basket")}</p>

        <Stepper
          currentStep={currentStep}
          onPrev={onPrev}
          onNext={onNext}
          list={[
            <StepperFirstStep
              key={`step1-${currentStep}`}
              onNext={onNext}
              products={products}
            />,
            <StepperSecondStep key="step2" onPrev={onPrev} onNext={onNext} />,
            <StepperSuccessStep key="step3" onSubmit={handleSubmit} />,
          ]}
        />
      </div>
    </div>
  );
};

export default BasketItem;
