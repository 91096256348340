import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import vectorOne from "../assets/bannerDispatchVector1.png";
import vectorTwo from "../assets/bannerDispatchVector2.png";
import vectorThree from "../assets/bannerDispatchVector3.png";
import ellips from "../assets/bannerDispatchBG.png";
import ellipsMB from "../assets/bannerDispatchBGMB.png";
import image from "../assets/bannerDispatchImage.png";
import styles from "../scss/components/bannerDispatch.module.scss";

const BannerDispatch = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    data: {
      email: "",
      response: false,
    },
    errors: {
      email: false,
    },
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      data: {
        ...formData.data,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        [name]: false, // Сбрасываем ошибку при изменении поля
      },
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Проверка на заполнение всех полей
    if (formData.data.email.length < 5) {
      // Устанавливаем ошибки для недостаточной длины поля
      setFormData({
        ...formData,
        errors: {
          email: true,
        },
      });
      return;
    }
    // Создаем объект данных для отправки на сервер
    const postData = {
      data: {
        email: formData.data.email,
      },
    };
    // Выполняем POST-запрос с использованием Axios
    axios
      .post("https://api.prodcomfit.md/api/mailings", postData)
      .then((response) => {
        // Обрабатываем успешный ответ здесь
        /*     console.log("Успешный POST-запрос:", response.data); */
        // Очищаем поля формы после успешной отправки
        setFormData({
          data: {
            email: "",
            response: false,
          },
          errors: {
            email: false,
          },
        });
      })
      .catch((error) => {
        /*  console.error("Ошибка при выполнении POST-запроса:", error); */
      });
  };
  return (
    <div className={styles.bannerDispatch}>
      <div className={styles.bannerDispatch__container}>
        <div className={styles.bannerDispatch__wrapper}>
          <img className={styles.ellips} src={ellips} alt="" />

          <img className={styles.ellipsMB} src={ellipsMB} alt="" />

          <div className={styles.contant}>
            <p className={styles.title}>{t("bannerMailing.title")}</p>
            <p className={styles.subtitle}>{t("bannerMailing.subtitle")}</p>
            <div className={styles.form}>
              <input
                type="text"
                name="email"
                value={formData.data.email}
                onChange={handleChange}
                placeholder={t("bannerMailing.placeholder")}
              />
              <button
                type="submit"
                disabled={formData.data.email.length < 5}
                onClick={handleSubmit}
                className={styles.btn}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="11"
                  viewBox="0 0 12 11"
                  fill="none"
                >
                  <path
                    d="M6.26592 9.72033C5.96012 10.1354 5.48389 10.3905 4.96899 10.4158C4.45408 10.4415 3.95538 10.2339 3.61036 9.85076L0.518567 6.4197C0.0271836 5.87473 0.0708331 5.03468 0.615798 4.54329C1.16129 4.05191 2.00082 4.09582 2.4922 4.64105L4.67964 7.06869C4.71205 7.10476 4.7591 7.1241 4.80746 7.12175C4.85607 7.1194 4.90051 7.09509 4.92952 7.05641L9.42568 0.958285C9.86087 0.367579 10.6926 0.241858 11.2835 0.677307C11.874 1.11276 11.9994 1.94445 11.564 2.53489L6.26592 9.72033Z"
                    fill="white"
                  />
                </svg>
                <p>{t("bannerMailing.btn")}</p>
              </button>
            </div>
          </div>
          <img className={styles.vectorTwo} src={vectorTwo} alt="" />
          <img className={styles.image} src={image} alt="" />
          <img className={styles.vectorOne} src={vectorOne} alt="" />
          <img className={styles.vectorThree} src={vectorThree} alt="" />
          <img className={styles.vectorThreeDuble} src={vectorThree} alt="" />
        </div>
      </div>
      <img className={styles.vectorTwoMb} src={vectorTwo} alt="" />
    </div>
  );
};

export default BannerDispatch;
