import React, { useState } from "react";
import styles from "../../scss/components/pages/favorites.module.scss";
import { useSelector } from "react-redux";
import { selectFavorites } from "../../store/features/favoritesSlice";

import HeaderOtherPages from "../HeaderOtherPages";
import FavoritesItem from "../FavoritesItem";
import FavoritesEmpty from "../FavoritesEmpty";
import Footer from "../Footer";
import FavoritesBreadcrumb from "../breadcrumbs/FavoritesBreadcrumb";
import PhoneWidget from "../mui/PhoneWidget";
import ScrollToTopWidget from "../mui/ScrollToTopWidget";

const Favorites = () => {
  const favorites = useSelector(selectFavorites);
  const [widgetActive, setWidgetActive] = useState(true);

  return (
    <div className={styles.favorites}>
      <HeaderOtherPages setWidgetActive={setWidgetActive} />
      <div className={styles.contant}>
        <FavoritesBreadcrumb />
        {favorites.length > 0 ? (
          <FavoritesItem products={favorites} />
        ) : (
          <FavoritesEmpty />
        )}
        {widgetActive ? (
          <div className={styles.widget}>
            <ScrollToTopWidget />
            <PhoneWidget />
          </div>
        ) : (
          ""
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Favorites;
