import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import Slider from "@mui/material/Slider";
import { useTranslation } from "react-i18next";
import styles from "../scss/components/filter.module.scss";

function customSort(data) {
  const sorted = data
    .filter((item) => item.attributes.sort_id !== null)
    .sort((a, b) => a.attributes.sort_id - b.attributes.sort_id);
  const withNull = data.filter((item) => item.attributes.sort_id === null);

  return [...sorted, ...withNull];
}

const Filter = ({
  category,
  setSelectedCharacteristics,
  selectedCharacteristics,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
  safeMaxPrice,
  value,
  setValue,
  handleMinPriceChange,
  handleMaxPriceChange,
}) => {
  const { t, i18n } = useTranslation();
  const [subcategoryData, setSubcategoryData] = useState([]);
  const [openAccordion, setOpenAccordion] = useState(new Array(0));

  const minDistance = 1;

  const handleSliderChange = useCallback(
    (event, newValue, activeThumb) => {
      if (!Array.isArray(newValue)) {
        return;
      }

      const [newMinValue, newMaxValue] = newValue;
      const newMin = Math.min(newMinValue, newMaxValue - minDistance);
      const newMax = Math.max(newMaxValue, newMinValue + minDistance);

      if (newMin !== minPrice) {
        setMinPrice(newMin);
      }
      if (newMax !== maxPrice) {
        setMaxPrice(newMax);
      }
      setValue([newMin, newMax]);
    },
    [minPrice, maxPrice, setMinPrice, setMaxPrice, setValue]
  );

  useEffect(() => {
    const fetchAllPages = async (url, page = 1, previousData = []) => {
      try {
        const response = await axios.get(`${url}&pagination[page]=${page}&pagination[pageSize]=100`);
        const newData = response.data.data;
  
        if (newData.length === 0) {
          // Если данных больше нет, возвращаем все собранные данные
          return previousData;
        }
  
        // Если есть данные, рекурсивно запрашиваем следующую страницу
        return fetchAllPages(url, page + 1, [...previousData, ...newData]);
      } catch (error) {
        console.error("Ошибка при запросе данных:", error);
        throw error;
      }
    };
  
    const fetchData = async () => {
      try {
        const data = await fetchAllPages(
          "https://api.prodcomfit.md/api/product-subcategories?fields[0]=name&fields[1]=roName&fields[2]=name&fields[3]=sort_id&populate[product_categories][fields][0]=name&populate[product_categories][fields][1]=roName&populate[product_characteristics][fields][0]=sort_id&populate[product_characteristics][fields][1]=roName&populate[product_characteristics][fields][2]=name"
        );
  
        const sortedData = customSort(data); // Сортируем данные, если это необходимо
        setSubcategoryData(sortedData);
        setOpenAccordion(new Array(sortedData.length).fill(true));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);
 
  const handleCheckboxChange = (event, characteristicId) => {
    setSelectedCharacteristics((prev) => {
      const newState = { ...prev };
      if (event.target.checked) {
        newState[characteristicId] = true;
        /*    console.log(characteristicId); */
      } else {
        delete newState[characteristicId];
      }
      if (Object.keys(newState).length === 0) {
        /*  console.log("No characteristics selected, showing all products by default"); */
        return {};
      }
      return newState;
    });
  };

  const handleAccordionClick = (index) => {
    setOpenAccordion((prev) => {
      const newAccordionState = [...prev];
      newAccordionState[index] = !newAccordionState[index];
      return newAccordionState;
    });
  };

  const isCharacteristicSelected = (characteristicId) => {
    return selectedCharacteristics[characteristicId];
  };

  return (
    <div className={styles.filter}>
      <div className={styles.filter__wrapper}>
        <p className={styles.title}>{t("catalog.filter.title")}</p>
        <div className={styles.items}>
          <div className={styles.price}>
            <div className={styles.item__title}>
              <p>{t("catalog.filter.priceTitle")}</p>
            </div>
            <div>
              <div className={styles.priceInput}>
                <input
                  type="number"
                  value={value[0]}
                  onChange={handleMinPriceChange}
                />
                <span>-</span>
                <input
                  type="number"
                  value={value[1]}
                  maxLength={10}
                  onChange={handleMaxPriceChange}
                />
              </div>
              <div>
                <Slider
                  className={styles.slider}
                  getAriaLabel={() => "Minimum distance"}
                  value={value}
                  onChange={handleSliderChange}
                  valueLabelDisplay="auto"
                  disableSwap
                  min={0}
                  max={safeMaxPrice}
                  step={1}
                />
              </div>
            </div>
          </div>
          {subcategoryData.map((subCategory, index) => {
            const productCategoryData =
              subCategory.attributes.product_categories.data;
            const characteristics =
              subCategory.attributes.product_characteristics.data;
            const sortedCharacteristics = customSort(characteristics);

            const isCategoryMatched = productCategoryData.some(
              (categoryItem) => {
                return categoryItem.id === Number(category);
              }
            );

            if (isCategoryMatched) {
              return (
                <div key={subCategory.id} className={styles.item}>
                  <div
                    className={styles.item__title}
                    onClick={() => handleAccordionClick(index)}
                  >
                    <p>
                      {(
                        (i18n.language === "ro" || i18n.language === "roName"
                          ? subCategory.attributes.roName ||
                            subCategory.attributes.name
                          : subCategory.attributes.name) || ""
                      ).replace(/\[.*?\]/g, "")}
                      :
                    </p>
                    {openAccordion[index] ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M7 10L12 15L17 10"
                          stroke="#98999C"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M17 14L12 9L7 14"
                          stroke="#8fc23d"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                  {openAccordion[index] && (
                    <div className={styles.choice}>
                      {sortedCharacteristics.map((characteristic) => (
                        <div
                          key={characteristic.id}
                          className={styles.choice__item}
                        >
                          <input
                            type="checkbox"
                            id={characteristic.id}
                            onChange={(e) =>
                              handleCheckboxChange(e, characteristic.id)
                            }
                            checked={
                              !!selectedCharacteristics[characteristic.id]
                            }
                          />
                          <label htmlFor={characteristic.id}>
                            {(i18n.language === "ro" ||
                            i18n.language === "roName"
                              ? characteristic.attributes.roName ||
                                characteristic.attributes.name
                              : characteristic.attributes.name
                            ).replace(/\[.*?\]/g, "")}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              );
            }

            return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default Filter;
