import React, { useEffect, useState, useCallback, useMemo, memo } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import i18next from "i18next";

import styles from "../scss/components/catalogSectionDetails.module.scss";
import CatalogResultNotFound from "./CatalogResultNotFound";
import Spinner from "./Spinner"; // Импортируем компонент Spinner

const CatalogSectionDetails = memo(({ chapter, name }) => {
  const chapterId = useMemo(() => chapter?.data.id || "", [chapter]);
  const currentLanguage = i18next.language;

  const [sectionData, setSectionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchData = useCallback(
    (page = 1) => {
      if (sectionData.length === 0) {
        axios
          .get(
            `https://api.prodcomfit.md/api/products-sections?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=name&fields[1]=roName&populate[image][fields][0]=name&populate[image][fields][1]=url&populate[product_shapters][fields][0]=id`
          )
          .then((res) => {
            const products = res.data.data;
            if (products.length > 0) {
              setSectionData((prevData) => [...prevData, ...products]);
              fetchData(page + 1); // Рекурсивный вызов с увеличением номера страницы
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            console.error("Ошибка при запросе данных:", error);
            setLoading(false);
          });
      }
    },
    [sectionData.length]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const filteredSections = useMemo(() => {
    return sectionData.filter((section) =>
      section.attributes.product_shapters.data.some(
        (chap) => chap.id === chapterId
      )
    );
  }, [sectionData, chapterId]);

  const title = useMemo(() => {
    return currentLanguage === "ru"
      ? chapter?.data?.attributes?.name
      : currentLanguage === "ro"
      ? chapter?.data?.attributes?.roName
      : "";
  }, [chapter, currentLanguage]);

  if (loading) {
    return <Spinner />;
  }

  if (filteredSections.length === 0) {
    return <CatalogResultNotFound />;
  }

  return (
    <div className={styles.catalogSectionDetails}>
      <div className="container">
        <div className={styles.catalogSectionDetails__wrapper}>
          <p className={styles.title}>{title}</p>
          <div className={styles.items}>
            {filteredSections.map((section) => {
              return (
                <Link to={`/catalog/section/${section.id}`} key={section.id}>
                  <div className={styles.item}>
                    <div className={styles.img}>
                      <img
                        src={`https://api.prodcomfit.md${section.attributes?.image?.data?.attributes?.url}`}
                        alt={section.attributes.name}
                      />
                    </div>
                    <p>
                      {currentLanguage === "ru"
                        ? section?.attributes?.name
                        : currentLanguage === "ro"
                        ? section?.attributes?.roName
                        : ""}
                    </p>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
});

export default CatalogSectionDetails;
