import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "../scss/components/orderItems.module.scss";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useDebounce } from "use-debounce";
import { useNavigate } from "react-router-dom";
const OrderItems = ({ orderData, token }) => {
  const { t } = useTranslation();
  const currentLanguage = i18next.language;
  const navigate = useNavigate();
  const options = {
    true: t("admin.field.sent"),
    false: t("admin.field.waiting"),
  };

  const [data, setData] = useState([] || {});
  const [saveDataConfirm, setSaveDataConfirm] = useState(false);
  const [deleteDataConfirm, setDeleteDataConfirm] = useState(false);
  const [addItem, setAddItem] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [editedProduct, setEditedProduct] = useState(selectedProduct || {});
  const [isProductFound, setIsProductFound] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const attributes = orderData?.data?.attributes || {};
  const [productsData, setProductsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/products?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=article&fields[1]=roTitle&fields[2]=title&fields[3]=price`
        )
        .then((res) => {
          const products = res.data.data;
          if (products.length > 0) {
            setProductsData((prevData) => [...prevData, ...products]);
            fetchData(page + 1); // Рекурсивный вызов с увеличением номера страницы
          } else {
            setIsLoading(false); // Завершаем загрузку, когда больше нет данных
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
          setIsLoading(false);
        });
    };

    fetchData();
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [foundProduct, setFoundProduct] = useState(null);
  const [foundProductPriceDiscount, setFoundProductPriceDiscount] = useState(0);
  const foundProductDiscountValue = foundProductPriceDiscount.toString();
  const debouncedDiscountValue = useDebounce(foundProductDiscountValue, 1000);
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const [foundProductQuantity, setFoundProductQuantity] = useState("1");
  const debouncedFoundProductQuantity = useDebounce(foundProductQuantity, 1000);
  const [foundProductTotalPrice, setFoundProductTotalPrice] = useState(0);
  const [isProductNotFound, setIsProductNotFound] = useState(false);
  const [isAddProductNotFound, setIsAddProductNotFound] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState(null);
  useEffect(() => {
    const searchProduct = async () => {
      // Check if the user has entered a search term
      if (debouncedSearchTerm[0] && Array.isArray(productsData)) {
        const found = productsData.find(
          (product) =>
            String(product?.attributes?.article).toLowerCase() ===
            String(debouncedSearchTerm[0]).toLowerCase()
        );

        setFoundProduct(found);
        setIsAddProductNotFound(!found);
      }
    };
    setFoundProduct(null);
    searchProduct();
  }, [debouncedSearchTerm, productsData]);

  const handleAddInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };
  useEffect(() => {}, [debouncedDiscountValue]);
  const handleDiscountChange = (event) => {
    const value = parseFloat(event.target.value);
    setFoundProductPriceDiscount(isNaN(value) ? 0 : value);
  };
  const foundProductDiscountedPrice = foundProduct
    ? isNaN(parseFloat(foundProduct.attributes.price))
      ? 0
      : parseFloat(foundProduct.attributes.price) -
        (parseFloat(foundProduct.attributes.price) *
          foundProductPriceDiscount) /
          100
    : 0;
  const handleFoundProductQuantityChange = (event) => {
    const value = event.target.valueAsNumber || event.target.value;
    const newValue = value === undefined || value === "" ? 1 : value;
    setFoundProductQuantity(newValue);
  };
  useEffect(() => {}, [debouncedFoundProductQuantity]);
  const calculateTotalPrice = () => {
    if (foundProduct) {
      const price = parseFloat(foundProduct.attributes.price) || 0;
      const discountedPrice =
        price - (price * foundProductPriceDiscount) / 100 || 0;
      const quantity = foundProductQuantity || 1;

      let total = discountedPrice * quantity;
      total = parseFloat(total.toFixed(2));

      setFoundProductTotalPrice(total);
    } else {
      setFoundProductTotalPrice(0);
    }
  };
  useEffect(() => {
    calculateTotalPrice();
  }, [foundProduct, foundProductPriceDiscount, foundProductQuantity]);
  const {
    adminComments,
    city,
    citySelect,
    comment,
    createdAt,
    delivery,
    dispatched,
    email,
    name,
    number,
    payment,
    postalIndex,
    products,
    streetHouseApartment,
    updatedAt,
    usedPromocode,
    usedPromocodeDiscount,
    usedPromocodeDiscountPrice,
  } = attributes;
  const orderId = orderData?.data?.id;
  const createdAtValue =
    createdAt !== null && createdAt !== undefined ? createdAt : "";
  const productsValue =
    products !== null && products !== undefined ? products : "";
  const updatedAtValue =
    updatedAt !== null && updatedAt !== undefined ? updatedAt : "";

  // Дополнительное состояние для хранения наибольшей даты с учетом updatedAtValue
  const [latestDateConsideringUpdatedAt, setLatestDateConsideringUpdatedAt] =
    useState("");
  const [latestPublishedAtFormatted, setLatestPublishedAtFormatted] =
    useState("");

  useEffect(() => {
    if (productsValue && Array.isArray(productsValue.data)) {
      const latestPublishedAt = productsValue.data.reduce((latest, product) => {
        const productPublishedAt = new Date(product.attributes.publishedAt);
        return productPublishedAt > latest ? productPublishedAt : latest;
      }, new Date(0));

      const formattedPublishedAt = latestPublishedAt.toISOString();
      setLatestPublishedAtFormatted(formattedPublishedAt);
      /* console.log("Самая поздняя дата publishedAt:", formattedPublishedAt);
       */
      // Сравниваем наибольшую publishedAt с updatedAtValue
      const latestDateWithUpdatedAt = new Date(
        Math.max(
          latestPublishedAt.getTime(),
          new Date(updatedAtValue).getTime()
        )
      ).toISOString();

      // Обновляем состояние для наибольшей даты с учетом updatedAtValue
      setLatestDateConsideringUpdatedAt(latestDateWithUpdatedAt);
      /*  console.log(
        "Наибольшая дата с учетом updatedAtValue:",
        latestDateWithUpdatedAt
      ); */
    }
  }, [productsValue, updatedAtValue]);

  const [dispatchedValue, setDispatchedValue] = useState(
    dispatched !== undefined ? dispatched : false
  );
  const getDisplayValue = (value) => {
    if (value !== undefined) {
      return options[value.toString()];
    }
    return options["false"];
  };
  const handleDispatcValueChange = (event) => {
    setIsChanged(true);
    const selectedValue = event.target.value;
    const value = selectedValue === options.true;
    setDispatchedValue(value);
  };
  useEffect(() => {
    setDispatchedValue(dispatched !== undefined ? dispatched : false);
  }, [dispatched]);

  const [nameValue, setNameValue] = useState(
    name !== null && name !== undefined ? name : ""
  );
  const [numberValue, setNumberValue] = useState(
    number !== null && number !== undefined ? number : ""
  );
  const [emailValue, setEmailValue] = useState(
    email !== null && email !== undefined ? email : ""
  );
  const [citySelectValue, setCitySelectValue] = useState(
    citySelect !== null && citySelect !== undefined ? citySelect : ""
  );
  const [cityValue, setCityValue] = useState(
    city !== null && city !== undefined ? city : ""
  );
  const [streetHouseApartmentValue, setStreetHouseApartmentValue] = useState(
    streetHouseApartment !== null && streetHouseApartment !== undefined
      ? streetHouseApartment
      : ""
  );
  const [postalIndexValue, setPostalIndexValue] = useState(
    postalIndex !== null && postalIndex !== undefined ? postalIndex : ""
  );
  const [deliveryValue, setDeliveryValue] = useState(
    delivery !== null && delivery !== undefined ? delivery : ""
  );
  const [usedPromocodeValue, setUsedPromocodeValue] = useState(
    usedPromocode !== null && usedPromocode !== undefined ? usedPromocode : ""
  );
  const [usedPromocodeDiscountValue, setUsedPromocodeDiscountValue] = useState(
    usedPromocodeDiscount !== null && usedPromocodeDiscount !== undefined
      ? usedPromocodeDiscount
      : 0
  );
  const [usedPromocodeDiscountPriceValue, setUsedPromocodeDiscountPriceValue] =
    useState(
      usedPromocodeDiscountPrice !== null &&
        usedPromocodeDiscountPrice !== undefined
        ? usedPromocodeDiscountPrice
        : ""
    );
  const [paymentValue, setPaymentValue] = useState(
    payment !== null && payment !== undefined ? payment : ""
  );
  const [commentValue, setCommentValue] = useState(
    comment !== null && comment !== undefined ? comment : ""
  );
  const [adminCommentsValue, setAdminCommentsValue] = useState(
    adminComments !== null && adminComments !== undefined ? adminComments : ""
  );

  useEffect(() => {
    setNameValue(name || "");
    setEmailValue(email || "");
    setNumberValue(number || "");
    setCitySelectValue(citySelect || "");
    setCityValue(city || "");
    setStreetHouseApartmentValue(streetHouseApartment || "");
    setPostalIndexValue(postalIndex || "");
    setDeliveryValue(delivery || "");
    setUsedPromocodeValue(usedPromocode || "");
    setUsedPromocodeDiscountValue(usedPromocodeDiscount || "");
    setUsedPromocodeDiscountPriceValue(usedPromocodeDiscountPrice || "");
    setPaymentValue(payment || "");
    setCommentValue(comment || "");
    setAdminCommentsValue(adminComments || "");
  }, [
    name,
    email,
    number,
    citySelect,
    city,
    streetHouseApartment,
    postalIndex,
    delivery,
    usedPromocode,
    usedPromocodeDiscount,
    usedPromocodeDiscountPrice,
    payment,
    comment,
    adminComments,
  ]);
  const handleChange = (e) => {
    setIsChanged(true);
    const { name, value } = e.target;
    switch (name) {
      case "dispatched":
        setDispatchedValue(value);
        break;
      case "name":
        setNameValue(value);
        break;
      case "email":
        setEmailValue(value);
        break;
      case "number":
        setNumberValue(value);
        break;
      case "citySelect":
        setCitySelectValue(value);
        break;
      case "city":
        setCityValue(value);
        break;
      case "streetHouseApartment":
        setStreetHouseApartmentValue(value);
        break;
      case "postalIndex":
        setPostalIndexValue(value);
        break;
      case "delivery":
        setDeliveryValue(value);
        break;
      case "usedPromocode":
        setUsedPromocodeValue(value);
        break;
      case "usedPromocodeDiscount":
        setUsedPromocodeDiscountValue(value);
        break;
      case "usedPromocodeDiscountPrice":
        setUsedPromocodeDiscountPriceValue(value);
        break;
      case "payment":
        setPaymentValue(value);
        break;
      case "comment":
        setCommentValue(value);
        break;
      case "adminComments":
        setAdminCommentsValue(value);
        break;
      // ... и так далее для каждого поля
      default:
        break;
    }
  };
  const handleAddItemToggle = () => {
    setAddItem(!addItem);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const searchProductByArticle = (article) => {
    const foundProduct = productsData.find(
      (product) =>
        product.attributes.article.toLowerCase() === article.toLowerCase()
    );

    if (foundProduct) {
      setIsProductNotFound(false);
      setEditedProduct((prevProduct) => {
        const updatedProduct = {
          ...prevProduct,
          ...foundProduct.attributes,
          article: foundProduct.attributes.article,
          ruName: foundProduct.attributes.title,
          roName: foundProduct.attributes.roTitle,
        };

        // Пересчет discountPrice и pricePosition
        const price = parseFloat(updatedProduct.price) || 0;
        const discount = parseFloat(updatedProduct.discount) || 0;
        const quantity = parseFloat(updatedProduct.quantity) || 1;

        const discountPrice = price - (price * discount) / 100;
        const pricePosition = discountPrice * quantity;

        return {
          ...updatedProduct,
          discountPrice: discountPrice.toFixed(2),
          pricePosition: pricePosition.toFixed(2),
        };
      });
    } else {
      setIsProductNotFound(true);
    }
  };

  useEffect(() => {
    if (productsValue && Array.isArray(productsValue.data)) {
      const newData = productsValue.data.map((item) => {
        if (item && item.attributes) {
          const {
            article,
            price,
            ruName,
            roName,
            discount,
            discountPrice,
            quantity,
            pricePosition,
          } = item.attributes;

          return {
            id: item.id, // Extract id from the parent object
            article,
            price,
            ruName,
            roName,
            discount: discount,
            discountPrice: discountPrice,
            quantity: quantity,
            pricePosition: pricePosition,
          };
        }
        return null;
      });

      setData(newData);
    }
  }, [productsValue]);

  const handleAddProduct = () => {
    // Ваш код для создания productPostData
    const productPostData = {
      data: {
        article: foundProduct?.attributes?.article,
        price: foundProduct?.attributes?.price,
        ruName: foundProduct?.attributes?.title,
        roName: foundProduct?.attributes?.roTitle,
        discount: foundProductDiscountValue,
        discountPrice: foundProductDiscountedPrice,
        quantity: foundProductQuantity,
        pricePosition: foundProductTotalPrice,
      },
    };

    // Обновление productsValue с добавлением нового товара
    setData((prevData) => {
      const updatedData = Array.isArray(prevData)
        ? [...prevData, productPostData.data]
        : [productPostData.data];
      return updatedData;
    });
    setIsChanged(true);
    setFoundProduct(null);
    setFoundProductQuantity("1");
    setFoundProductPriceDiscount(0);
    setAddItem(false);
  };
  const handleDeleteProduct = (index) => {
    setData((prevData) => {
      setIsChanged(true);
      // Создайте новый массив, исключив элемент с указанным индексом
      const updatedData = [...prevData];
      updatedData.splice(index, 1);
      return updatedData;
    });
  };
  useEffect(() => {
    setEditedProduct(data || {});
  }, [selectedProduct]);
  const handleEditItemOpen = (index) => {
    if (index >= 0 && index < data.length) {
      const selectedProductValue = data[index];
      console.log("Selected Product:", selectedProductValue);
      setIsEditing(true);
      setSelectedProduct(selectedProductValue);
      setSelectedProductIndex(index);
      setEditedProduct(selectedProductValue);
      setEditItem(true);
    } else {
      /*   console.error("Invalid index:", index); */
      // Обработка случая, когда индекс недействителен
    }
  };
  const cancelEditing = () => {
    setIsEditing(false);
    setEditedProduct(data || {});
    setEditItem(false);
  };
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;

    // Проверяем, является ли поле числовым (за исключением поля 'article')
    const isNumericField = ["price", "discount", "quantity"].includes(name);
    const updatedValue = isNumericField ? parseFloat(value) || 0 : value;

    setEditedProduct((data) => {
      // Обновляем текущее значение
      const updatedProduct = { ...data, [name]: updatedValue };

      // Если поле является артикулом, выполняем поиск продукта
      if (name === "article") {
        searchProductByArticle(value);
      }

      // Пересчитываем цены, если изменились числовые поля
      if (isNumericField) {
        const price = parseFloat(updatedProduct.price) || 0;
        const discount = parseFloat(updatedProduct.discount) || 0;
        const quantity = parseFloat(updatedProduct.quantity) || 1;

        const discountPrice = price - (price * discount) / 100;
        const pricePosition = discountPrice * quantity;

        return {
          ...updatedProduct,
          discountPrice: discountPrice.toFixed(2),
          pricePosition: pricePosition.toFixed(2),
        };
      }

      return updatedProduct;
    });
  };
  const handleEditItemClose = (index) => {
    setEditItem(false);
    setEditedProduct([]);
    setIsProductNotFound(false);
  };
  const handleEditProduct = async () => {
    if (selectedProductIndex === null || selectedProductIndex === undefined) {
      console.error("No product index specified for editing");
      return;
    }

    const updatedData = [...data];

    if (
      selectedProductIndex >= 0 &&
      selectedProductIndex < updatedData.length
    ) {
      // Проверяем, что editedProduct существует и имеет свойство 'article'
      if (editedProduct && "article" in editedProduct) {
        // Обновляем данные в копии массива по индексу
        const updatedProduct = {
          article: editedProduct.article || "",
          price: editedProduct.price || 0,
          ruName: editedProduct.ruName || "",
          roName: editedProduct.roName || "",
          discount: editedProduct.discount || 0,
          discountPrice: editedProduct.discountPrice || 0,
          quantity: editedProduct.quantity || 0,
          pricePosition: editedProduct.pricePosition || 0,
        };

        updatedData[selectedProductIndex] = {
          ...updatedData[selectedProductIndex],
          ...updatedProduct,
        };

        // Обновляем состояние data с помощью обновленной копии массива
        setData(updatedData);
        setIsChanged(true);
        // Сбрасываем переменную isEditing в false после завершения редактирования
        setIsEditing(false);
      } else {
        console.error("The 'article' property does not exist in editedProduct");
      }
    } else {
      console.error("Product index is out of bounds");
    }

    setEditItem(false);
    setEditedProduct([]);
    setIsProductNotFound(false);
    setSelectedProduct(null);
  };
  useEffect(() => {
    if (selectedProduct) {
      setEditedProduct(selectedProduct);
    }
  }, [selectedProduct]);
  const handleSave = async () => {
    const discountPrice = Number(usedPromocodeDiscountPriceValue) || 0;

    // Объявление переменной usedPromocodeDiscountValue
    let usedPromocodeDiscountValue;
    // Преобразуйте usedPromocodeDiscountValue в число, если это возможно
    if (usedPromocodeDiscountValue !== undefined) {
      usedPromocodeDiscountValue = Number(usedPromocodeDiscountValue);
    }
    // Проверка на isNaN (is Not a Number)
    if (isNaN(usedPromocodeDiscountValue)) {
      usedPromocodeDiscountValue = 0;
    }
    const orderPutData = {
      data: {
        dispatched: dispatchedValue,
        name: nameValue,
        number: numberValue,
        email: emailValue,
        citySelect: citySelectValue,
        city: cityValue,
        streetHouseApartment: streetHouseApartmentValue,
        postalIndex: postalIndexValue,
        delivery: deliveryValue,
        usedPromocode: usedPromocodeValue,
        usedPromocodeDiscount: usedPromocodeDiscountValue,
        usedPromocodeDiscountPrice: discountPrice,
        payment: paymentValue,
        comment: commentValue,
        adminComments: adminCommentsValue,
      },
    };

    // Обновление данных заказа
    try {
      await axios.put(
        `https://api.prodcomfit.md/api/orders/${orderId}`,
        orderPutData, // Переносим данные заказа в тело запроса
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsChanged(false);
    } catch (error) {
      console.error("Ошибка при редактировании заказа:", error);
      console.log("Response data:", error.response.data);
      console.log("Response status:", error.response.status);
      console.log("Error details:", error.response.data.error);
    }

    // Проверка наличия массива товаров перед их обработкой
    if (data && Array.isArray(data) && data.length >= 0) {
      // Сравнение элементов в data с теми, что есть на сервере (productsValue.data)
      const itemsToRemove = productsValue.data.filter(
        (serverItem) =>
          !data.some((updatedItem) => updatedItem.id === serverItem.id)
      );

      // Удаление элементов, которые отсутствуют в data, но есть на сервере
      for (const itemToRemove of itemsToRemove) {
        try {
          await axios.delete(
            `https://api.prodcomfit.md/api/order-products/${itemToRemove.id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } catch (error) {
          console.error("Ошибка при удалении товара:", error);
        }
      }

      // Обработка каждого товара в data
      for (const item of data) {
        const productData = {
          data: {
            article: item.article,
            roName: item.roName,
            ruName: item.ruName,
            price: item.price,
            discount: item.discount,
            discountPrice: item.discountPrice,
            quantity: item.quantity,
            pricePosition: item.pricePosition,
            order: orderId,
          },
        };

        // Проверка наличия идентификатора товара для определения запроса PUT или POST
        if (item.id) {
          // Если товар не найден в productsValue.data, удалить его
          if (!productsValue.data.some((newItem) => newItem.id === item.id)) {
            try {
              // Удаление товара на сервере
              await axios.delete(
                `https://api.prodcomfit.md/api/order-products/${item.id}`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
            } catch (error) {
              console.error("Ошибка при удалении товара:", error);
            }
          } else {
            // Если товар найден, обновить его с использованием PUT
            try {
              await axios.put(
                `https://api.prodcomfit.md/api/order-products/${item.id}`,

                productData,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
            } catch (error) {
              console.error("Ошибка при редактировании товара:", error);
            }
          }
        } else {
          // Если у товара нет идентификатора, значит, это новый товар, используйте POST
          try {
            await axios.post(
              `https://api.prodcomfit.md/api/order-products`,
              productData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } catch (error) {
            console.error("Ошибка при добавлении нового товара:", error);
          }
        }
      }
    } else {
      console.error("Товары не являются массивом или пустыми:", data);
    }

    setFoundProduct(null);
    setFoundProductQuantity("1");
    setFoundProductPriceDiscount(0);
    setSaveDataConfirm(false);
  };
  const handleOrderDelete = async () => {
    try {
      // Получаем все товары из заказа
      const orderProducts = productsValue?.data || [];

      // Удаляем каждый товар из заказа
      for (const itemToRemove of orderProducts) {
        await axios.delete(
          `https://api.prodcomfit.md/api/order-products/${itemToRemove.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      // Удаляем сам заказ
      await axios.delete(`https://api.prodcomfit.md/api/orders/${orderId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Дополнительные действия после успешного удаления заказа
      /*      console.log(`Заказ с ID ${orderId} и все его товары успешно удалены.`); */
    } catch (error) {
      console.error("Ошибка при удалении заказа и его товаров:", error);
    }
    navigate(`/admin`);
  };
  const handleSaveOrderConfirmOpen = () => {
    setSaveDataConfirm(true);
  };
  const handleSaveOrderConfirmCancel = () => {
    setSaveDataConfirm(false);
  };

  const handleSaveOrderConfirmNotSave = () => {
    setSaveDataConfirm(false);
    setData(productsValue.data);
    setNameValue(name !== null && name !== undefined ? name : "");
    setNumberValue(number !== null && number !== undefined ? number : "");
    setEmailValue(email !== null && email !== undefined ? email : "");
    setCitySelectValue(
      citySelect !== null && citySelect !== undefined ? citySelect : ""
    );
    setCityValue(city !== null && city !== undefined ? city : "");
    setStreetHouseApartmentValue(
      streetHouseApartment !== null && streetHouseApartment !== undefined
        ? streetHouseApartment
        : ""
    );
    setPostalIndexValue(
      postalIndex !== null && postalIndex !== undefined ? postalIndex : ""
    );
    setDeliveryValue(
      delivery !== null && delivery !== undefined ? delivery : ""
    );
    setUsedPromocodeValue(
      usedPromocode !== null && usedPromocode !== undefined ? usedPromocode : ""
    );
    setUsedPromocodeDiscountValue(
      usedPromocodeDiscount !== null && usedPromocodeDiscount !== undefined
        ? usedPromocodeDiscount
        : 0
    );
    setUsedPromocodeDiscountPriceValue(
      usedPromocodeDiscountPrice !== null &&
        usedPromocodeDiscountPrice !== undefined
        ? usedPromocodeDiscountPrice
        : ""
    );
    setPaymentValue(payment !== null && payment !== undefined ? payment : "");
    setCommentValue(comment !== null && comment !== undefined ? comment : "");
    setAdminCommentsValue(
      adminComments !== null && adminComments !== undefined ? adminComments : ""
    );
    navigate(`/admin`);
  };
  const handleDeleteOrderConfirmOpen = () => {
    setDeleteDataConfirm(true);
  };
  const handleDeleteOrderConfirmClose = () => {
    setDeleteDataConfirm(false);
  };

  const mappedRecords = Array.isArray(data)
    ? data.map((item, index) => {
        return (
          <tr key={index}>
            <td className={`${styles.stock} ${styles["sticky-left"]}`}>
              {item && item.article ? item.article : ""}
            </td>
            <td>
              {item && (currentLanguage === "ru" ? item.ruName : item.roName)}
            </td>
            <td>{item && item.price}</td>
            <td>{item && item.discount}</td>
            <td>{item && item.discountPrice}</td>
            <td>{item && item.quantity}</td>
            <td>{item && item.pricePosition ? item.pricePosition : 0}</td>
            <td className={styles["sticky-right"]}>
              <button onClick={() => handleDeleteProduct(index)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="14"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="red"
                    d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                  />
                </svg>
              </button>
              <button
                onClick={() => handleEditItemOpen(index)}
                className={`${styles.btn} ${styles["btn--primary"]}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="16"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="rgb(73, 69, 255)"
                    d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0-32-14.3-32-32s-14.3-32-32-32H96z"
                  />
                </svg>
              </button>
            </td>
          </tr>
        );
      })
    : null;

  return (
    <div className={styles.orderItems}>
      <div className={styles.orderItems__container}>
        <div className={styles.orderItems__wrapper}>
          <div className={styles.header}>
            <div className={styles.data}>
              <div>
                <p>{t("adminOrderProduct.orderNumber")}</p>
                <span> {orderId}</span>
              </div>
              <div>
                <p>{t("adminOrderProduct.createdAt")}</p>
                <span>
                  {new Date(createdAtValue).toLocaleDateString("ru-RU")}
                </span>
              </div>
              <div>
                <p>{t("adminOrderProduct.updatedAt")}</p>
                <span>
                  {latestDateConsideringUpdatedAt &&
                    new Date(latestDateConsideringUpdatedAt).toLocaleDateString(
                      "ru-RU"
                    )}
                </span>
              </div>
            </div>
            <div className={styles.tools}>
              <div className={styles.stateChange}>
                <select
                  value={getDisplayValue(dispatchedValue)}
                  onChange={handleDispatcValueChange}
                  className="your-select-style"
                >
                  {Object.entries(options).map(([key, value]) => (
                    <option key={key} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>

              <button
                className={`${styles.saveData} ${
                  !isChanged ? styles.disabled : ""
                }`}
                disabled={!isChanged}
                onClick={handleSaveOrderConfirmOpen}
              >
                {t("adminOrderProduct.textSave")}
              </button>
              <button
                onClick={handleDeleteOrderConfirmOpen}
                className={styles.deleteData}
              >
                {t("adminOrderProduct.textDelete")}
              </button>
            </div>
          </div>
          <div className={styles.information}>
            <div className={styles.information__header}>
              <div>
                <p>{t("admin.field.name")}</p>
                <input
                  type="text"
                  name="name"
                  value={nameValue}
                  onChange={handleChange}
                />
              </div>
              <div>
                <p>{t("admin.field.number")}</p>
                <input
                  type="text"
                  name="number"
                  onChange={handleChange}
                  value={numberValue}
                />
              </div>
              <div>
                <p>E-mail</p>
                <input
                  type="text"
                  name="email"
                  value={emailValue}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={styles.information__main}>
              <div className={styles.information__mainInf}>
                <div className={styles.information__mainInf__items}>
                  <div>
                    <p>{t("admin.field.citySelect")}</p>
                    <input
                      type="text"
                      name="citySelect"
                      onChange={handleChange}
                      value={citySelectValue}
                    />
                  </div>
                  <div>
                    <p>{t("admin.field.city")}</p>
                    <input
                      type="text"
                      name="city"
                      onChange={handleChange}
                      value={cityValue}
                    />
                  </div>
                  <div>
                    <p>{t("admin.field.street")}</p>
                    <input
                      type="text"
                      name="streetHouseApartment"
                      onChange={handleChange}
                      value={streetHouseApartmentValue}
                    />
                  </div>

                  <div>
                    <p>{t("admin.field.postalIndex")}</p>
                    <input
                      type="text"
                      name="postalIndex"
                      onChange={handleChange}
                      value={postalIndexValue}
                    />
                  </div>
                  <div>
                    <p>{t("admin.field.delivery")}</p>
                    <input
                      type="text"
                      name="delivery"
                      onChange={handleChange}
                      value={deliveryValue}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.information__footer}>
              <div>
                <p>{t("admin.field.usedPromocode")}</p>
                <input
                  type="text"
                  name="usedPromocode"
                  onChange={handleChange}
                  value={usedPromocodeValue}
                />
              </div>
              <div>
                <p>{t("admin.field.usedPromocodeDiscount")}</p>
                <input
                  type="text"
                  name="usedPromocodeDiscount"
                  onChange={handleChange}
                  value={usedPromocodeDiscountValue}
                />
              </div>
              <div>
                <p>{t("admin.field.usedPromocodeDiscountPrice")}</p>
                <input
                  type="text"
                  name="usedPromocodeDiscountPrice"
                  onChange={handleChange}
                  value={usedPromocodeDiscountPriceValue}
                />
              </div>
              <div>
                <p>{t("admin.field.payment")}</p>
                <input
                  type="text"
                  name="payment"
                  onChange={handleChange}
                  value={paymentValue}
                />
              </div>
            </div>
          </div>
          <div className={styles["table-widget"]}>
            <div className={styles["table-wrapper"]}>
              <table>
                <thead className={styles.tableHeader}>
                  <tr>
                    <th className={styles["sticky-left"]}>
                      {t("adminOrderProduct.article")}
                    </th>
                    <th>{t("adminOrderProduct.name")}</th>
                    <th>{t("adminOrderProduct.price")}</th>
                    <th>{t("adminOrderProduct.discount")}</th>
                    <th>{t("adminOrderProduct.discountPrice")}</th>
                    <th>{t("adminOrderProduct.quantity")}</th>
                    <th>{t("adminOrderProduct.pricePosition")}</th>
                    <th className={styles["sticky-right"]}></th>
                  </tr>
                </thead>
                <tbody id="table-rows">{mappedRecords}</tbody>
              </table>
              <button className={styles.addProduct}>
                <svg
                  onClick={handleAddItemToggle}
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="16"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="rgb(73, 69, 255)"
                    d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className={styles.note}>
            <div>
              <p className={styles.noteTitle}>{t("admin.field.message")}</p>
              <textarea
                name="comment"
                cols="30"
                rows="10"
                value={commentValue}
                onChange={handleChange}
              ></textarea>
            </div>

            <div>
              <p className={styles.noteTitle}>{t("adminOrderProduct.note")}</p>
              <textarea
                value={adminCommentsValue}
                onChange={handleChange}
                placeholder={t("adminOrderProduct.notePlaceholder")}
                name="adminComments"
                cols="30"
                rows="10"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      {addItem && (
        <div>
          <div className={styles.modalOverlay} onClick={handleAddItemToggle} />

          <div className={styles.modalContent}>
            <svg
              onClick={handleAddItemToggle}
              className={styles.modalClose}
              xmlns="http://www.w3.org/2000/svg"
              height="16"
              width="16"
              viewBox="0 0 384 512"
            >
              <path
                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                fill="black"
              />
            </svg>

            <div className={styles.modalContent__items}>
              <div>
                <p>{t("adminOrderProduct.article")}</p>
                <input onChange={handleAddInputChange} type="text" />
                {isAddProductNotFound && (
                  <p className={styles.texNotFound}>
                    {t("adminOrderProduct.texNotFound")}
                  </p>
                )}
              </div>
              {foundProduct && (
                <>
                  <div>
                    <p>{t("adminOrderProduct.name")}</p>
                    <input
                      readOnly
                      type="text"
                      value={foundProduct.attributes.title}
                    />
                  </div>
                  <div>
                    <p>{t("adminOrderProduct.name")}</p>
                    <input
                      readOnly
                      type="text"
                      value={foundProduct.attributes.roTitle}
                    />
                  </div>
                  <div>
                    <p>{t("adminOrderProduct.price")}</p>
                    <input
                      readOnly
                      type="text"
                      value={foundProduct.attributes.price}
                    />
                  </div>
                </>
              )}

              <div>
                <p>{t("adminOrderProduct.discount")}</p>
                <input
                  value={foundProductPriceDiscount}
                  onChange={handleDiscountChange}
                  type="number"
                />
              </div>
              <div>
                <p>{t("adminOrderProduct.discountPrice")}</p>
                <input
                  readOnly
                  value={
                    isNaN(foundProductDiscountedPrice)
                      ? 0
                      : foundProductDiscountedPrice.toFixed(2)
                  }
                  type="number"
                />
              </div>
              <div>
                <p>{t("adminOrderProduct.quantity")}</p>
                <input
                  value={foundProductQuantity}
                  onChange={handleFoundProductQuantityChange}
                  type="number"
                />
              </div>
              <div>
                <p>{t("adminOrderProduct.pricePosition")}</p>
                <input readOnly value={foundProductTotalPrice} type="text" />
              </div>
            </div>
            <div className={styles.modalTools}>
              <button
                /*   onClick={() => handleAddProduct([productPostData], orderId)} */
                onClick={handleAddProduct}
                className={`${styles.saveData} ${
                  !foundProduct?.attributes?.article ? styles.disabled : ""
                }`}
                disabled={!foundProduct?.attributes?.article}
              >
                {t("adminOrderProduct.textSave")}
              </button>
            </div>
          </div>
        </div>
      )}
      {editItem && (
        <div>
          <div className={styles.modalOverlay} onClick={handleEditItemClose} />

          <div className={styles.modalContent}>
            <svg
              onClick={handleEditItemClose}
              className={styles.modalClose}
              xmlns="http://www.w3.org/2000/svg"
              height="16"
              width="16"
              viewBox="0 0 384 512"
            >
              <path
                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                fill="black"
              />
            </svg>

            <div className={styles.modalContent__items}>
              <div>
                <p>{t("adminOrderProduct.article")}</p>
                <input
                  name="article"
                  type="text"
                  value={editedProduct.article || ""}
                  onChange={handleEditInputChange}
                />
                {isProductNotFound && (
                  <p className={styles.texNotFound}>
                    {t("adminOrderProduct.texNotFound")}
                  </p>
                )}
              </div>
              <div>
                <p>{t("adminOrderProduct.name")}</p>
                <input
                  name="ruName"
                  type="text"
                  readOnly
                  value={editedProduct.ruName || ""}
                  onChange={handleEditInputChange}
                />
              </div>
              <div>
                <p>{t("adminOrderProduct.name")}</p>
                <input
                  name="roName"
                  type="text"
                  readOnly
                  value={editedProduct.roName || ""}
                  onChange={handleEditInputChange}
                />
              </div>
              <div>
                <p>{t("adminOrderProduct.price")}</p>
                <input
                  name="price"
                  type="number"
                  readOnly
                  value={editedProduct.price || ""}
                  onChange={handleEditInputChange}
                />
              </div>
              <div>
                <p>{t("adminOrderProduct.discount")}</p>
                <input
                  name="discount"
                  type="number"
                  value={editedProduct.discount || "0"}
                  onChange={handleEditInputChange}
                />
              </div>
              <div>
                <p>{t("adminOrderProduct.discountPrice")}</p>
                <input
                  name="discountPrice"
                  type="number"
                  readOnly
                  value={editedProduct.discountPrice}
                  onChange={handleEditInputChange}
                />
              </div>
              <div>
                <p>{t("adminOrderProduct.quantity")}</p>
                <input
                  name="quantity"
                  type="number"
                  value={editedProduct.quantity || "1"}
                  onChange={handleEditInputChange}
                />
              </div>
              <div>
                <p>{t("adminOrderProduct.pricePosition")}</p>
                <input
                  name="pricePosition"
                  type="number"
                  readOnly
                  value={editedProduct.pricePosition || ""}
                  onChange={handleEditInputChange}
                />
              </div>
            </div>
            <div className={styles.modalTools}>
              <button onClick={cancelEditing} className={styles.deleteData}>
                {t("adminOrderProduct.textCancel")}
              </button>

              <button
                className={`${styles.saveData} ${
                  isProductNotFound ? styles.disabled : ""
                }`}
                disabled={isProductNotFound}
                onClick={handleEditProduct}
              >
                {t("adminOrderProduct.textSave")}
              </button>
            </div>
          </div>
        </div>
      )}
      {saveDataConfirm && (
        <>
          <div
            onClick={handleSaveOrderConfirmCancel}
            className={styles.confirmOverlay}
          ></div>
          <div className={styles.confirmContent}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 512 512"
            >
              <path
                fill="#FFD43B"
                d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
              />
            </svg>

            <p className={styles.confirmTitle}>
              {t("adminOrderProduct.textSaveOrderConfirm")}
            </p>
            <p className={styles.confirmDescriptions}>
              {t("adminOrderProduct.textSaveOrderConfirmDescription")}
            </p>

            <div className={styles.confirmBtn}>
              <button onClick={handleSave} className={styles.saveData}>
                {t("adminOrderProduct.textYes")}
              </button>
              <button
                onClick={handleSaveOrderConfirmNotSave}
                className={styles.deleteData}
              >
                {t("adminOrderProduct.textNo")}
              </button>
              <button
                onClick={handleSaveOrderConfirmCancel}
                className={styles.cancelData}
              >
                {t("adminOrderProduct.textCancel")}
              </button>
            </div>
          </div>
        </>
      )}
      {deleteDataConfirm && (
        <>
          <div
            onClick={handleDeleteOrderConfirmClose}
            className={styles.confirmOverlay}
          ></div>
          <div className={styles.confirmContent}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 512 512"
            >
              <path
                fill="#FFD43B"
                d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
              />
            </svg>

            <p className={styles.confirmTitle}>
              {t("adminOrderProduct.textDeleteOrderConfirm")}
            </p>
            <p className={styles.confirmDescriptions}>
              {t("adminOrderProduct.textDeleteOrderConfirmDescription")}
            </p>

            <div className={styles.confirmBtn}>
              <button onClick={handleOrderDelete} className={styles.saveData}>
                {t("adminOrderProduct.textYes")}
              </button>
              <button
                onClick={handleDeleteOrderConfirmClose}
                className={styles.deleteData}
              >
                {t("adminOrderProduct.textNo")}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OrderItems;
