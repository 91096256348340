import React, { useState } from "react";
import axios from "axios";
import styles from "../scss/components/contactUs.module.scss";
import image from "../assets/contactUs.png";
import ellipsFirst from "../assets/ContactUsEllips1.png";
import ellipsSecond from "../assets/ContactUsEllips2.png";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    data: {
      name: "",
      email: "",
      number: "",
      message: "",
      response: false,
    },
    errors: {
      name: false,
      number: false,
      email: false,
      message: false,
    },
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      data: {
        ...formData.data,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        [name]: false, // Сбрасываем ошибку при изменении поля
      },
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Проверка на заполнение всех полей
    if (!formData.data.name || !formData.data.email || !formData.data.message) {
      // Устанавливаем ошибки для незаполненных полей
      setFormData({
        ...formData,
        errors: {
          name: !formData.data.name,
          number: !formData.data.number,
          email: !formData.data.email,
          message: !formData.data.message,
        },
      });
      return;
    }

    // Создаем объект данных для отправки на сервер
    const postData = {
      data: {
        name: formData.data.name,
        number: formData.data.number,
        email: formData.data.email,
        message: formData.data.message,
      },
    };

    // Выполняем POST-запрос с использованием Axios
    axios
      .post("https://api.prodcomfit.md/api/feedbacks", postData)
      .then((response) => {
        // Обрабатываем успешный ответ здесь
        /*  console.log("Успешный POST-запрос:", response.data); */

        // Очищаем поля формы после успешной отправки
        setFormData({
          data: {
            name: "",
            number: "",
            email: "",
            message: "",
            response: false,
          },
          errors: {
            name: false,
            number: false,
            email: false,
            message: false,
          },
        });
      })
      .catch((error) => {
        // Обрабатываем любые ошибки, которые могли возникнуть во время запроса
        /*  console.error("Ошибка при выполнении POST-запроса:", error); */
      });
  };
  return (
    <div className={styles.contactUs}>
      <div className={styles.container}>
        <div className={styles.contactUs__wrapper}>
          <div className={styles.twoPart}>
            <div className={styles.image}>
              <img src={image} alt="image" />
            </div>
            <div className={styles.descriptions}>
              <div className={styles.description}>
                <p className={styles.title}>{t("contactsForm.title")}</p>
                <p className={styles.subtitle}>{t("contactsForm.subtitle")}</p>
              </div>
              <div className={styles.form}>
                <div className={styles.inputs}>
                  <input
                    type="text"
                    name="name"
                    placeholder={t("contactsForm.formField.name.id")}
                    value={formData.data.name}
                    onChange={handleChange}
                    className={formData.errors.name ? styles.errorInput : ""}
                  />

                  <input
                    type="text"
                    name="number"
                    placeholder={t("contactsForm.formField.name.number")}
                    value={formData.data.number}
                    onChange={handleChange}
                    className={formData.errors.number ? styles.errorInput : ""}
                  />

                  <input
                    type="text"
                    name="email"
                    placeholder="E-mail"
                    value={formData.data.email}
                    onChange={handleChange}
                    className={formData.errors.email ? styles.errorInput : ""}
                  />

                  <div className={styles.message}>
                    <textarea
                      name="message"
                      placeholder={t("contactsForm.formField.name.message")}
                      value={formData.data.message}
                      onChange={handleChange}
                      className={
                        formData.errors.message ? styles.errorInput : ""
                      }
                    />
                  </div>
                </div>
                <div className={styles.footer}>
                  <div className={styles.btn} onClick={handleSubmit}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                    >
                      <path
                        d="M0.869946 7.62844C0.653578 7.71496 0.508694 7.92102 0.500374 8.15387C0.492092 8.3868 0.622054 8.60262 0.831704 8.70427L7.72811 12.0489L19.6679 0.109375L0.869946 7.62844Z"
                        fill="white"
                      />
                      <path
                        d="M8.56055 12.8816L11.9053 19.778C12.0039 19.9814 12.2099 20.1097 12.4346 20.1097C12.4416 20.1097 12.4486 20.1096 12.4556 20.1093C12.6885 20.101 12.8946 19.9561 12.9812 19.7398L20.5003 0.941895L8.56055 12.8816Z"
                        fill="white"
                      />
                    </svg>
                    <p>{t("contactsForm.btn")}</p>
                  </div>
                  <p className={styles.note}>
                    *{t("contactsForm.description")}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.ellips}></div>
          </div>
        </div>
      </div>
      <img className={styles.ellipsFirst} src={ellipsFirst} alt="ellips" />
      <img className={styles.ellipsSecond} src={ellipsSecond} alt="ellips" />
    </div>
  );
};

export default ContactUs;
