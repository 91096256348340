import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import BlogPostCard from "./BlogPostCard";
import PaginationRounded from "./mui/Pagination";
import Spinner from "./Spinner";
import styles from "../scss/components/blogItems.module.scss";

const BlogItems = () => {
  const { t } = useTranslation();
  const currentLanguage = i18next.language;
  const [blogPostCard, setBlogPostCard] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("newest");
  const itemsPerPage = 6;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAllPages = async (url, page = 1, allData = []) => {
      try {
        const response = await axios.get(`${url}&pagination[page]=${page}&pagination[pageSize]=100`);
        const newData = response.data.data;
  
        if (newData.length === 0) {
          // Если на странице нет данных, возвращаем все собранные данные
          return allData;
        }
  
        // Объединяем новые данные с уже полученными
        const updatedData = [...allData, ...newData];
  
        // Рекурсивно вызываем функцию для следующей страницы
        return fetchAllPages(url, page + 1, updatedData);
      } catch (error) {
        console.error("Ошибка при запросе данных:", error);
        throw error;
      }
    };
  
    const fetchData = async () => {
      try {
        const data = await fetchAllPages(
          "https://api.prodcomfit.md/api/blogs?fields[0]=title&fields[1]=roTitle&fields[2]=date&fields[3]=subtitle&fields[4]=roSubtitle&fields[5]=date&populate[imagePostCard][fields][0]=name&populate[imagePostCard][fields][1]=url"
        );
        setBlogPostCard(data);
      } catch (error) {
        console.error("Ошибка при запросе данных:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  const handleSortChange = useCallback((newOrder) => {
    setSortOrder(newOrder);
  }, []);

  const sortByDate = useCallback(
    (a, b) => {
      const dateA = new Date(a.attributes?.date);
      const dateB = new Date(b.attributes?.date);
      return sortOrder === "newest" ? dateB - dateA : dateA - dateB;
    },
    [sortOrder]
  );

  const sortedItems = useMemo(() => {
    return [...blogPostCard].sort(sortByDate);
  }, [blogPostCard, sortByDate]);

  const paginatedItems = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return sortedItems.slice(indexOfFirstItem, indexOfLastItem);
  }, [sortedItems, currentPage, itemsPerPage]);

  const pageCount = useMemo(
    () => Math.ceil(blogPostCard.length / itemsPerPage),
    [blogPostCard.length, itemsPerPage]
  );

  const onPageChange = useCallback((event, newPage) => {
    setCurrentPage(newPage);
    // Добавляем скролл наверх страницы
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className={styles.blogItems}>
      <div className="container">
        <div className={styles.blogItems__wrapper}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <p className={styles.title}>{t("blog.title")}</p>
              <div className={styles.sort}>
                <p
                  onClick={() => handleSortChange("newest")}
                  className={sortOrder === "newest" ? styles.activeSort : ""}
                >
                  {t("blog.sort.new")}
                </p>
                <p
                  onClick={() => handleSortChange("oldest")}
                  className={sortOrder === "oldest" ? styles.activeSort : ""}
                >
                  {t("blog.sort.old")}
                </p>
              </div>

              <div className={styles.items}>
                {paginatedItems.map((post) => {
                  const postProps = {
                    key: post.id,
                    id: post.id,
                    imagePostCard:
                      post.attributes?.imagePostCard?.data[0].attributes?.url,
                    date: post.attributes?.date,
                    title:
                      currentLanguage === "ru"
                        ? post.attributes.title
                        : post.attributes.roTitle,
                    subtitle:
                      currentLanguage === "ru"
                        ? post.attributes.subtitle
                        : post.attributes.roSubtitle,
                  };
                  return <BlogPostCard {...postProps} />;
                })}
              </div>

              <div className={styles.pagination}>
                <PaginationRounded
                  className={styles.paginationPoint}
                  pageCount={pageCount}
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(BlogItems);
