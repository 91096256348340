import React from "react";
import styles from "../scss/components/bannerAboutUs.module.scss";
import { Link } from "react-router-dom";
import image from "../assets/aboutUs.jpg";
import background from "../assets/aboutUsBg.png";
import { useTranslation } from "react-i18next";

const BannerAboutUs = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.bannerAboutUs}>
      <div className="container">
        <div className={styles.twoPart}>
          <div className={styles.first}>
            <p className={styles.title}>{t("bannerAboutUs.title")} </p>
            <div className={styles.description}>
              <div>
                <img className={styles.logoMb} src={image} alt="logo" />

                <p>
                  <span>PRODCOM FIT ADAfer</span> —{" "}
                  {t("bannerAboutUs.description.firstWord")}
                </p>
              </div>
              <p>{t("bannerAboutUs.description.secondWord")}</p>
            </div>
            <Link to="/aboutUs" className={styles.btn}>
              <p>{t("bannerAboutUs.link")}</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
              >
                <circle cx="9.5" cy="9.5" r="9.5" fill="#8FC23D" />
                <path d="M12.399 9.19342H5.51562H12.399Z" fill="#8FC23D" />
                <path
                  d="M9.47592 6.12891L12.8705 9.19342L9.47592 12.2579M12.399 9.19342H5.51562"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          </div>
          <div className={styles.second}>
            <img
              className={styles.second__background}
              src={background}
              alt="background"
            />
            <div className={styles.contant}>
              <div className={styles.img}>
                <img src={image} alt="logo" />
              </div>
              <p className={styles.slogan}>{t("bannerAboutUs.subtitle")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerAboutUs;
