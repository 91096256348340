import React, { useState } from "react";
import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "./scss/app.scss";

import Home from "./components/pages/Home";
import Login from "./components/Login";
import Admin from "./components/pages/Admin";
import NewOrder from "./components/pages/NewOrder";
import OrderDetails from "./components/pages/OrderDetails";
import PaymentDelivery from "./components/pages/PaymentDelivery";
import AboutUs from "./components/pages/AboutUs";
import Blog from "./components/pages/Blog";
import BlogPost from "./components/pages/BlogPost";
import Contacts from "./components/pages/Contacts";
import Catalog from "./components/pages/Catalog";
import CatalogChapter from "./components/pages/CatalogChapter";
import CatalogSection from "./components/pages/CatalogSection";
import CatalogCategory from "./components/pages/CatalogCategory";
import ProductDetails from "./components/pages/ProductDetails";
import Favorites from "./components/pages/Favorites";
import Basket from "./components/pages/Basket";
import NotFound from "./components/pages/NotFound";

function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [isAuthorization, setIsAuthorization] = useState(false);
  useEffect(() => {
    const storedUserData = localStorage.getItem("user-data");
    if (storedUserData) {
      setIsAuthorization(true);
    }
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/admin"
          element={
            isAuthorization ? (
              <Admin />
            ) : (
              <Login setIsAuthorization={setIsAuthorization} />
            )
          }
        ></Route>
        <Route
          path="/newOrder"
          element={
            isAuthorization ? (
              <NewOrder />
            ) : (
              <Login setIsAuthorization={setIsAuthorization} />
            )
          }
        ></Route>
        <Route
          path="/order/:orderId"
          element={
            isAuthorization ? (
              <OrderDetails />
            ) : (
              <Login setIsAuthorization={setIsAuthorization} />
            )
          }
        ></Route>

        <Route path="/" element={<Home />}></Route>
        <Route path="/payment&delivery" element={<PaymentDelivery />}></Route>
        <Route path="/aboutUS" element={<AboutUs />}></Route>
        <Route path="/blog" element={<Blog />}></Route>
        <Route path="/blog/:postId" element={<BlogPost />}></Route>
        <Route path="/contacts" element={<Contacts />}></Route>
        <Route path="/catalog/chapter" element={<CatalogChapter />}></Route>
        <Route
          path="/catalog/chapter/:chapterId"
          element={<CatalogSection />}
        ></Route>
        <Route
          path="/catalog/section/:sectionId"
          element={<CatalogCategory />}
        ></Route>
        <Route path="/catalog/:categoryId" element={<Catalog />}></Route>
        <Route
          path="/catalog/product/:productId"
          element={<ProductDetails />}
        ></Route>
        <Route path="/favorites" element={<Favorites />}></Route>
        <Route path="/basket" element={<Basket />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </div>
  );
}

export default App;
