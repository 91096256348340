import React from "react";
import styles from "../scss/components/stepperSuccessStep.module.scss";
import delivery from "../assets/delivery.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StepperSuccessStep = ({ onSubmit }) => {
  const { t } = useTranslation();

  const handleButtonClick = (e) => {
    onSubmit(e);
  };
  
  return (
    <div className={styles.successStep}>
      <img className={styles.image} src={delivery} alt="" />
      <div className={styles.successStepDescriptions}>
        <p className={styles.successStepDescriptions__title}>
          {t("stepper.successStep.title")}
        </p>
        <p className={styles.successStepDescriptions__description}>
          {t("stepper.successStep.subtitle")}
        </p>
      </div>

      <Link to="/catalog/chapter" className={styles.successStepBtn}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
        >
          <path
            d="M6.20703 10.187L1.79376 5.77381L6.20703 1.36055"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p>{t("stepper.successStep.btn")}</p>
      </Link>
      <button onClick={(e) => handleButtonClick(e)}>Отправить</button>

    </div>
  );
};

export default StepperSuccessStep;
