import React, { useEffect, useState } from "react";
import styles from "../scss/components/login.module.scss";
import { useDebounce } from "use-debounce";
import axios from "axios";

const Login = ({ setIsAuthorization }) => {
  const [userData, setUserData] = useState(null);
  const [isLogin, setIsLogin] = useState(false);
  const [checkingData, setCheckingData] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [debouncedLoginInput] = useDebounce(login, 300);
  const [debouncedPasswordInput] = useDebounce(password, 300);
  useEffect(() => {
    setLogin(debouncedLoginInput);
  }, [debouncedLoginInput, setLogin]);
  useEffect(() => {
    setPassword(debouncedPasswordInput);
  }, [debouncedPasswordInput, setPassword]);
  useEffect(() => {
    const storedUserData = localStorage.getItem("user-data");
    if (storedUserData) {
      setIsLogin(true);
    }
  }, [userData]);
  const handleLogin = async () => {
    try {
      const response = await axios.post(
        "https://api.prodcomfit.md/api/auth/local",
        {
          identifier: login,
          password: password,
        }
      );

      if (response.status === 200) {
        setUserData(response.data.user);
        localStorage.setItem(
          "user-data",
          JSON.stringify({
            token: response.data.jwt,
            username: "identifier",
          })
        );
        setIsAuthorization(true);
        setIsLogin(true);
        setCheckingData(false);
      } else {
        setLogin("");
        setPassword("");
        setCheckingData(true);
      }
    } catch (error) {
      setLogin("");
      setPassword("");
      setCheckingData(true);
    }
  };

  return (
    <div className={isLogin ? styles.hidden : styles.login}>
      <div className={styles.login__wrapper}>
        <div>
          <span>Autentificare</span>
          <input
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            type="text"
          />
        </div>
        <div>
          <span>Parolă</span>
          <input
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            type="password"
          />
        </div>
        <p style={checkingData ? { display: "flex" } : {}}>date incorecte*</p>
        <button
          className={
            login.length < 6 || password.length < 6 ? styles.disabled : ""
          }
          disabled={login.length < 6 || password.length < 6}
          onClick={handleLogin}
        >
          Intră
        </button>
      </div>
    </div>
  );
};

export default Login;
