import React, { useEffect, useState } from "react";
import styles from "../../scss/components/pages/blog.module.scss";

import HeaderOtherPages from "../HeaderOtherPages";
import BlogItems from "../BlogItems";
import Footer from "../Footer";
import BlogBreadcrumb from "../breadcrumbs/BlogBreadcrumb";
import PhoneWidget from "../mui/PhoneWidget";
import ScrollToTopWidget from "../mui/ScrollToTopWidget";

const Blog = () => {
  const [widgetActive, setWidgetActive] = useState(true);

  return (
    <div className={styles.blog}>
     <HeaderOtherPages setWidgetActive={setWidgetActive} />
      <div className={styles.contant}>
        <BlogBreadcrumb />
        <BlogItems />
        {widgetActive ? (
          <div className={styles.widget}>
            <ScrollToTopWidget />
            <PhoneWidget />
          </div>
        ) : (
          ""
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
