import React, { useEffect, useRef, useState } from "react";
import styles from "../scss/components/stepperSecondStep.module.scss";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  updateOrderDataWithForm,
  selectOrderData,
  selectTotal,
} from "../store/features/orderSlice";

const StepperSecondStep = ({ onPrev, onNext }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  const [deliveryData, setDeliveryData] = useState([]);
  const [country, setCountry] = useState([]);
  const [countryOpen, setCountryOpen] = useState(false);
  const [deliveryItemDropDown, setDeliveryItemDropDown] = useState(false);
  const [activeDeliveryItemId, setActiveDeliveryItemId] = useState(null);
  const toggleDeliveryItemDropDown = (id) => {
    if (activeDeliveryItemId === id) {
      // Если ID уже активен, закрыть dropdown
      setDeliveryItemDropDown(false);
      setActiveDeliveryItemId(null);
    } else {
      // Если другой ID, открыть dropdown для этого элемента
      setDeliveryItemDropDown(true);
      setActiveDeliveryItemId(id);
    }
  };
  const orderData = useSelector(selectOrderData);
  const basketTotalPrice = orderData.promo.discountPrice + orderData.total;
  /*   console.log(basketTotalPrice); */

  const toogleCountryOpen = () => {
    setCountryOpen(!countryOpen);
  };
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    citySelect: "",
    city: "",
    streetHouseApartment: "",
    postalIndex: "",
    comment: "",
    delivery: "",
    agreedToTerms: false,
  });
  const countryOpenRef = useRef(null);
  const handleDocumentClick = (e) => {
    if (countryOpenRef.current && !countryOpenRef.current.contains(e.target)) {
      // Клик был сделан за пределами элемента countryOpen, закройте его
      setCountryOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    const handleLanguageChange = () => {
      setCurrentLanguage(i18next.language);
    };
    i18next.on("languageChanged", handleLanguageChange);
    return () => {
      i18next.off("languageChanged", handleLanguageChange);
    };
  }, []);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchCountries = async (page = 1) => {
      try {
        const res = await axios.get(
          `https://api.prodcomfit.md/api/countries?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=name&fields[1]=roName&fields[2]=deliveryCity`
        );
        const country = res.data.data;
        if (country.length > 0) {
          setCountry((prevData) => [...prevData, ...country]);
          fetchCountries(page + 1); // Рекурсивный вызов с увеличением номера страницы
        } else {
          setIsLoading(false); // Завершаем загрузку, когда больше нет данных
        }
      } catch (error) {
        console.error("Ошибка при запросе данных:", error);
        setIsLoading(false);
      }
    };

    // Функция для загрузки данных доставки
    const fetchDeliveries = async (page = 1) => {
      try {
        const res = await axios.get(
          `https://api.prodcomfit.md/api/delivery-categories?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=title&fields[1]=roTitle&fields[2]=Chisinau&populate[variants][fields][0]=name&populate[variants][fields][1]=price&populate[variants][fields][2]=code&populate[variants][fields][3]=roName&populate[variants][fields][4]=fromOrderPrice&populate[variants][fields][5]=toOrderPrice&populate[variants][fields][6]=note&populate[variants][fields][7]=roNote&populate[variants][fields][8]=Chisinau&populate[variants][fields][9]=description&populate[variants][fields][10]=roDescription`
        );
        const delivery = res.data.data;
        if (delivery.length > 0) {
          setDeliveryData((prevData) => [...prevData, ...delivery]);
          fetchDeliveries(page + 1); // Рекурсивный вызов с увеличением номера страницы
        } else {
          setIsLoading(false); // Завершаем загрузку, когда больше нет данных
        }
      } catch (error) {
        console.error("Ошибка при запросе данных:", error);
        setIsLoading(false); // Устанавливаем состояние загрузки в false в случае ошибки
      }
    };

    fetchCountries();
    fetchDeliveries();
  }, []);
  // Обработчик выбора города
  const handleCitySelect = (selectedCity, selectedCityRo) => {
    const newCitySelect =
      i18n.language === "ro" ? selectedCityRo : selectedCity;

    // Устанавливаем новый выбор города и всегда сбрасываем доставку
    setFormData((prevFormData) => ({
      ...prevFormData,
      citySelect: newCitySelect,
      delivery: "", // Сброс доставки при выборе любого города
    }));
    setSelectedDelivery(null); // Сброс выбранной доставки
    setDeliveryItemDropDown(false); // Закрытие выпадающего списка, если он был открыт

    setCountryOpen(false);
  };
  // useEffect для сброса доставки, если город изменён на не Кишинёв
  useEffect(() => {
    const isChisinauDelivery =
      formData.delivery &&
      deliveryData.some((delivery) => {
        return (
          delivery.attributes?.Chisinau && delivery.id === selectedDelivery
        );
      });

    if (
      formData.citySelect !== "Кишинёв" &&
      formData.citySelect !== "Chișinău" &&
      isChisinauDelivery
    ) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        delivery: "",
      }));
      setSelectedDelivery(null);
    }
  }, [formData.citySelect, formData.delivery, deliveryData, selectedDelivery]);
  // Обработчик выбора доставки
  // Функция для проверки, доступен ли вариант доставки на основе цены корзины
  const isDeliveryAvailable = (variant, basketPrice) => {
    const fromOrderPrice = variant.attributes?.fromOrderPrice;
    const toOrderPrice = variant.attributes?.toOrderPrice;

    // Если toOrderPrice === null, доставка доступна от fromOrderPrice и выше
    if (toOrderPrice === null) {
      return basketPrice >= fromOrderPrice;
    }

    // Если basketPrice находится в диапазоне от fromOrderPrice до toOrderPrice
    return basketPrice >= fromOrderPrice && basketPrice <= toOrderPrice;
  };
  // Обработчик выбора доставки
  const handleDeliverySelect = (variantId) => {
    let shouldResetCitySelect = false;

    const variant = deliveryData.reduce((acc, item) => {
      const variants = item.attributes?.variants?.data;
      return acc || variants?.find((v) => v.id === variantId);
    }, null);

    // Проверяем, доступен ли выбранный вариант доставки
    if (variant && isDeliveryAvailable(variant, basketTotalPrice)) {
      const selectedVariantCode = variant.attributes?.code;

      // Сброс выбора города, если необходимо
      if (
        variant.attributes?.Chisinau &&
        formData.citySelect !== "Кишинёв" &&
        formData.citySelect !== "Chișinău"
      ) {
        shouldResetCitySelect = true;
      }

      // Установка выбранного варианта доставки
      setFormData((prevFormData) => ({
        ...prevFormData,
        delivery: shouldResetCitySelect ? "" : selectedVariantCode,
        citySelect: shouldResetCitySelect ? "" : formData.citySelect,
      }));

      // Установка состояния выбранной доставки
      setSelectedDelivery(shouldResetCitySelect ? null : variantId);
    } else {
      // Если вариант недоступен, не устанавливаем его как выбранный
      setFormData((prevFormData) => ({
        ...prevFormData,
        delivery: "", // Сброс выбора доставки
      }));
      setSelectedDelivery(null); // Сброс выбранной доставки
    }

    // Переключение состояния dropdown, если это необходимо
    setDeliveryItemDropDown(
      variantId && isDeliveryAvailable(variant, basketTotalPrice)
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: !value,
    });
  };
  const handleCheckboxChange = (e) => {
    setFormData({
      ...formData,
      agreedToTerms: e.target.checked,
    });
    setErrors({
      ...errors,
      agreedToTerms: !e.target.checked,
    });
  };
  const validateForm = () => {
    const newErrors = {};
    let isValid = true;
    Object.keys(formData).forEach((key) => {
      // Пропускаем проверку для необязательных полей
      if (key !== "comment" && key !== "agreedToTerms" && !formData[key]) {
        newErrors[key] = true;
        isValid = false;
      }
    });
    // Проверяем, что выбран способ доставки и дано согласие на условия
    if (!selectedDelivery) {
      newErrors.delivery = true;
      isValid = false;
    }
    if (!formData.agreedToTerms) {
      newErrors.agreedToTerms = true;
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const isFormValid = validateForm();

    if (isFormValid) {
      /*   console.log("Форма отправлена с данными:", formData); */
      dispatch(updateOrderDataWithForm(formData));
      onNext();
    }
  };
  const handleNextOrSubmit = () => {
    handleSubmit({ preventDefault: () => {} });
  };
  return (
    <div className={styles.secondStep}>
      <div className={styles.delivery}>
        <p className={styles.deliveryTitle}>
          {t("stepper.secondStepper.delivery.title")}
        </p>
        <div className={styles.delivery__items}>
          {deliveryData.map((item) => {
            // Проверяем, есть ли доступные варианты доставки внутри элемента, учитывая цену корзины
            const hasAvailableVariants = item.attributes.variants.data.some(
              (variant) => {
                const isAvailable = isDeliveryAvailable(
                  variant,
                  basketTotalPrice
                );
                const isChisinauVariant = variant.attributes?.Chisinau === true;
                const isChisinauSelected =
                  formData.citySelect === "Кишинёв" ||
                  formData.citySelect === "Chișinău";
                return (
                  isAvailable && (!isChisinauVariant || isChisinauSelected)
                );
              }
            );
            // Если в форме есть ошибка и есть доступные варианты доставки, применяем класс ошибки
            const errorClass =
              errors.delivery && hasAvailableVariants
                ? styles.errorDelivery
                : "";

            return (
              <div
                key={item.id}
                className={`${styles.delivery__item} ${errorClass}`}
              >
                <div className={styles.deliveryHeader}>
                  <p>
                    {i18n.language === "ro"
                      ? item.attributes.roTitle
                      : item.attributes.title}
                  </p>
                </div>
                <div className={styles.deliveryContant}>
                  {item.attributes.variants.data.map((variant) => {
                    const isAvailable = isDeliveryAvailable(
                      variant,
                      basketTotalPrice
                    );
                    const isChisinauVariant =
                      variant.attributes?.Chisinau === true;
                    const isChisinauSelected =
                      formData.citySelect === "Кишинёв" ||
                      formData.citySelect === "Chișinău";
                    const shouldApplyRedBackground =
                      isChisinauVariant && !isChisinauSelected;
                    const isVariantUnavailable =
                      !isAvailable ||
                      (isChisinauVariant && !isChisinauSelected);
                    // Добавление класса для недоступных вариантов доставки
                    const unavailableClass = isVariantUnavailable
                      ? styles.deliveryUnavailable
                      : "";

                    return (
                      <div key={`delivery-${variant.id}`}>
                        <div
                          onClick={() => {
                            // Обработчик клика срабатывает, только если вариант доступен и выбран Кишинёв (если это необходимо)
                            if (
                              isAvailable &&
                              (!isChisinauVariant || isChisinauSelected)
                            ) {
                              handleDeliverySelect(variant.id);
                              toggleDeliveryItemDropDown(variant.id);
                            }
                          }}
                          className={`${styles.deliveryHeaderItem} ${unavailableClass}`}
                        >
                          <input
                            type="radio"
                            readOnly
                            checked={selectedDelivery === variant.id}
                            onChange={() => handleDeliverySelect(variant.id)}
                          />
                          <p className={styles.deliveryItemTitle}>
                            {i18n.language === "ro"
                              ? variant.attributes.roName
                              : variant.attributes.name}
                          </p>
                          <p
                            className={`${styles.deliveryItemPrice} ${
                              variant.attributes.price === 0
                                ? styles.hidden
                                : ""
                            }`}
                          >
                            {variant.attributes.price !== null
                              ? variant.attributes.price
                              : "N/A"}{" "}
                            <span>{t("word.lei")}</span>
                          </p>
                          {variant.attributes.note && (
                            <div>
                              <svg
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g data-name="Layer 57" id="Layer_57">
                                  <path
                                    className="cls-1"
                                    d="M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z"
                                    fill="#8fc23d"
                                  />
                                  <path
                                    className="cls-1"
                                    d="M16,24a2,2,0,1,1,2-2A2,2,0,0,1,16,24Zm0-2Z"
                                    fill="#8fc23d"
                                  />
                                  <path
                                    className="cls-1"
                                    d="M16,18a1,1,0,0,1-1-1V8a1,1,0,0,1,2,0v9A1,1,0,0,1,16,18Z"
                                    fill="#8fc23d"
                                  />
                                </g>
                              </svg>

                              <p className={styles.deliveryItemNote}>
                                {i18n.language === "ro" &&
                                variant.attributes.roNote
                                  ? variant.attributes.roNote
                                  : variant.attributes.note}
                              </p>
                            </div>
                          )}
                        </div>
                        {activeDeliveryItemId === variant.id &&
                          deliveryItemDropDown &&
                          (i18n.language === "ro"
                            ? variant.attributes.roDescription != null
                            : variant.attributes.description != null) && (
                            <div className={styles.deliveryItemDropDown}>
                              <p>
                                {i18n.language === "ro"
                                  ? variant.attributes.roDescription
                                  : variant.attributes.description}
                              </p>
                            </div>
                          )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <form>
        <div className={styles.data__wrapper}>
          <div className={styles.personalInfo}>
            <p className={styles.personalInfoTitle}>
              {t("stepper.secondStepper.data.personalInfo.title")}
            </p>
            <div className={styles.personalInfo__items}>
              <div className={styles.personalInfo__item}>
                <p>{t("stepper.secondStepper.delivery.input.name")}</p>
                <input
                  type="text"
                  name="name"
                  placeholder={t(
                    "stepper.secondStepper.delivery.input.namePlaceholder"
                  )}
                  value={formData.name}
                  onChange={handleChange}
                  className={errors.name ? styles.errorInput : ""}
                />
              </div>
              <div className={styles.personalInfo__item}>
                <p>{t("stepper.secondStepper.delivery.input.number")}</p>
                <input
                  type="number"
                  name="number"
                  placeholder="+(373)12345678"
                  value={formData.number}
                  onChange={handleChange}
                  className={errors.number ? styles.errorInput : ""}
                />
              </div>
              <div className={styles.personalInfo__item}>
                <p>E-mail</p>
                <input
                  type="text"
                  name="email"
                  placeholder="prodcom.fit.md@gmail.com"
                  value={formData.email}
                  onChange={handleChange}
                  className={errors.email ? styles.errorInput : ""}
                />
              </div>
            </div>
          </div>
          <div className={styles.adress}>
            <p className={styles.adressTitle}>
              {t("stepper.secondStepper.data.adress.title")}
            </p>
            <div className={styles.adress__items}>
              <div className={styles.adress__item}>
                <p>{t("stepper.secondStepper.data.adress.input.country")}</p>
                <input
                  type="text"
                  value={t(
                    "stepper.secondStepper.data.adress.input.countryValue"
                  )}
                  readOnly
                />
              </div>
              <div className={styles.adressSelectCoutry}>
                <div
                  onClick={toogleCountryOpen}
                  ref={countryOpenRef}
                  className={`${styles.adressSelectCoutry__main} ${
                    countryOpen ? styles.adressSelectCoutryActive : ""
                  }`}
                >
                  <p>
                    {t("stepper.secondStepper.data.adress.input.citySelect")}
                  </p>
                  <input
                    type="text"
                    name="citySelect"
                    placeholder={t(
                      "stepper.secondStepper.data.adress.input.citySelectPlaceholder"
                    )}
                    value={formData.citySelect}
                    readOnly
                    onChange={handleChange}
                    className={errors.citySelect ? styles.errorInput : ""}
                  />
                </div>
                <div
                  className={
                    countryOpen
                      ? styles.adressSelectCoutry__dropDown
                      : styles.hidden
                  }
                >
                  {country.map((item) => (
                    <p
                      onClick={() => {
                        handleCitySelect(
                          item.attributes.name,
                          item.attributes.roName
                        );
                      }}
                      key={item.id}
                    >
                      {i18n.language === "ro"
                        ? item.attributes.roName
                        : item.attributes.name}
                    </p>
                  ))}
                </div>
              </div>
              <div className={styles.adress__item}>
                <p>{t("stepper.secondStepper.data.adress.input.city")}</p>
                <input
                  type="text"
                  name="city"
                  placeholder={t(
                    "stepper.secondStepper.data.adress.input.cityPlaceholder"
                  )}
                  value={formData.city}
                  onChange={handleChange}
                  className={errors.city ? styles.errorInput : ""}
                />
              </div>
              <div className={styles.adress__item}>
                <p>{t("stepper.secondStepper.data.adress.input.street")}</p>
                <input
                  type="text"
                  name="streetHouseApartment"
                  placeholder={t(
                    "stepper.secondStepper.data.adress.input.streetPlaceholder"
                  )}
                  value={formData.streetHouseApartment}
                  onChange={handleChange}
                  className={
                    errors.streetHouseApartment ? styles.errorInput : ""
                  }
                />
              </div>
              <div className={styles.adress__item}>
                <p>{t("stepper.secondStepper.data.adress.input.index")}</p>
                <input
                  type="number"
                  name="postalIndex"
                  placeholder="1234"
                  value={formData.postalIndex}
                  onChange={handleChange}
                  className={errors.postalIndex ? styles.errorInput : ""}
                />
              </div>
              <div className={styles.adress__item}>
                <p>{t("stepper.secondStepper.data.adress.input.message")}</p>
                <textarea
                  name="comment"
                  placeholder={t(
                    "stepper.secondStepper.data.adress.input.messagePlaceholder"
                  )}
                  value={formData.comment}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dataFooter}>
          <div
            className={`${styles.dataConfirmation} ${
              errors.agreedToTerms ? styles.errorDataConfirmation : ""
            }`}
          >
            <input
              type="checkbox"
              checked={formData.agreedToTerms}
              onChange={handleCheckboxChange}
            />
            <p>
              {t("stepper.secondStepper.data.adress.input.agreed.p")}
              <span>
                {t("stepper.secondStepper.data.adress.input.agreed.span")}
              </span>
              .
            </p>
          </div>
          <div
            onClick={handleNextOrSubmit}
            type="submit"
            className={styles.dataBtn}
          >
            <p>{t("stepper.secondStepper.data.adress.input.continue")}</p>
          </div>
          <div onClick={onPrev} className={styles.dataBtnBack}>
            <p>{t("stepper.secondStepper.data.adress.input.back")}</p>
          </div>
          <div className={styles.dataAttention}>
            <div className={styles.dataAttention__icon}>
              <p>i</p>
            </div>
            <p className={styles.dataAttention__description}>
              {t("stepper.secondStepper.data.adress.input.description")}
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default StepperSecondStep;
