import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import HeaderOtherPages from "../HeaderOtherPages";
import CatalogSectionDetails from "../CatalogSectionDetails";
import Footer from "../Footer";
import CatalogSectionBreadcrumb from "../breadcrumbs/CatalogSectionBreadcrumb";
import NotFound from "../pages/NotFound";
import Spinner from "../Spinner"; // Импортируем компонент Spinner
import PhoneWidget from "../mui/PhoneWidget";
import ScrollToTopWidget from "../mui/ScrollToTopWidget";

import styles from "../../scss/components/pages/catalogSection.module.scss";

const CatalogSection = () => {
  const currentLanguage = i18next.language;
  const [widgetActive, setWidgetActive] = useState(true);

  const { chapterId } = useParams();
  const [chapterData, setChapterData] = useState(null);
  const { i18n } = useTranslation();

  // Добавляем состояние для отслеживания загрузки данных
  const [loading, setLoading] = useState(true);

  const fetchChapterData = useCallback(() => {
    axios
      .get(
        `https://api.prodcomfit.md/api/product-shapters/${chapterId}?pagination[page]=1&pagination[pageSize]=1000&fields[0]=id&fields[1]=name&fields[2]=roName&populate[product_sections][populate][fields][0]=id&populate[product_sections][fields][1]=name&populate[product_sections][populate][fields][1]=roName`
      )
      .then((res) => {
        setChapterData(res.data);
        setLoading(false); // Устанавливаем loading в false после успешной загрузки данных
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
        setLoading(false); // Устанавливаем loading в false в случае ошибки
      });
  }, [chapterId]);

  useEffect(() => {
    fetchChapterData();
  }, [fetchChapterData]);

  const titles = useMemo(() => {
    const sections = chapterData?.data?.attributes?.product_sections;
    return (
      sections?.data?.reduce((acc, section) => {
        const sectionId = section.id;
        const sectionTitle =
          i18n.language === "ro"
            ? section.attributes?.roName
            : section.attributes?.name;
        acc[sectionId] = sectionTitle;
        return acc;
      }, {}) || {}
    );
  }, [chapterData, i18n.language]);

  const title = useMemo(() => {
    return currentLanguage === "ro"
      ? chapterData?.data?.attributes?.roName || ""
      : chapterData?.data?.attributes?.name || "";
  }, [chapterData, currentLanguage]);

  if (loading) {
    return <Spinner />;
  }

  if (!chapterData) {
    return <NotFound />;
  }

  return (
    <div className={styles.catalogSection}>
      <HeaderOtherPages setWidgetActive={setWidgetActive} />
      <div className={styles.contant}>
        <CatalogSectionBreadcrumb name={title} />
        <CatalogSectionDetails name={titles} chapter={chapterData} />
        {widgetActive ? (
          <div className={styles.widget}>
            <ScrollToTopWidget />
            <PhoneWidget />
          </div>
        ) : (
          ""
        )}
      </div>
      <Footer />
    </div>
  );
};

export default React.memo(CatalogSection);
