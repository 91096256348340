import React from "react";
import styles from "../../scss/components/pages/admin.module.scss";
import AdminItems from "../AdminItems";
import AdminHeader from "../AdminHeader";
import Login from "../Login";

const Admin = () => {
  return (
    <div className={styles.admin}>
      <AdminHeader/>
      <AdminItems />
    </div>
  );
};

export default Admin;
