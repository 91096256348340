import React, { useState } from "react";
import styles from "../../scss/components/pages/catalogChapter.module.scss";

import HeaderOtherPages from "../HeaderOtherPages";
import CatalogChapterDetails from "../CatalogChapterDetails";
import Footer from "../Footer";
import CatalogChapterBreadcrumb from "../breadcrumbs/CatalogChapterBreadcrumb";
import PhoneWidget from "../mui/PhoneWidget";
import ScrollToTopWidget from "../mui/ScrollToTopWidget";

const CatalogChapter = () => {
  const [widgetActive, setWidgetActive] = useState(true);

  return (
    <div className={styles.catalogChapter}>
    <HeaderOtherPages setWidgetActive={setWidgetActive} />
      <div className={styles.contant}>
        <CatalogChapterBreadcrumb />
        <CatalogChapterDetails />
        {widgetActive ? (
          <div className={styles.widget}>
            <ScrollToTopWidget />
            <PhoneWidget />
          </div>
        ) : (
          ""
        )}
      </div>
      <Footer />
    </div>
  );
};

export default CatalogChapter;
