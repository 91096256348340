import React, { useEffect, useRef, useState } from "react";
import styles from "../scss/components/blogPostCarouselCard.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BlogPostCarouselCard = ({ imagePostCard, date, title, subtitle, id }) => {
  const { t } = useTranslation();

  const parallaxRef = useRef(null);
  useEffect(() => {
    const parallaxContainer = parallaxRef.current;
    const sensitivity = 40;
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      const { offsetWidth, offsetHeight } = parallaxContainer;
      const x = (offsetWidth / 2 - clientX) / sensitivity;
      const y = (offsetHeight / 2 - clientY) / sensitivity;
      parallaxContainer.style.transition = "none"; // Отключение плавности для движения
      parallaxContainer.style.transform = `translate(${x}px, ${y}px) scale(1.1)`;  // Добавлено масштабирование
    };
    const handleMouseLeave = () => {
      parallaxContainer.style.transition = "transform 0.5s ease-in-out"; // Включение плавности для возвращения
      parallaxContainer.style.transform = "translate(0, 0) scale(1.0)"; // Добавлено масштабирование
    };
    parallaxContainer.addEventListener("mousemove", handleMouseMove);
    parallaxContainer.addEventListener("mouseleave", handleMouseLeave);
    return () => {
      parallaxContainer.removeEventListener("mousemove", handleMouseMove);
      parallaxContainer.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return (
    <Link to={`/blog/${id}`} className={styles.blogPostCard}>
      <div className={styles.img}>
        <img
          ref={parallaxRef}
          src={`https://api.prodcomfit.md${imagePostCard}`}
          alt={title}
        />
      </div>
      <div className={styles.descriptions}>
        <p className={styles.date}>{date}</p>
        <div className={styles.data}>
          <p className={styles.data__title}>{title}</p>
          <p className={styles.data__info}>{subtitle}</p>
        </div>
        <div className={styles.btn}>
          <p>{t("blog.btn")}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
          >
            <circle cx="9.5" cy="10.0063" r="9.5" fill="#8FC23D" />
            <path d="M12.399 9.69977H5.51562H12.399Z" fill="#8FC23D" />
            <path
              d="M9.47592 6.63525L12.8705 9.69977L9.47592 12.7643M12.399 9.69977H5.51562"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </Link>
  );
};

export default BlogPostCarouselCard;
