import React, { useState, useCallback, useMemo, useEffect } from "react";
import styles from "../scss/components/catalogItems.module.scss";
import { useTranslation } from "react-i18next";
import { Loader } from "semantic-ui-react";
import ProductItems from "./ProductItems";
import Filter from "./Filter";
import Sort from "./Sort";

const CatalogItems = ({ categoryData, categoryDataName, setWidgetActive }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 200);

    return () => clearTimeout(timer);
  }, []);
  const [sorting, setSorting] = useState("default");
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [selectedCharacteristics, setSelectedCharacteristics] = useState({});
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(Infinity);
  const [safeMaxPrice, setSafeMaxPrice] = useState(Infinity);

  // Функция для переключения состояния фильтра
  const toggleFilter = useCallback(() => {
    setIsFilterOpen(!isFilterOpen);
    setIsSortOpen(false);
  }, [isFilterOpen]);
  // Callback для установки минимальной цены
  const setMinPriceCallback = useCallback(
    (newMinPrice) => {
      if (newMinPrice <= maxPrice) {
        setMinPrice(newMinPrice);
      }
    },
    [maxPrice]
  );
  // Callback для установки максимальной цены
  const setMaxPriceCallback = useCallback(
    (newMaxPrice) => {
      if (newMaxPrice >= minPrice) {
        setMaxPrice(newMaxPrice);
      }
    },
    [minPrice]
  );
  // Callback для установки шкалы Range
  const setSafeMaxPriceCallback = useCallback(
    (safeMaxPrice) => {
      if (safeMaxPrice >= minPrice) {
        setSafeMaxPrice(safeMaxPrice);
      }
    },
    [minPrice]
  );

  // Обработчики для изменений значений ползунков
  const [value, setValue] = useState([minPrice, maxPrice]);
  const handleMinPriceChange = (event) => {
    let inputValue = event.target.value;

    // Обрезать значение до 13 символов
    if (inputValue.length > 13) {
      inputValue = inputValue.slice(0, 13);
    }

    const newMinPrice = Math.max(Number(inputValue), 0);
    setValue([newMinPrice, value[1]]);
  };
  const handleMaxPriceChange = (event) => {
    let inputValue = event.target.value;

    // Проверка на пустое значение или начало с нуля
    if (inputValue === "" || inputValue === "0" || inputValue.match(/^0/)) {
      inputValue = "1";
    } else if (inputValue.length > 13) {
      // Обрезать значение до 10 символов
      inputValue = inputValue.slice(0, 13);
    }

    // Преобразовать введенное значение в число и обновить состояние
    const newMaxPrice = Number(inputValue);
    setValue([value[0], newMaxPrice]);
  };
  useEffect(() => {
    if (isFilterOpen === true) {
      setWidgetActive(false);
    } else {
   setWidgetActive(true);
    }
  }, [isFilterOpen]);
  return (
    <div className={styles.catalogItems}>
      <div className="container">
        <div className={styles.catalogItems__wrapper}>
          <p className={styles.categorySearchName}>
            {t("catalog.catalogItems.title")} <span>{categoryDataName}</span>
          </p>
          <div className={styles.items}>
            <div className={`${styles.item} ${styles.filter}`}>
              <Filter
                safeMaxPrice={safeMaxPrice}
                category={categoryData}
                selectedCharacteristics={selectedCharacteristics}
                setSelectedCharacteristics={setSelectedCharacteristics}
                minPrice={minPrice}
                maxPrice={maxPrice}
                setMinPrice={setMinPriceCallback}
                setMaxPrice={setMaxPriceCallback}
                setValue={setValue}
                value={value}
                handleMinPriceChange={handleMinPriceChange}
                handleMaxPriceChange={handleMaxPriceChange}
              />
            </div>
            <div className={styles.item}>
              <div className={styles.interaction}>
                <div className={styles.filterMb}>
                  <div
                    onClick={toggleFilter}
                    className={styles.filterMbRelativ}
                  >
                    <p>{t("catalog.filter.title")}</p>
                  </div>
                  {isFilterOpen ? (
                    <>
                      <div
                        onClick={toggleFilter}
                        className={styles.overlay}
                      ></div>
                      <div className={styles.dropDown}>
                        <svg
                          className={styles.close}
                          onClick={toggleFilter}
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="100"
                          height="100"
                          viewBox="0,0,256,256"
                        >
                          <g
                            fill="#8fc23d"
                            fillRule="nonzero"
                            stroke="none"
                            strokeWidth="1"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="10"
                            strokeDasharray=""
                            strokeDashoffset="0"
                            fontFamily="none"
                            fontWeight="none"
                            fontSize="none"
                            textAnchor="none"
                          >
                            <g transform="scale(8.53333,8.53333)">
                              <path d="M7,4c-0.25587,0 -0.51203,0.09747 -0.70703,0.29297l-2,2c-0.391,0.391 -0.391,1.02406 0,1.41406l7.29297,7.29297l-7.29297,7.29297c-0.391,0.391 -0.391,1.02406 0,1.41406l2,2c0.391,0.391 1.02406,0.391 1.41406,0l7.29297,-7.29297l7.29297,7.29297c0.39,0.391 1.02406,0.391 1.41406,0l2,-2c0.391,-0.391 0.391,-1.02406 0,-1.41406l-7.29297,-7.29297l7.29297,-7.29297c0.391,-0.39 0.391,-1.02406 0,-1.41406l-2,-2c-0.391,-0.391 -1.02406,-0.391 -1.41406,0l-7.29297,7.29297l-7.29297,-7.29297c-0.1955,-0.1955 -0.45116,-0.29297 -0.70703,-0.29297z"></path>
                            </g>
                          </g>
                        </svg>
                        <Filter
                          safeMaxPrice={safeMaxPrice}
                          category={categoryData}
                          selectedCharacteristics={selectedCharacteristics}
                          setSelectedCharacteristics={
                            setSelectedCharacteristics
                          }
                          minPrice={minPrice}
                          maxPrice={maxPrice}
                          setMinPrice={setMinPriceCallback}
                          setMaxPrice={setMaxPriceCallback}
                          setValue={setValue}
                          value={value}
                          handleMinPriceChange={handleMinPriceChange}
                          handleMaxPriceChange={handleMaxPriceChange}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
                <Sort
                  sorting={sorting}
                  setSorting={setSorting}
                  isSortOpen={isSortOpen}
                  setIsSortOpen={setIsSortOpen}
                  setIsFilterOpen={setIsFilterOpen}
                />
              </div>

              {isLoading ? (
                <Loader active inline="centered" />
              ) : (
                <ProductItems
                  value={value}
                  category={categoryData}
                  sorting={sorting}
                  selectedCharacteristics={selectedCharacteristics}
                  setSelectedCharacteristics={setSelectedCharacteristics}
                  minPrice={minPrice}
                  maxPrice={maxPrice}
                  setMaxPrice={setMaxPriceCallback}
                  setMinPrice={setMinPriceCallback}
                  setSafeMaxPrice={setSafeMaxPriceCallback}
                  setValue={setValue}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatalogItems;
