import React from "react";
import styles from "../scss/components/paymentDeliveryContant.module.scss";
import { useTranslation } from "react-i18next";

const PaymentDeliveryContant = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.paymentDeliveryContant}>
      <div className="container">
        <p className={styles.title}>{t("paymentDelivery.title")}</p>
        <div className={styles.contant}>
          <div className={styles.contant__item}>
            <p className={styles.subtitle}>
              {t("paymentDelivery.item.first.title")}
            </p>
            <div className={styles.descriptions}>
              <p>{t("paymentDelivery.item.first.description")}</p>
            </div>
          </div>
          <div className={styles.contant__item}>
            <p className={styles.subtitle}>
              {t("paymentDelivery.item.second.title")}
            </p>
            <div className={styles.descriptions}>
              <p>{t("paymentDelivery.item.second.description.text")}</p>
              <div className={styles.subContant}>
                <p className={styles.subContant__title}>
                  {t(
                    "paymentDelivery.item.second.description.textItems.firstItem.title"
                  )}
                </p>
                <p>
                  {t(
                    "paymentDelivery.item.second.description.textItems.firstItem.description"
                  )}
                </p>
              </div>
              <div className={styles.subContant}>
                <p className={styles.subContant__title}>
                  {t(
                    "paymentDelivery.item.second.description.textItems.secondItem.title"
                  )}
                </p>
                <p>
                  {t(
                    "paymentDelivery.item.second.description.textItems.secondItem.description"
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.contant__item}>
            <p className={styles.subtitle}>
              {t("paymentDelivery.item.third.title")}
            </p>
            <div className={styles.descriptions}>
              <nav>
                <ul>
                  <ol>{t("paymentDelivery.item.third.texlList.firstText")}</ol>
                  <ol>{t("paymentDelivery.item.third.texlList.secondText")}</ol>
                  <ol>{t("paymentDelivery.item.third.texlList.thirdText")}</ol>
                  <ol>{t("paymentDelivery.item.third.texlList.fourthText")}</ol>
                  <ol>{t("paymentDelivery.item.third.texlList.fifthText")}</ol>
                  <ol>{t("paymentDelivery.item.third.texlList.sixthText")}</ol>
                </ul>
              </nav>
            </div>
          </div>
          <div className={styles.contant__item}>
            <p className={styles.subtitle}>
              {t("paymentDelivery.item.fourth.title")}
            </p>
            <div className={styles.descriptions}>
              <p>{t("paymentDelivery.item.fourth.description")}</p>
            </div>
          </div>
          <div className={styles.contant__item}>
            <p className={styles.subtitle}>
              {t("paymentDelivery.item.fifth.title")}
            </p>
            <div className={styles.descriptions}>
              <p>{t("paymentDelivery.item.fifth.description")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDeliveryContant;
