import React, { useState } from "react";
import styles from "../../scss/components/pages/basket.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { selectBasket, updateBasket } from "../../store/features/basketSlice";

import HeaderOtherPages from "../HeaderOtherPages";
import BasketItem from "../BasketItem";
import BasketEmpty from "../BasketEmpty";
import Footer from "../Footer";
import BasketBreadcrumb from "../breadcrumbs/BasketBreadcrumb";
import PhoneWidget from "../mui/PhoneWidget";
import ScrollToTopWidget from "../mui/ScrollToTopWidget";

const Basket = () => {
  const [widgetActive, setWidgetActive] = useState(true);
  const basket = useSelector(selectBasket);
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const onPrev = () => {
    if (currentStep > 0) {
      console.log("basketonPrev", basket);
      dispatch(updateBasket(basket));
      setCurrentStep(currentStep - 1);
    }
  };
  const onNext = () => {
    if (currentStep < 2) {
      console.log("basketonNext", basket);
      dispatch(updateBasket(basket));
      setCurrentStep(currentStep + 1);
    }
  };
  return (
    <div className={styles.basket}>
      <HeaderOtherPages setWidgetActive={setWidgetActive} />

      <div className={styles.contant}>
        <BasketBreadcrumb />
        {basket.length > 0 ? (
          <BasketItem
            products={basket}
            currentStep={currentStep}
            onPrev={onPrev}
            onNext={onNext}
          />
        ) : (
          <BasketEmpty />
        )}
        {widgetActive ? (
          <div className={styles.widget}>
            <ScrollToTopWidget />
            <PhoneWidget />
          </div>
        ) : (
          ""
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Basket;
