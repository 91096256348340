import React, { useEffect, useState, useCallback, useMemo } from "react";
import styles from "../scss/components/sort.module.scss";
import { useTranslation } from "react-i18next";

const Sort = ({
  sorting,
  setSorting,
  isSortOpen,
  setIsSortOpen,
  setIsFilterOpen,
}) => {
  const { t } = useTranslation();

  const [selectedSort, setSelectedSort] = useState("По цене - дорогие выше");

  useEffect(() => {
    setSelectedSort(sorting); // Установим начальное значение выбранной сортировки из пропсов
  }, [sorting]);

  const toggleSort = useCallback(() => {
    setIsSortOpen(!isSortOpen);
    setIsFilterOpen(false); // Закрыть фильтрацию при открытии сортировки
  }, [isSortOpen, setIsSortOpen, setIsFilterOpen]);

  const handleSortChange = useCallback(
    (sortType) => {
      setSelectedSort(sortType);
      setSorting(sortType); // Установим выбранную сортировку через пропс setSorting
      toggleSort();
    },
    [setSorting, toggleSort]
  );

  // Мемоизация объекта sortNames
  const sortNames = useMemo(
    () => ({
      "A-Z": t("catalog.sort.item.A-Z"),
      "Z-A": t("catalog.sort.item.Z-A"),
      "low-to-high": t("catalog.sort.item.low"),
      "high-to-low": t("catalog.sort.item.high"),
      hits: t("catalog.sort.item.hits"),
      discount: t("catalog.sort.item.discount"),
    }),
    [t]
  );

  return (
    <div className={styles.sort}>
      <div className={styles.sort__wrapper}>
        <div onClick={toggleSort} className={styles.sortBy}>
          <div className={styles.description}>
            <p className={styles.title}>{t("catalog.sort.title")}</p>
            <p className={styles.selected}>{sortNames[selectedSort]}</p>
          </div>
          <div className={styles.arrow}>
            {isSortOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17 14L12 9L7 14"
                  stroke="#4FA083"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7 10L12 15L17 10"
                  stroke="#98999C"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
        </div>
        {isSortOpen ? (
          <div className={styles.dropDown}>
            {Object.keys(sortNames).map((key) => (
              <p
                key={key}
                onClick={() => handleSortChange(key)}
                className={key === selectedSort ? styles.selectedItem : ""}
              >
                {sortNames[key]}
              </p>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Sort;
