import React from 'react';
import styles from "../scss/components/spinner.module.scss";


const Spinner = () => {
  return (
    <div className={styles.main}>
      <div className={styles.spinner}></div>
    </div>
  );
};

export default Spinner;
