import React, { useEffect, useState, useMemo } from "react";
import styles from "../scss/components/bannerProductRange.module.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const BannerProductRange = () => {
  const { t } = useTranslation();
  const currentLanguage = i18next.language;

  const [chapterData, setChapterData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = (page = 1) => {
      axios
        .get(
          `https://api.prodcomfit.md/api/product-shapters?pagination[page]=${page}&pagination[pageSize]=1000&populate[product_sections][fields][0]=name&populate[product_sections][fields][1]=roName&populate[product_sections][populate][image][fields][0]=url&populate[product_sections][populate][image][fields][1]=name`
        )
        .then((res) => {
          const products = res.data.data;
          if (products.length > 0) {
            setChapterData((prevData) => [...prevData, ...products]);
            fetchData(page + 1); // Рекурсивный вызов fetchData для следующей страницы
          } else {
            setIsLoading(false); // Устанавливаем isLoading в false после загрузки всех данных
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
          setIsLoading(false); // Устанавливаем isLoading в false в случае ошибки
        });
    };

    fetchData();
  }, []);
  const url = process.env.REACT_APP_API_FOR_IMAGE;
  const sectionNames = useMemo(() => {
    return chapterData.length > 0
      ? chapterData[0].attributes.product_sections.data.map((section) => ({
          id: section.id,
          name:
            currentLanguage === "ru"
              ? section.attributes.name
              : currentLanguage === "ro"
              ? section.attributes.roName
              : section.attributes.name, // Значение по умолчанию
          imageUrl: `${url}${section.attributes?.image?.data?.attributes?.url}`,
        }))
      : [];
  }, [chapterData, currentLanguage]);

  return (
    <div className={styles.bannerProductRange}>
      <div className="container">
        <div className={styles.bannerProductRange__wrapper}>
          <div className={styles.descriptions}>
            <p className={styles.title}>{t("bannerProductRange.title")}</p>
            <p className={styles.subtitle}>
              {t("bannerProductRange.subtitle")}
            </p>
          </div>
          <div className={styles.items}>
            {sectionNames.map((section) => (
              <Link key={section.id} to={`/catalog/section/${section.id}`}>
                <div className={styles.item}>
                  <div className={styles.img}>
                    <img src={section.imageUrl} alt={section.name} />
                  </div>
                  <p>{section.name}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerProductRange;
